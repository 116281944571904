import translate from 'i18n/translate'
import clsx from 'clsx'
import AddTemplateModal from 'features/generalLedger/components/AddTemplateModal/AddTemplateModal'
import TemplatesList from 'features/generalLedger/components/TemplatesList/TemplatesList'
import ImportButton from 'ui-components/ImportButton/ImportButton'
import { getTemplates, uploadTemplatesFile, exportTemplates } from 'services/templates.service'
import { useIntl } from 'react-intl'
import { useTemplatesStore } from 'features/generalLedger/TemplatesStore/TemplatesStore'
import { useEffect, useState } from 'react'
import { Message } from '@bdo/kitchensink'
import ExportButton from 'ui-components/ExportButton/ExportButton'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import styles from './Templates.module.scss'

function Templates() {
  const { page, pageSize, setTemplates, setPagination } = useTemplatesStore()
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    fetchTemplates()
  }, [page, pageSize])

  function fetchTemplates() {
    setIsLoading(true)
    getTemplates(page, pageSize)
      .then((data) => {
        setTemplates(data.rows)
        setPagination(page, pageSize, data.total)
      })
      .catch((error) => {
        LoggerHelper(error)
        Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }), 5)
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <div className='pagePadding'>
      <h2 className='pageTitle'>{translate('description_templates')}</h2>
      <div className='card cardTable'>
        <div className={clsx(styles.controlsWrapper, 'flex', 'alignItems')}>
          <div className={clsx('flex', 'alignItems')}>
            <AddTemplateModal />
            <ImportButton onUpload={uploadTemplatesFile} modalTitle={intl.$t({ id: 'import_templates' })} onFinished={() => fetchTemplates()} />
          </div>
          <div className={clsx('flex', 'alignItems')}>
            <ExportButton getData={exportTemplates} exportedFileName='alle klantomschrijvingen.xlsx' />
          </div>
        </div>
        <TemplatesList isLoading={isLoading} />
      </div>
    </div>
  )
}

export default Templates
