export interface Validate {
  // object itself has validation errors
  hasValidationErrors: boolean

  // object itself or nested object has validation errors
  hasErrors: boolean
}

export function isValidate(object: unknown): object is Validate {
  return Object.prototype.hasOwnProperty.call(object, 'hasErrors') && Object.prototype.hasOwnProperty.call(object, 'hasValidationErrors')
}
