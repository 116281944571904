import { ColumnViewModel } from 'features/questionnaire/components/_models/ColumnViewModel'
import { GroupContentViewModel } from 'features/questionnaire/components/_models/GroupContentViewModel'
import { OptionViewModel } from 'features/questionnaire/components/_models/OptionViewModel'
import { QuestionViewModel } from 'features/questionnaire/components/_models/QuestionViewModel'
import { from } from 'linq-to-typescript'
import { QuestionnaireViewModel } from 'pages/_models/QuestionnaireViewModel'
import { QuestionTypes, SharedQuestionsOptions } from 'services/Questionnaire/questionnaire.models'
import { QuestionAnswer } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

type QuestionnaireAnswerState = {
  questionnaireVM: QuestionnaireViewModel
  questionnaireAnswer: QuestionAnswer[]
  selectedGroupKey: string | undefined
  isDirty: boolean
  snapshot: string
  isReadOnly: boolean
  isArchived: boolean
  isSavingAndGenerating: boolean
}

type QuestionnaireAnswerActions = {
  setQuestionnaireVM: (questionnaire: QuestionnaireViewModel) => void
  selectGroup: (groupKey: string) => void
  addQuestionsAnswer: (questionsAnswers: QuestionAnswer[]) => void
  updateAnswer: (newAnswer: QuestionAnswer) => void
  updateAnswers: (newAnswers: QuestionAnswer[]) => void
  updateQuestion: (newQuestion: QuestionViewModel) => void
  updateReadOnly: (value: boolean) => void
  updateIsArchived: (value: boolean) => void
  setIsSavingAndGenerating: (value: boolean) => void
  setIsDirty: (isDirty: boolean) => void
  setVisibilityAndValidateGeneratedQuestions: (question: QuestionViewModel, selectedMultiplicationNumber: number) => void
  reset: () => void
}

type QuestionnaireAnswerStateActionResult =
  | QuestionnaireAnswerState
  | Partial<QuestionnaireAnswerState>
  | ((state: QuestionnaireAnswerState) => QuestionnaireAnswerState | Partial<QuestionnaireAnswerState>)

const initialState: QuestionnaireAnswerState = {
  questionnaireVM: {
    groups: [],
    id: '',
    modifiedBy: '',
    modifiedDate: new Date(),
    name: '',
    status: '',
    hasErrors: false,
    hasValidationErrors: false,
    service: '',
    application: ''
  },
  questionnaireAnswer: [],
  selectedGroupKey: undefined,
  isDirty: false,
  snapshot: '',
  isReadOnly: false,
  isArchived: false,
  isSavingAndGenerating: false
}

const useQuestionnaireAnswerStore = create<QuestionnaireAnswerState & QuestionnaireAnswerActions, [['zustand/devtools', QuestionnaireAnswerState & QuestionnaireAnswerActions]]>(
  devtools(
    (set) => ({
      ...initialState,
      setQuestionnaireVM: (questionnaire: QuestionnaireViewModel) => set(setQuestionnaireVM(questionnaire), false, 'Set questionnaire'),
      selectGroup: (groupKey) => set(selectGroup(groupKey), false, 'Select group'),
      addQuestionsAnswer: (questionsAnswers: QuestionAnswer[]) => set(addQuestionsAnswer(questionsAnswers), false, 'Add questions answers'),
      updateAnswer: (newAnswer) => {
        set(updateAnswer(newAnswer), false, 'Update answer req')
      },
      updateAnswers: (newAnswers) => {
        set(updateAnswers(newAnswers), false, 'Update answer req')
      },
      updateQuestion: (newQuestion) => {
        set(updateQuestion(newQuestion), false, 'Update question req')
      },
      updateReadOnly: (value) => {
        set(updateReadOnly(value), false, 'Update readonly')
      },
      updateIsArchived: (value) => {
        set(updateIsArchived(value), false, 'Update is archived')
      },
      setIsSavingAndGenerating: (value) => {
        set(setIsSavingAndGenerating(value), false, 'Set is saving and generating status')
      },
      setIsDirty: (isDirty) => {
        set(setIsDirty(isDirty), false, 'Set is dirty')
      },
      setVisibilityAndValidateGeneratedQuestions: (question, selectedMultiplicationNumber) => {
        set(setVisibilityAndValidateGeneratedQuestions(question, selectedMultiplicationNumber), false, 'Set multiplication number')
      },
      reset: () => {
        set((state) => ({ ...state, ...initialState }), false, 'Reset state to initial values')
      }
    }),
    { name: 'Onboarding tool - Answer questionnaire store' }
  )
)
export const useQuestionnaireVM = () => useQuestionnaireAnswerStore((state) => state.questionnaireVM)
export const useApplicationName = () => useQuestionnaireAnswerStore((state) => state.questionnaireVM.application)
export const useQuestionnaireHasErrors = () => useQuestionnaireAnswerStore((state) => state.questionnaireVM.hasErrors)
export const useQuestionnaireId = () => useQuestionnaireAnswerStore((state) => state.questionnaireVM.id)
export const useIsDirty = () => useQuestionnaireAnswerStore((state) => state.isDirty)
export const useQuestionnaireAnswer = () => useQuestionnaireAnswerStore((state) => state.questionnaireAnswer)
export const useQuestionnaireName = () => useQuestionnaireAnswerStore((state) => state.questionnaireVM.name)
export const useQuestionnaireGroups = () => useQuestionnaireAnswerStore((state) => state.questionnaireVM.groups)
export const useQuestionnaireApp = () => useQuestionnaireAnswerStore((state) => state.questionnaireVM.application)
export const useSelectedGroupKey = () => useQuestionnaireAnswerStore((state) => state.selectedGroupKey)
export const useSelectedGroup = () => useQuestionnaireAnswerStore((state) => findGroup(state, state.selectedGroupKey ?? ''))

function findGroup(state: QuestionnaireAnswerState, groupKey: string) {
  const group = state.questionnaireVM.groups.find((x) => x.key === groupKey)
  if (!group) {
    throw new Error('FE: Group was not displayed in answer questionnaire')
  }

  return group
}

export const useGroup = (groupKey: string) => useQuestionnaireAnswerStore((state) => findGroup(state, groupKey))

export const useColumn = (columnKey: string) =>
  useQuestionnaireAnswerStore((state) => {
    const column = state.questionnaireVM.groups.flatMap((x) => x.columns).find((x) => x.key === columnKey)
    if (!column) {
      throw new Error('FE: Column was not displayed in answer questionnaire')
    }
    return column
  })

export const useQuestion = (questionKey: string) =>
  useQuestionnaireAnswerStore((state) => {
    const question = findQuestionRecursively(state, questionKey)

    if (!question) {
      throw new Error('FE: Question was not displayed in answer questionnaire')
    }

    return question
  })

export const useOptions = (questionKey: string) =>
  useQuestionnaireAnswerStore((state) => {
    const question = findQuestionRecursively(state, questionKey)

    if (!question) {
      throw new Error('FE: Question was not displayed in answer questionnaire')
    }

    return question.options
  })

function findQuestionRecursively(state: QuestionnaireAnswerState, questionKey: string): QuestionViewModel | null {
  const question = from(state.questionnaireVM.groups)
    .selectMany((x) => x.columns)
    .selectMany((x) => x.questions)
    .selectMany((x) => getQuestionsRecursively(x))
    .firstOrDefault((x) => x.key === questionKey)

  return question
}

export function getQuestionsRecursively(question: QuestionViewModel) {
  const generatedQuestions = from(question?.generatedQuestions || []).toArray()
  const sharedQuestions = from(question?.sharedQuestions || []).toArray()

  const result: QuestionViewModel[] = [question]

  generatedQuestions.forEach((q) => {
    if (q !== undefined) result.push(...getQuestionsRecursively(q))
  })

  sharedQuestions.forEach((q) => {
    if (q !== undefined) result.push(...getQuestionsRecursively(q))
  })

  return result
}

export const useAllQuestions = () =>
  useQuestionnaireAnswerStore((state) => {
    const questions = from(state.questionnaireVM.groups)
      .selectMany((x) => x.columns)
      .selectMany((x) => x.questions)
      .selectMany((x) => getQuestionsRecursively(x))
    return questions.toArray()
  })

export const useAnswer = (questionKey: string) =>
  useQuestionnaireAnswerStore((state) => {
    const answer = state.questionnaireAnswer.find((a) => a.questionId === questionKey)

    if (!answer) {
      throw new Error(`FE: Answer to question with id: '${questionKey}' was not displayed in answer questionnaire`)
    }
    return answer
  })

export const useClearAnswerNotDisplay = () =>
  useQuestionnaireAnswerStore((state) => {
    const allQuestions = from(state.questionnaireVM.groups)
      .selectMany((x) => x.columns)
      .selectMany((x) => x.questions)
      .selectMany((x) => getQuestionsRecursively(x))
      .where((q: QuestionViewModel) => q.isDisplay === false)
      .select((vq) => vq.key)
      .toArray()

    const validAnswers: QuestionAnswer[] = state.questionnaireAnswer.map((answer: QuestionAnswer) => {
      if (allQuestions.indexOf(answer.questionId) > -1) {
        const changedParam = answer
        changedParam.text = ''
        return changedParam
      }
      return answer
    })

    return validAnswers
  })

function selectGroup(groupKey: string): QuestionnaireAnswerStateActionResult {
  return (state) => ({
    ...state,
    selectedGroupKey: groupKey
  })
}

function setQuestionnaireVM(questionnaire: QuestionnaireViewModel): QuestionnaireAnswerStateActionResult {
  return (state) => {
    const oldSelectedGroupIdx = state.questionnaireVM.groups.findIndex((x) => x.key === state.selectedGroupKey)

    const allAnswers = allQuestion(questionnaire).map((q: QuestionViewModel) => {
      const defaultOption = q.options.find((option) => option.isDefault === true)
      const answer = from(state.questionnaireAnswer).firstOrDefault((a) => a.questionId === q.id)
      if (answer === null)
        return {
          id: undefined,
          text: (q.type !== QuestionTypes.Multiplied && defaultOption === undefined ? '' : defaultOption?.id) ?? (q.type === QuestionTypes.Multiplied ? '0' : ''),
          questionId: q.key
        } as QuestionAnswer

      return answer
    })

    const newState = {
      ...state,
      questionnaireVM: questionnaire,
      snapshot: getSnapshot(state.questionnaireAnswer),
      isDirty: false,
      selectedGroupKey: '',
      questionnaireAnswer: allAnswers,
      isReadOnly: false
    }

    const groups: GroupContentViewModel[] = questionnaire.groups?.map((g: GroupContentViewModel) => ({
      ...g,
      columns: g.columns?.map((c: ColumnViewModel) => ({
        ...c,
        questions: c.questions?.map((q: QuestionViewModel) => checkVisibilityAndValidateQuestion(q, allAnswers))
      }))
    }))

    let newQuestionnaireVM = { ...questionnaire, groups }

    newQuestionnaireVM = markErrors(newQuestionnaireVM)
    if (oldSelectedGroupIdx !== -1 && questionnaire?.groups[oldSelectedGroupIdx]) {
      newState.selectedGroupKey = questionnaire.groups[oldSelectedGroupIdx].key
    }

    return { ...newState, questionnaireVM: newQuestionnaireVM }
  }
}

function checkVisibilityAndValidateQuestion(question: QuestionViewModel, allAnswers: QuestionAnswer[], parentQuestion?: QuestionViewModel): QuestionViewModel {
  const answer = allAnswers.find((a) => a.questionId === question.key)
  if (!answer) {
    return question
  }

  const isDisplayed = isQuestionDisplayed(question, parentQuestion, allAnswers)
  const hasValidationErrors = isDisplayed ? questionHasErrors(question, answer) : false

  return {
    ...question,
    isDisplay: isDisplayed,
    hasValidationErrors,
    generatedQuestions: question.generatedQuestions?.map((gq) => checkVisibilityAndValidateQuestion(gq, allAnswers, { ...question, isDisplay: isDisplayed, hasValidationErrors })),
    sharedQuestions: question.sharedQuestions?.map((sq) => checkVisibilityAndValidateQuestion(sq, allAnswers, { ...question, isDisplay: isDisplayed, hasValidationErrors }))
  } as QuestionViewModel
}

function questionHasErrors(question: QuestionViewModel, answer: QuestionAnswer) {
  let hasNumberOfCharactersValidationError = false
  if (question.type === QuestionTypes.Text && question.maxNumberOfCharacters) {
    hasNumberOfCharactersValidationError = answer.text.length > question.maxNumberOfCharacters
  }
  const hasValidationErrors = answer.text === '' && question.isMandatory

  return hasNumberOfCharactersValidationError || hasValidationErrors
}

function setVisibilityAndValidateGeneratedQuestions(generator: QuestionViewModel, selectedMultiplicationNumber: number): QuestionnaireAnswerStateActionResult {
  return (state) => {
    let newAnswers: QuestionAnswer[] = state.questionnaireAnswer

    generator.generatedQuestions.forEach((gq: QuestionViewModel, index: number) => {
      const generatedQuestion = gq
      generatedQuestion.isDisplay = false
      generatedQuestion.hasValidationErrors = false
      if (index <= generator.questionsTemplate.length * selectedMultiplicationNumber - 1) {
        const answer = state.questionnaireAnswer.find((a: QuestionAnswer) => a.questionId === generatedQuestion.id)
        generatedQuestion.isDisplay = true

        if (answer) {
          newAnswers = checkDefaultOptionForGeneratorQuestions(answer, newAnswers, generatedQuestion)
          generatedQuestion.hasValidationErrors = questionHasErrors(generatedQuestion, answer)
        }
      }

      return generatedQuestion
    })

    const newState = { ...updateQuestion(generator) }
    return { ...newState, questionnaireAnswer: newAnswers }
  }
}

function checkDefaultOptionForGeneratorQuestions(answer: QuestionAnswer, newAnswers: QuestionAnswer[], generatedQuestion: QuestionViewModel) {
  return newAnswers.map((a: QuestionAnswer) => {
    if (a.questionId === answer.questionId && answer.text === '') {
      const defaultOption = generatedQuestion.options.find((option) => option.isDefault === true)
      return {
        id: undefined,
        text: defaultOption === undefined ? '' : defaultOption?.id,
        questionId: generatedQuestion.key
      } as QuestionAnswer
    }
    return a
  })
}

function allQuestion(newQuestionnaireVM: QuestionnaireViewModel): QuestionViewModel[] {
  return from(newQuestionnaireVM.groups)
    .selectMany((x) => x.columns)
    .selectMany((x) => x.questions)
    .selectMany((x) => getQuestionsRecursively(x))
    .toArray()
}

function isQuestionDisplayed(question: QuestionViewModel, parentQuestion: QuestionViewModel | undefined, allAnswers: QuestionAnswer[]): boolean {
  if (question.isSharedQuestion) {
    const questionParent = parentQuestion
    if (questionParent === undefined) {
      return false
    }

    const parentAnswer = allAnswers.find((a) => a.questionId === questionParent.key)
    if (parentAnswer === undefined) {
      return false
    }

    return (
      questionParent.isDisplay &&
      questionParent.sharedQuestionsOptions?.filter((sqo: SharedQuestionsOptions) => sqo.sharedQuestionId === question.id && parentAnswer.text.includes(sqo.optionId)).length !== 0
    )
  }

  const parentGeneratedQuestion = parentQuestion?.type === QuestionTypes.Multiplied ? parentQuestion : undefined
  if (parentGeneratedQuestion !== undefined) {
    if (parentGeneratedQuestion.isDisplay === false) {
      return false
    }
    const parentAnswer = allAnswers.find((a) => a.questionId === parentGeneratedQuestion.key)
    if (parentAnswer === undefined) {
      return false
    }
    const gqsPlease = parentGeneratedQuestion.generatedQuestions.findIndex((x) => x.key === question.key)
    return gqsPlease >= 0 && gqsPlease < Number(parentAnswer.text) * parentGeneratedQuestion.questionsTemplate.length
  }
  return true
}

function addQuestionsAnswer(questionsAnswer: QuestionAnswer[]): QuestionnaireAnswerStateActionResult {
  return (state) => {
    const newQuestionnaireAnswer = {
      ...state,
      questionnaireAnswer: questionsAnswer
    }
    const isDirty = state.snapshot !== getSnapshot(newQuestionnaireAnswer.questionnaireAnswer)
    return { newQuestionnaireAnswer, isDirty }
  }
}

function updateAnswer(newAnswer: QuestionAnswer): QuestionnaireAnswerStateActionResult {
  return (state) => {
    let contains = true
    const answers: QuestionAnswer[] = state.questionnaireAnswer.map((a: QuestionAnswer) => {
      if (a.questionId === newAnswer.questionId) {
        contains = false
        return newAnswer
      }
      return a
    })

    if (contains) answers.push(newAnswer)

    const isDirty = state.snapshot !== getSnapshot(answers)
    return { ...state, questionnaireAnswer: answers, isDirty }
  }
}

function updateAnswers(newAnswers: QuestionAnswer[]): QuestionnaireAnswerStateActionResult {
  return (state) => {
    const isDirty = false
    return { ...state, questionnaireAnswer: newAnswers, isDirty }
  }
}

function updateReadOnly(value: boolean): QuestionnaireAnswerStateActionResult {
  return (state) => ({ ...state, isReadOnly: value })
}

function updateIsArchived(value: boolean): QuestionnaireAnswerStateActionResult {
  return (state) => ({ ...state, isArchived: value })
}

function setIsSavingAndGenerating(value: boolean): QuestionnaireAnswerStateActionResult {
  return (state) => ({ ...state, isSavingAndGenerating: value })
}

function setIsDirty(isDirty: boolean): QuestionnaireAnswerStateActionResult {
  return (state) => ({ ...state, isDirty })
}

function updateQuestion(newQuestion: QuestionViewModel): QuestionnaireAnswerStateActionResult {
  return (state) => {
    const groups: GroupContentViewModel[] = state.questionnaireVM.groups?.map((g: GroupContentViewModel) => ({
      ...g,
      columns: g.columns?.map((c: ColumnViewModel) => ({
        ...c,
        questions: c.questions?.map((q: QuestionViewModel) => updateFoundQuestion(q, newQuestion))
      }))
    }))

    let newQuestionnaireVM = { ...state.questionnaireVM, groups }

    newQuestionnaireVM = markErrors(newQuestionnaireVM)
    return { ...state, questionnaireVM: newQuestionnaireVM }
  }
}

const getSnapshot = (questionnaireParam: QuestionAnswer[]) => {
  const data = {
    questionnaireParam: questionnaireParam.map((qa: QuestionAnswer) => ({
      id: qa.id,
      text: qa.text,
      questionId: qa.questionId
    }))
  }

  return JSON.stringify(data)
}

function markErrors(questionnaire: QuestionnaireViewModel): QuestionnaireViewModel {
  return {
    ...questionnaire,
    hasErrors:
      from(questionnaire.groups)
        .selectMany((x) => x.columns)
        .selectMany((x) => x.questions)
        .selectMany((x) => getQuestionsRecursively(x))
        .any((x) => x.hasValidationErrors) ||
      from(questionnaire.groups)
        .selectMany((x) => x.columns)
        .selectMany((x) => x.questions)
        .selectMany((x) => x.options)
        .selectMany((x) => getOptionsRecursively(x))
        .any((x) => x.hasValidationErrors),
    groups: questionnaire.groups.map((g) => ({
      ...g,
      hasErrors:
        from(g.columns)
          .selectMany((x) => x.questions)
          .selectMany((x) => getQuestionsRecursively(x))
          .any((x) => x.hasValidationErrors) ||
        from(g.columns)
          .selectMany((x) => x.questions)
          .selectMany((x) => x.sharedQuestions)
          .selectMany((x) => getQuestionsRecursively(x))
          .any((x) => x.hasValidationErrors) ||
        from(g.columns)
          .selectMany((x) => x.questions)
          .selectMany((x) => x.generatedQuestions)
          .selectMany((x) => getQuestionsRecursively(x))
          .any((x) => x.hasValidationErrors) ||
        from(g.columns)
          .selectMany((x) => x.questions)
          .selectMany((x) => x.sharedQuestions)
          .selectMany((x) => x.generatedQuestions)
          .selectMany((x) => getQuestionsRecursively(x))
          .any((x) => x.hasValidationErrors),
      columns: g.columns.map((c: ColumnViewModel) => ({
        ...c,
        questions: c.questions.map((q: QuestionViewModel) => markQuestionErrors(q))
      }))
    }))
  }
}

function markQuestionErrors(question: QuestionViewModel): QuestionViewModel {
  return {
    ...question,
    hasErrors:
      question.hasValidationErrors ||
      from(getQuestionsRecursively(question)).any((x) => x.hasValidationErrors) ||
      from(question.options || [])
        .selectMany((x) => getOptionsRecursively(x))
        .any((x) => x.hasValidationErrors),
    options: question.options?.map((o: OptionViewModel) => ({
      ...o,
      hasErrors:
        o.hasValidationErrors ||
        from(o.linkedQuestions || [])
          .selectMany((x) => getQuestionsRecursively(x))
          .any((x) => x.hasValidationErrors) ||
        from(o.linkedQuestions || [])
          .selectMany((x) => x.options)
          .selectMany((x) => getOptionsRecursively(x))
          .any((x) => x.hasValidationErrors)
    }))
  }
}

function getOptionsRecursively(option: OptionViewModel): OptionViewModel[] {
  const optionsFromLinkedQuestions = from(option.linkedQuestions || [])
    .selectMany((x) => x.options)
    .toArray()

  const result: OptionViewModel[] = [option]

  if (optionsFromLinkedQuestions.length === 0) {
    return result
  }

  optionsFromLinkedQuestions.forEach((o: OptionViewModel) => {
    if (o !== undefined) {
      result.push(...getOptionsRecursively(o))
    }
  })

  return result
}

function updateFoundQuestion(sourceQuestion: QuestionViewModel, newQuestion: QuestionViewModel): QuestionViewModel {
  if (sourceQuestion.key === newQuestion.key) {
    return newQuestion
  }

  return {
    ...sourceQuestion,
    questionsTemplate: sourceQuestion.questionsTemplate?.map((qt) => updateFoundQuestion(qt, newQuestion)),
    generatedQuestions: sourceQuestion.generatedQuestions?.map((gq) => updateFoundQuestion(gq, newQuestion)),
    sharedQuestions: sourceQuestion.sharedQuestions?.map((sq) => updateFoundQuestion(sq, newQuestion))
  }
}

export default useQuestionnaireAnswerStore
