import { Icon } from '@bdo/kitchensink'
import clsx from 'clsx'
import { useRef } from 'react'
import { useQuestionnaireStore } from 'features/questionnaire/questionaryZustandStore'
import { useDragDrop } from 'hooks/useDragDrop'
import translate from 'i18n/translate'
import { QuestionTypes } from 'services/Questionnaire/questionnaire.models'
import styles from './SharedQuestionPreview.module.scss'
import { QuestionViewModel } from '../_models/QuestionViewModel'
import QuestionPanel from './QuestionPanel'

type Props = {
  sharedQuestion: QuestionViewModel
  index: number
  level: number
  sharedQuestions: QuestionViewModel[]
}

function SharedQuestionPreview(props: Readonly<Props>) {
  const { sharedQuestion, index, level, sharedQuestions } = props
  const { isReadOnly, swapSharedQuestions } = useQuestionnaireStore()
  const ref = useRef<HTMLDivElement>(null)
  const isDragging = useDragDrop(ref, swapSharedQuestions, `item${level}`, index, sharedQuestion.key, isReadOnly)

  return (
    <div className={clsx(isDragging && 'transparent')}>
      <div className={clsx(styles.questionPreview, 'flex', 'alignItems', level === 2 && styles.previewSecondLevel)} ref={ref}>
        <h5 className={clsx('flex', 'alignItems')}>
          <Icon type='Waffle' className={styles.dragIcon} />
          {sharedQuestion.text}
        </h5>
        <div className={clsx('flex', styles.infoWrapper)}>
          <span className={styles.type}>{translate(`form_type_${sharedQuestion.type}`.toLowerCase())}</span>
          <span>{sharedQuestion.isMandatory && <span>{translate('form_mandatory_field')}</span>}</span>
        </div>
      </div>

      {sharedQuestion.type === QuestionTypes.Multiplied &&
        sharedQuestion.questionsTemplate.map((qt) => <QuestionPanel question={qt} key={qt.key} type='template' questionLevel={level + 1} />)}

      {sharedQuestions.map((sq) => (
        <QuestionPanel question={sq} key={sq.key} type='sharedQuestion' questionLevel={level + 1} />
      ))}
    </div>
  )
}

export default SharedQuestionPreview
