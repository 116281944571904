import translate from 'i18n/translate'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import AccountsList from 'features/generalLedger/components/AccountsList/AccountsList'
import AddAccountModal from 'features/generalLedger/components/AddAccountModal/AddAccountModal'
import FilterControls from 'features/generalLedger/components/FilterControls/FilterControls'
import FilterPanel from 'features/generalLedger/components/FilterPanel/FilterPanel'
import ImportButton from 'ui-components/ImportButton/ImportButton'
import { getAccounts as getLedgerAccounts, uploadAccountFile, exportAccounts } from 'services/generalLedger.service'
import { useGeneralLedgerStore } from 'features/generalLedger/generalLedgerStore/generalLedgerStore'
import { Message } from '@bdo/kitchensink'
import { useIntl } from 'react-intl'
import ExportButton from 'ui-components/ExportButton/ExportButton'
import moment from 'moment'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import styles from './GeneralLedger.module.scss'

function GeneralLedger() {
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)
  const setPagination = useGeneralLedgerStore((state) => state.setPagination)
  const setAccounts = useGeneralLedgerStore((state) => state.setAccounts)
  const sortField = useGeneralLedgerStore((state) => state.sortField)
  const sortDirection = useGeneralLedgerStore((state) => state.sortDirection)
  const pageSize = useGeneralLedgerStore((state) => state.pageSize)
  const accounts = useGeneralLedgerStore((state) => state.accounts)
  const filters = useGeneralLedgerStore((state) => state.filters)
  const page = useGeneralLedgerStore((state) => state.page)
  const intl = useIntl()
  const [modalStatusOpen, setModalStatusOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const sortOrder = sortDirection === 'ascend' ? `${sortField}` : `${sortField} desc`

  const onToggleFilters = () => {
    setIsFilterOpen(!isFilterOpen)
  }

  const onCloseFilters = () => {
    setIsFilterOpen(false)
  }

  useEffect(() => {
    setIsLoading(true)
    getLedgerAccounts(page, pageSize, filters, sortOrder)
      .then((data) => {
        setAccounts(data.rows)
        setPagination(page, pageSize, data.total)
        setIsLoading(false)
      })
      .catch((error) => {
        LoggerHelper(error)
        Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }), 5)
      })
      .finally(() => setIsLoading(false))
  }, [page, pageSize, modalStatusOpen, sortOrder])

  const refresh = () => {
    setModalStatusOpen(!modalStatusOpen)
  }

  const getExportFileName = () => {
    const currentDate = moment().utc().format('YYYY-MM-DD')
    return `Ledgers_-_XLSX_-${currentDate}.xlsx`
  }

  return (
    <div className='pagePadding'>
      <h2 className='pageTitle'>{translate('general_ledger')}</h2>
      <div className='card cardTable'>
        <div className={clsx(styles.controlsWrapper, 'flex', 'alignItems')}>
          <div className={clsx('flex', 'alignItems')}>
            <AddAccountModal />
            <ImportButton onUpload={uploadAccountFile} onFinished={refresh} modalTitle={intl.$t({ id: 'import_accounts' })} />
          </div>
          <div className={clsx('flex', 'alignItems')}>
            <ExportButton getData={() => exportAccounts(filters, sortOrder)} exportedFileName={getExportFileName()} disabled={accounts.length === 0} />
            <FilterControls toggleFilters={onToggleFilters} isFilterOpen={isFilterOpen} />
          </div>
        </div>
        {isFilterOpen && <FilterPanel closeFilterPanel={onCloseFilters} />}
        <AccountsList isLoading={isLoading} />
      </div>
    </div>
  )
}

export default GeneralLedger
