import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import translate from 'i18n/translate'
import { Modal } from 'antd'
import { Form, Button } from '@bdo/kitchensink'
import AccountForm from 'features/generalLedger/components/AccountForm/AccountForm'
import axios, { AxiosError } from 'axios'
import { ErrorModel } from 'services/Questionnaire/questionnaire.models'
import { useIntl } from 'react-intl'
import { LedgerAccount, createAccount } from 'services/generalLedger.service'
import Loader from 'ui-components/Loader/Loader'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'

function AddAccountModal() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isFilled, setIsFilled] = useState<boolean>(false)

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isNumber, setIsNumber] = useState(true)
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const intl = useIntl()

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = (values: LedgerAccount) => {
    const newAccount = { ...values, alwaysOn: values.alwaysOn || false }
    setIsSaving(true)
    createAccount(newAccount)
      .then((createdAccount) => {
        setIsModalOpen(false)
        form.resetFields()

        const path = `${createdAccount.id}/account`
        navigate(path)
      })
      .catch(onRequestError)
      .finally(() => {
        setIsFilled(false)
        setIsSaving(false)
      })
  }

  const onRequestError = (e: Error | AxiosError) => {
    LoggerHelper(e)
    setIsModalOpen(true)
    if (axios.isAxiosError(e)) {
      if (e.response && e.response.status === 400) {
        const errors = e.response.data
        form.setFields([
          {
            name: 'accountNumber',
            touched: true,
            errors: errors.AccountNumber.map((error: ErrorModel) => intl.$t({ id: `api_${'account_number'}_${error.errorCode}` }))
          }
        ])
      }
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    form.resetFields()
    setIsFilled(false)
  }

  const onValuesChange = (_: LedgerAccount, allValues: LedgerAccount) => {
    const { alwaysOn, ...requiredValues } = allValues
    const arrayContainsEmptyField = Object.values(requiredValues).some((x) => x === undefined || x === '')
    setIsFilled(!arrayContainsEmptyField)

    if (allValues.accountNumber?.length < 7) {
      const isAccountNumberAStringValue = Number.isNaN(Number(allValues.accountNumber))
      setIsNumber(!isAccountNumberAStringValue)
      form.validateFields(['accountNumber']).catch(() => {
        setIsFilled(false)
      })
    }
  }

  return (
    <>
      <Button type='primary' onClick={showModal}>
        {translate('account_add')}
      </Button>
      <Modal
        title={translate('account_add')}
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form.submit}
        okButtonProps={{ disabled: !isFilled || !isNumber }}
        okText={translate('button_add')}
        cancelText={translate('button_cancel')}
        width={650}
      >
        <Form name='add_account' form={form} layout='vertical' onFinish={handleOk} onValuesChange={onValuesChange}>
          <AccountForm />
        </Form>
      </Modal>
      <Loader open={isSaving} text={intl.$t({ id: 'action_saving' })} />
    </>
  )
}

export default AddAccountModal
