import { Empty } from '@bdo/kitchensink'
import translate from '../../i18n/translate'

function TableEmptyState() {
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      imageStyle={{
        height: 60
      }}
      description={<p className='emptyStateText'>{translate('empty_table')}</p>}
    />
  )
}

export default TableEmptyState
