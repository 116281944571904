/* eslint-disable import/prefer-default-export */
import { GroupContentViewModel } from 'features/questionnaire/components/_models/GroupContentViewModel'
import { OptionViewModel } from 'features/questionnaire/components/_models/OptionViewModel'
import { QuestionViewModel } from 'features/questionnaire/components/_models/QuestionViewModel'
import { Condition, GeneratorRange } from 'services/accountCondition.service'
import { Questionnaire, QuestionTypes } from 'services/Questionnaire/questionnaire.models'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

type AccountState = {
  isDirty: boolean
  questionnaires: Questionnaire[]
  groups: GroupContentViewModel[]
  questions: QuestionViewModel[]
  childQuestions: QuestionViewModel[]
  options: OptionViewModel[]
  selectedQuestionnaireId: string | undefined
  selectedGroupId: string | undefined
  selectedQuestionId: string | undefined
  selectedChildQuestionId: string | undefined
  selectedOptionIds: string[]
  selectedRange: GeneratorRange | undefined
  allAccountConditions: Condition[]
  currentCondition: Condition
  snapshot: string
}

type AccountActions = {
  setQuestionnaires: (questionnaires: Questionnaire[]) => void
  setGroups: (groups: GroupContentViewModel[]) => void
  setQuestions: (questions: QuestionViewModel[]) => void
  setChildQuestions: (questions: QuestionViewModel[]) => void
  setSelectedOption: (optionId: string, checked: boolean) => void
  selectQuestionnaire: (questionnaireId: string) => void
  selectGroup: (groupId: string | undefined) => void
  selectQuestion: (questionId: string | undefined) => void
  selectChildQuestion: (questionId: string | undefined) => void
  setCurrentCondition: (condition: Condition) => void
  setSelectedOptions: (optionIds: string[]) => void
  setSelectedRange: (selectedRange: GeneratorRange | undefined) => void
  addToAllAccountConditions: (condition: Condition) => void
  setAllAccountConditions: (conditions: Condition[]) => void
  updateAllAccountConditions: (condition: Condition) => void
  deleteFromAllAccountConditions: (accountId: number, questionnaireId: string) => void
  cleanupCondition: () => void
}

const initialState: AccountState = {
  isDirty: false,
  questionnaires: [],
  groups: [],
  questions: [],
  childQuestions: [],
  options: [],
  selectedQuestionnaireId: undefined,
  selectedGroupId: undefined,
  selectedQuestionId: undefined,
  selectedChildQuestionId: undefined,
  selectedOptionIds: [],
  selectedRange: undefined,
  allAccountConditions: [],
  currentCondition: {
    groupId: undefined,
    optionIds: [],
    questionId: undefined,
    questionnaireId: '',
    accountId: 0,
    parentQuestionId: undefined
  },
  snapshot: ''
}

export const useAccountConditionStore = create<AccountState & AccountActions, [['zustand/devtools', AccountState & AccountActions]]>(
  devtools(
    (set) => ({
      ...initialState,
      setQuestionnaires: (questionnaires) => set(setQuestionnaires(questionnaires), false, 'Set questionnaires list'),
      setGroups: (groups) => set(setGroups(groups), false, 'Set group list'),
      setQuestions: (questions) => set(setQuestions(questions), false, 'Set question list'),
      setChildQuestions: (questions) => set(setChildQuestions(questions), false, 'Set child questions list'),
      setSelectedOption: (optionId, checked) => set(setSelectedOption(optionId, checked), false, 'Set selected option'),
      setSelectedOptions: (optionIds) => set(setSelectedOptions(optionIds), false, 'Set selected options list'),
      setSelectedRange: (selectedRange) => set(setSelectedRange(selectedRange), false, 'Set selected range'),
      selectQuestionnaire: (questionnaireId) => set(selectQuestionnaire(questionnaireId), false, 'Select questionnaire'),
      selectGroup: (groupId) => set(selectGroup(groupId), false, 'Select group'),
      selectQuestion: (questionId) => set(selectQuestion(questionId), false, 'Select question'),
      selectChildQuestion: (questionId) => set(selectChildQuestion(questionId), false, 'Select child question'),
      setCurrentCondition: (condition) => set(setCurrentCondition(condition), false, 'Set condition'),
      setAllAccountConditions: (conditions) => set(setAllAccountConditions(conditions), false, 'Set all account conditions'),
      addToAllAccountConditions: (condition) => set(addToAllAccountConditions(condition), false, 'Add account condition'),
      updateAllAccountConditions: (condition) => set(updateAllAccountConditions(condition), false, 'Update account condition'),
      deleteFromAllAccountConditions: (accountId, questionnaireId) => set(deleteFromAllAccountConditions(accountId, questionnaireId), false, 'Delete from all account conditions'),
      cleanupCondition: () => set(cleanupCondition(), false, 'Cleanup')
    }),
    { name: 'Onboarding tool - Account condition store' }
  )
)

export const useSelectedRange = () => useAccountConditionStore((state) => state.selectedRange)
export const useGroups = () => useAccountConditionStore((state) => state.groups)
export const useOptions = () => useAccountConditionStore((state) => state.options)
export const useIsDirty = () => useAccountConditionStore((state) => state.isDirty)
export const useQuestions = () => useAccountConditionStore((state) => state.questions)
export const useChildQuestions = () => useAccountConditionStore((state) => state.childQuestions)
export const useQuestionnaires = () => useAccountConditionStore((state) => state.questionnaires)
export const useSelectedOptionId = () => useAccountConditionStore((state) => state.selectedOptionIds)
export const useSelectedQuestionId = () => useAccountConditionStore((state) => state.selectedQuestionId)
export const useSelectedChildQuestionId = () => useAccountConditionStore((state) => state.selectedChildQuestionId)
export const useSelectedGroupId = () => useAccountConditionStore((state) => state.selectedGroupId)
export const useSelectedQuestionnaireId = () => useAccountConditionStore((state) => state.selectedQuestionnaireId)
export const useCurrentCondition = () => useAccountConditionStore((state) => state.currentCondition)
export const useAllAccountConditions = () => useAccountConditionStore((state) => state.allAccountConditions)

function cleanupCondition(): (state: AccountState) => AccountState {
  return (state) => ({
    ...state,
    allAccountConditions: [],
    currentCondition: { ...initialState.currentCondition },
    groups: [],
    options: [],
    questions: [],
    selectedGroupId: undefined,
    selectedOptionIds: [],
    selectedQuestionId: undefined,
    selectedChildQuestionId: undefined,
    selectedQuestionnaireId: undefined,
    snapshot: getSnapshot([], undefined, undefined),
    isDirty: false
  })
}

function getSnapshot(selectedOptionIds: string[], selectedQuestionId: string | undefined, range: GeneratorRange | undefined) {
  const data = {
    selectedOptionIds,
    selectedQuestionId,
    range
  }
  const snapshot = JSON.stringify(data)
  return snapshot
}

function setQuestionnaires(questionnaires: Questionnaire[]): (state: AccountState) => AccountState {
  return (state) => ({ ...state, questionnaires })
}

function setGroups(groups: GroupContentViewModel[]): (state: AccountState) => AccountState {
  return (state) => ({ ...state, groups })
}

function setQuestions(questions: QuestionViewModel[]): (state: AccountState) => AccountState {
  return (state) => {
    const flattenedQuestionsList: QuestionViewModel[] = [...questions]

    questions.forEach((q) => {
      if (q.generatedQuestions.length) {
        q.generatedQuestions.forEach((gq) => {
          if (gq.type === QuestionTypes.Checkbox || gq.type === QuestionTypes.Radio || gq.type === QuestionTypes.Dropdown || gq.type === QuestionTypes.Multiplied) {
            flattenedQuestionsList.push(gq)
          }
        })
      }
    })

    const selectedQuestion = flattenedQuestionsList.find((x) => x.id === state.selectedQuestionId)
    const options = selectedQuestion?.options ?? []
    return { ...state, questions: flattenedQuestionsList, options }
  }
}

function setChildQuestions(questions: QuestionViewModel[]): (state: AccountState) => AccountState {
  return (state) => {
    const selectedChildQuestion = questions.find((x) => x.id === state.selectedChildQuestionId)
    const options = selectedChildQuestion?.options ?? []
    return { ...state, childQuestions: questions, options }
  }
}

function selectQuestionnaire(questionnaireId: string): (state: AccountState) => AccountState {
  return (state) => ({
    ...state,
    selectedQuestionnaireId: questionnaireId,
    selectedGroupId: undefined,
    selectedQuestionId: undefined,
    selectedChildQuestionId: undefined,
    selectedOptionIds: []
  })
}

function setSelectedOption(optionId: string, checked: boolean): (state: AccountState) => AccountState {
  return (state) => {
    let selectedOptions = state.selectedOptionIds
    if (checked) {
      selectedOptions.push(optionId)
    } else {
      selectedOptions = selectedOptions.filter((x) => x !== optionId)
    }

    const isDirty = state.snapshot !== getSnapshot(selectedOptions, state.selectedQuestionId, state.selectedRange)
    return { ...state, selectedOptionIds: [...selectedOptions], isDirty }
  }
}

function setSelectedOptions(optionIds: string[]): (state: AccountState) => AccountState {
  return (state) => {
    const questionWithCondition = state.currentCondition.questionId === state.selectedQuestionId ? state.selectedQuestionId : state.selectedChildQuestionId
    const isDirty = state.snapshot !== getSnapshot(optionIds, questionWithCondition, state.selectedRange)

    return { ...state, selectedOptionIds: optionIds, isDirty }
  }
}

function setSelectedRange(generatorRange: GeneratorRange | undefined): (state: AccountState) => AccountState {
  return (state) => {
    const questionWithCondition = state.currentCondition.questionId === state.selectedQuestionId ? state.selectedQuestionId : state.selectedChildQuestionId

    let range = generatorRange
    if (questionWithCondition !== state.selectedQuestionId && questionWithCondition !== undefined) {
      range = undefined
    }

    const isDirty = state.snapshot !== getSnapshot(state.selectedOptionIds, questionWithCondition, range)
    return { ...state, selectedRange: range, isDirty }
  }
}

function selectGroup(groupId: string | undefined): (state: AccountState) => AccountState {
  return (state) => ({
    ...state,
    selectedGroupId: groupId,
    selectedQuestionId: undefined,
    selectedOptionIds: []
  })
}

function selectQuestion(questionId: string | undefined): (state: AccountState) => AccountState {
  return (state) => {
    const questionOptions = state.questions.find((x) => x.id === questionId)?.options ?? []
    const selectedOptions = state.currentCondition.questionId === questionId ? state.currentCondition.optionIds : []
    const questionWithCondition = state.currentCondition.questionId === questionId ? questionId : state.currentCondition.questionId

    const questionRange = undefined

    const isDirty = state.snapshot !== getSnapshot(selectedOptions, questionWithCondition, questionRange)

    return {
      ...state,
      selectedQuestionId: questionId,
      selectedOptionIds: selectedOptions,
      childQuestions: [],
      selectedChildQuestionId: undefined,
      options: questionOptions,
      isDirty,
      selectedRange: questionRange
    }
  }
}

function selectChildQuestion(childQuestionId: string | undefined): (state: AccountState) => AccountState {
  return (state) => {
    const questionOptions = state.childQuestions.find((x) => x.id === childQuestionId)?.options ?? []

    const selectedOptions =
      state.currentCondition.questionId === childQuestionId && state.currentCondition.parentQuestionId === state.selectedQuestionId ? state.currentCondition.optionIds : []
    const questionRange = undefined
    const isDirty = state.snapshot !== getSnapshot(selectedOptions, childQuestionId, questionRange)

    return {
      ...state,
      selectedChildQuestionId: childQuestionId,
      selectedOptionIds: selectedOptions,
      options: questionOptions,
      isDirty,
      selectedRange: questionRange
    }
  }
}

function setCurrentCondition(condition: Condition): (state: AccountState) => AccountState {
  return (state) => ({
    ...state,
    currentCondition: condition,
    snapshot: getSnapshot(condition.optionIds, condition.questionId, condition.range),
    isDirty: false
  })
}

function setAllAccountConditions(conditions: Condition[]): (state: AccountState) => AccountState {
  return (state) => ({ ...state, allAccountConditions: conditions })
}

function updateAllAccountConditions(condition: Condition): (state: AccountState) => AccountState {
  return (state) => {
    const newAccountConditions = state.allAccountConditions.map((c) => {
      if (c.accountId === condition.accountId && c.questionnaireId === condition.questionnaireId) {
        return condition
      }
      return c
    })

    return { ...state, allAccountConditions: newAccountConditions }
  }
}

function deleteFromAllAccountConditions(accountId: number, questionnaireId: string): (state: AccountState) => AccountState {
  return (state) => {
    const newAccountConditions = state.allAccountConditions.filter((x) => x.accountId !== accountId || x.questionnaireId !== questionnaireId)
    return { ...state, allAccountConditions: newAccountConditions }
  }
}

function addToAllAccountConditions(condition: Condition): (state: AccountState) => AccountState {
  return (state) => ({
    ...state,
    allAccountConditions: [...state.allAccountConditions, condition]
  })
}
