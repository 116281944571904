import { Input } from 'antd'
import { Form, Icon, Radio } from '@bdo/kitchensink'
import translate from 'i18n/translate'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { FormInstance } from '@bdo/kitchensink/lib/components/form/Form'
import { useOption, useQuestionnaireStore, useQuestion, useFormFieldIsReadOnly } from 'features/questionnaire/questionaryZustandStore'
import { useDragDrop } from 'hooks/useDragDrop'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { SharedQuestionsOptions } from 'services/Questionnaire/questionnaire.models'
import IconButton from 'ui-components/IconButton/IconButton'
import { OptionViewModel } from '../_models/OptionViewModel'
import styles from './FieldOption.module.scss'
import { QuestionViewModel } from '../_models/QuestionViewModel'
import RemoveOptionModal from '../RemoveOptionModal/RemoveOptionModal'
import SharedQuestionPreview from '../SharedQuestion/SharedQuestionPreview'

type Props = {
  optionKey: string
  form: FormInstance
  canBeDeleted: boolean
  isSetDefaultOptionEnabled: boolean
  idx: number
  level: number
  questionKey: string
}

function FieldOption(props: Readonly<Props>) {
  const { optionKey, form, canBeDeleted, level, idx, isSetDefaultOptionEnabled, questionKey } = props
  const { isReadOnly, updateOption, removeOption, swapOptions } = useQuestionnaireStore()
  const ref = useRef<HTMLDivElement>(null)
  const option = useOption(optionKey)
  const formFieldIsReadOnly = useFormFieldIsReadOnly()
  const intl = useIntl()
  const isDragging = useDragDrop(ref, swapOptions, `item${level}`, idx, option?.key, isReadOnly)
  const [optionIsOpen, setOptionIsOpen] = useState(false)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false)
  const question = useQuestion(questionKey)
  const [sharedQuestionsPreview, setSharedQuestionsPreview] = useState<QuestionViewModel[]>([])
  const [indexArray, setIndexArray] = useState<number[]>([])
  const [isOptionShared, setIsOptionShared] = useState<boolean>(false)
  const isPublishedQuestionnaire = useQuestionnaireStore((state) => state.isPublishedQuestionnaire)

  useEffect(() => {
    if (!option) {
      return
    }
    form.setFields([{ name: option.key, value: option.text }])
    form.validateFields([option.key])
  }, [])

  useEffect(() => {
    const indexesOfThisOption: number[] = []
    const sharedQuestionsIdForThisOption = question?.sharedQuestionsOptions
      .filter((sq: SharedQuestionsOptions, index: number) => {
        if (sq.optionId === optionKey) indexesOfThisOption.push(index)
        return sq.optionId === optionKey
      })
      .map((ds: SharedQuestionsOptions) => ds.sharedQuestionId)

    setIndexArray(indexesOfThisOption)
    const filteredSharedQuestionsArray: QuestionViewModel[] = []

    sharedQuestionsIdForThisOption?.forEach((sqId: string) => {
      question?.sharedQuestions
        .filter((sq: QuestionViewModel) => sq.key === sqId)
        .forEach((singleSharedQuestion: QuestionViewModel) => filteredSharedQuestionsArray.push(singleSharedQuestion))
    })

    setSharedQuestionsPreview(filteredSharedQuestionsArray)
  }, [question?.sharedQuestionsOptions, question])

  useEffect(() => {
    const optionsId = question?.sharedQuestionsOptions.filter((sq) => sq.optionId === optionKey)
    if (optionsId && optionsId.length > 0) {
      setIsOptionShared(true)
    } else {
      setIsOptionShared(false)
    }
  }, [question?.sharedQuestionsOptions])

  const onChange = () => {
    if (!option) {
      return
    }
    const newOption: OptionViewModel = {
      ...option,
      text: form.getFieldValue(option.key)
    }

    form
      .validateFields([option.key])
      .then(() => {
        newOption.hasValidationErrors = false
      })
      .catch((e) => {
        newOption.hasValidationErrors = e.errorFields.length > 0
      })
      .finally(() => {
        updateOption(newOption)
      })
  }

  const onRemoveOption = () => {
    if (option?.text === '' || option?.id === undefined) {
      removeOption(option?.key)
    } else {
      setIsRemoveModalOpen(true)
    }
  }

  return (
    <>
      <div className={clsx(isDragging && 'transparent')}>
        <div className='flex alignItems'>
          <Radio value={option?.key} className={clsx(styles.radio, isSetDefaultOptionEnabled ? 'radioOn' : 'radioOff')} />
          <div
            className={clsx(
              styles.optionWrapper,
              option?.isNoneOption && styles.noneOption,
              level === 1 && styles.optionWrapperNestedOne,
              level === 2 && styles.optionWrapperNestedTwo,
              'flex',
              'alignItems'
            )}
          >
            <div className={clsx(styles.textField, 'flex', 'alignItems', 'optionField')}>
              <div ref={ref} style={{ visibility: option?.isNoneOption && idx === 0 ? 'hidden' : 'visible' }} data-testId='dragIcon'>
                <Icon type='Waffle' className={styles.dragIcon} />
              </div>

              <Form.Item name={option?.key} rules={[{ required: true, message: translate('forms_required_field') }]}>
                <Input
                  onBlur={() => onChange()}
                  placeholder={intl.$t({ id: 'forms_placeholder_no_characters' }, { value: '164' })}
                  maxLength={164}
                  showCount
                  disabled={isReadOnly || formFieldIsReadOnly}
                />
              </Form.Item>
              {!isPublishedQuestionnaire && !isReadOnly && canBeDeleted && (
                <IconButton
                  onClick={() => onRemoveOption()}
                  type='Delete'
                  disabled={!canBeDeleted || (option?.isNoneOption && idx === 0)}
                  customStyles={clsx(styles.removeButton, option?.isNoneOption && idx === 0 && 'visibilityHidden')}
                  testId='removeBtn'
                />
              )}
            </div>
            <div className={clsx(styles.chevron, 'optionSharedChevronDown')}>
              {isOptionShared && (
                <IconButton
                  onClick={() => {
                    setOptionIsOpen(!optionIsOpen)
                  }}
                  type='ChevronDown'
                  disabled={!isOptionShared}
                  customStyles={clsx(styles.chevronButton, 'iconButton')}
                  customIconStyles={clsx(!optionIsOpen && styles.iconUpsideDown)}
                  testId='openIcon'
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <DndProvider backend={HTML5Backend}>
        {optionIsOpen &&
          sharedQuestionsPreview.map((sq: QuestionViewModel, index: number) => (
            <SharedQuestionPreview sharedQuestion={sq} key={sq.key} index={indexArray[index]} level={level + 1} sharedQuestions={sq.sharedQuestions} />
          ))}
      </DndProvider>

      <RemoveOptionModal questionKey={questionKey} optionKey={optionKey} isRemoveModalOpen={isRemoveModalOpen} setIsRemoveModalOpen={setIsRemoveModalOpen} />
    </>
  )
}

export default FieldOption
