import { useMsal } from '@azure/msal-react'
import { Button } from '@bdo/kitchensink'
import { authConfig } from 'app.config'
import translate from 'i18n/translate'

export default function SignInButton() {
  const { instance } = useMsal()

  const click = () => {
    instance.loginPopup({
      scopes: authConfig.scopes ?? []
    })
  }

  return (
    <Button type='primary' onClick={click}>
      {translate('button_signIn')}
    </Button>
  )
}
