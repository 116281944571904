// eslint-disable-next-line import/no-cycle
import { SortOrder } from 'antd/lib/table/interface'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

type ClientListType = ClientListState | Partial<ClientListState> | ((state: ClientListState) => ClientListState | Partial<ClientListState>)

type ClientListState = {
  sortField: string | undefined
  sortDirection: SortOrder
  page: number
  pageSize: number
  total: number
  searchValue: string
}

type ClientListActions = {
  setOrder: (sortField: string | undefined, sortDirection: SortOrder) => void
  resetSortOrder: () => void
  setPagination: (page: number, pageSize: number, total: number) => void
  setSearchValue: (searchValue: string) => void
}

const initialState: ClientListState = {
  sortField: 'modificationDate',
  sortDirection: 'descend',
  page: 1,
  pageSize: 10,
  total: 0,
  searchValue: ''
}

// eslint-disable-next-line import/prefer-default-export
export const useClientListStore = create<ClientListState & ClientListActions, [['zustand/devtools', ClientListState & ClientListActions]]>(
  devtools(
    (set) => ({
      ...initialState,
      setOrder: (sortField, sortDirection) => set(setOrder(sortField, sortDirection), false, 'Set order'),
      resetSortOrder: () => set(resetSortOrder(), false, 'Reset sort order'),
      setPagination: (page, pageSize, total) => set(setPagination(page, pageSize, total), false, 'Set pagination'),
      setSearchValue: (searchValue) => set(setSearchValue(searchValue), false, 'Set search value')
    }),
    { name: 'Onboarding tool - Client list store' }
  )
)

function setOrder(sortField: string | undefined, sortDirection: SortOrder): ClientListType {
  return (state) => ({ ...state, sortField, sortDirection })
}

function resetSortOrder(): ClientListType {
  return (state) => ({ ...state, sortField: initialState.sortField, sortDirection: initialState.sortDirection })
}

function setPagination(page: number, pageSize: number, total: number): ClientListType {
  return (state) => ({ ...state, page, pageSize, total })
}

function setSearchValue(searchValue: string): ClientListType {
  return (state) => ({ ...state, searchValue })
}
