import translate from 'i18n/translate'
import clsx from 'clsx'
import { useClient } from 'features/clients/clientZustandStore'
import styles from './TabClientDetails.module.scss'

function TabClientDetails() {
  const clientData = useClient()

  return (
    <div className={styles.wrapper}>
      <h3>{translate('client_details')}</h3>
      <div className={clsx(styles.clientDetailsWrapper, 'card', 'flex')}>
        <div className={styles.column}>
          <table>
            <tbody>
              <tr>
                <td>{translate('client_relation_number')}:</td>
                <td>{clientData?.id}</td>
              </tr>
              <tr>
                <td>{translate('client_name')}:</td>
                <td>{clientData?.name}</td>
              </tr>
              <tr>
                <td>{translate('client_address')}:</td>
                <td>{clientData?.address}</td>
              </tr>
              <tr>
                <td>{translate('client_postcode')}:</td>
                <td>{clientData?.postalCode}</td>
              </tr>
              <tr>
                <td>{translate('client_country')}:</td>
                <td>{clientData?.country}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.column}>
          <table>
            <tbody>
              <tr>
                <td>{translate('client_legal_form')}:</td>
                <td>{clientData?.legalForm}</td>
              </tr>
              <tr>
                <td>{translate('client_sbi_main_activity')}:</td>
                <td>
                  {clientData?.sbi?.description} - {clientData?.sbi?.id}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TabClientDetails
