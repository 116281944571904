import translate from 'i18n/translate'
import { Empty } from '@bdo/kitchensink'
import emptyPage from 'assets/images/empty_folder.svg'
import styles from './EmptyPage.module.scss'

type Props = {
  children?: JSX.Element
}

function EmptyPage(props: Readonly<Props>) {
  const { children } = props

  return (
    <div className={styles.emptyStateInfo}>
      <Empty
        image={emptyPage}
        imageStyle={{
          height: 80
        }}
        description={<p className='emptyStateText'>{translate('empty_page')}</p>}
      >
        {children}
      </Empty>
    </div>
  )
}

export default EmptyPage
