import { Message } from '@bdo/kitchensink'
import { useQuestionnaireApplicationName } from 'features/questionnaire/questionaryZustandStore'
import translate from 'i18n/translate'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Apps } from 'services/Questionnaire/questionnaire.models'
import { getAccountConnection } from 'services/Questionnaire/questionnaire.service'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'

export default function ConnectionWarningMessage(props: {
  warningMessageId: string
  onConnectionsCheckFinished: () => void
  filter: { groupId?: string; questionId?: string[]; optionId?: string[] }
}) {
  const { warningMessageId, onConnectionsCheckFinished, filter } = props
  const intl = useIntl()
  const [hasConnections, setHasConnections] = useState<boolean>(false)
  const [connectionsCount, setConnectionsCount] = useState<number>(0)
  const applicationName = useQuestionnaireApplicationName()

  useEffect(() => {
    if (applicationName !== Apps.twinfield) {
      onConnectionsCheckFinished()
      return
    }
    getAccountConnection(filter)
      .then((connectionsResponse) => {
        const uniqueConnectionsPerAccountNumber = [...new Map(connectionsResponse.map((item) => [item.accountId, item])).values()]
        setConnectionsCount(uniqueConnectionsPerAccountNumber.length)
        setHasConnections(uniqueConnectionsPerAccountNumber.length > 0)
        onConnectionsCheckFinished()
      })
      .catch((error) => {
        LoggerHelper(error)
        Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
      })
  }, [])

  return hasConnections ? <p>{translate(warningMessageId, { connectionsCount })}</p> : null
}
