/* eslint-disable no-param-reassign */
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import msalInstance from 'services/PublicClientApplication.service'

const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      'InstrumentationKey=0bc05192-2ab6-476c-9998-1dbe42359861;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
    extensions: [reactPlugin],
    extensionConfig: {},
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true
  }
})
appInsights.loadAppInsights()

appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
  env.tags = env.tags ?? []
  env.tags['ai.cloud.role'] = 'testTag'
  env.tags['ai.user.id'] = msalInstance.getActiveAccount()?.localAccountId
})

export { reactPlugin, appInsights }
