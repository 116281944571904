import { useEffect, useState } from 'react'
import translate from 'i18n/translate'
import { Tooltip } from 'antd'
import { Table } from '@bdo/kitchensink'
import { getQuestionnaires } from 'services/Questionnaire/questionnaire.service'
import { Questionnaire, QuestionnaireStatuses } from 'services/Questionnaire/questionnaire.models'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { toLimitString } from 'utils/StringHelper/string-helper'
import TableEmptyState from 'ui-components/TableEmptyState/TableEmptyState'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import { useIntl } from 'react-intl'
import styles from './QuestionnairesList.module.scss'
import DuplicateAction from '../DuplicateAction/DuplicateAction'
import ArchiveAction from '../ArchiveAction/ArchiveAction'
import StatusBadge from '../StatusBadge/StatusBadge'

type Props = {
  showArchived?: boolean
}

function QuestionnairesList(props: Readonly<Props>) {
  const { showArchived } = props
  const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const intl = useIntl()

  const columns = [
    {
      title: translate('questionnaire_name'),
      dataIndex: 'name',
      key: 'name',
      render: (name: string, record: Questionnaire) => (
        <Link to={`${record.id}/edit`} className={styles.questionnaireLink}>
          <Tooltip title={name} mouseEnterDelay={1}>
            {toLimitString(name, 50)}
          </Tooltip>
        </Link>
      )
    },
    {
      title: translate('table_column_status'),
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => status && <StatusBadge questionnaireStatus={status} />
    },
    {
      title: translate('table_column_modification_date'),
      dataIndex: 'modifiedDate',
      key: 'modifiedDate',
      render: (modifiedDate: Date) => <>{moment.utc(modifiedDate).local().format('DD.MM.YYYY HH:mm')}</>
    },
    {
      title: translate('table_column_modified_by'),
      dataIndex: 'modifiedBy',
      key: 'modifiedBy'
    },
    {
      title: translate('service'),
      dataIndex: 'service',
      key: 'service'
    },
    {
      title: translate('application'),
      dataIndex: 'application',
      key: 'application',
      render: (application: string) => <>{intl.$t({ id: `application_${application}` })}</>
    },
    {
      title: translate('actions'),
      dataIndex: 'action',
      key: 'action',
      width: 83,
      className: 'questionnaireListActions',
      render: (_action: string, record: Questionnaire) => (
        <>
          {record.status === QuestionnaireStatuses.Draft && <ArchiveAction name={record.name} id={record.id} onArchived={() => onQuestionnaireArchived(record.id)} />}
          {record.status === QuestionnaireStatuses.Published && <DuplicateAction name={record.name} id={record.id} />}
        </>
      )
    }
  ]

  useEffect(() => {
    setIsLoading(true)

    const statuses = getStatusesToShow()
    getQuestionnaires(undefined, statuses)
      .then((data) => {
        setQuestionnaires(data)
      })
      .catch((e) => LoggerHelper(e))
      .finally(() => setIsLoading(false))
  }, [showArchived])

  function getStatusesToShow() {
    let statuses = Object.values(QuestionnaireStatuses)
    if (!showArchived) {
      statuses = statuses.filter((x) => x !== QuestionnaireStatuses.Archived)
    }

    return statuses
  }

  const onQuestionnaireArchived = (id: string) => {
    setQuestionnaires(
      questionnaires
        .filter((q) => showArchived || q.id !== id)
        .map((q) => {
          if (q.id === id) {
            return { ...q, status: QuestionnaireStatuses.Archived }
          }
          return q
        })
    )
  }

  return (
    <Table
      dataSource={questionnaires}
      columns={columns}
      rowKey='id'
      loading={isLoading}
      locale={{
        emptyText: <TableEmptyState />
      }}
    />
  )
}

export default QuestionnairesList
