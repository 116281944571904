import { Identifier, XYCoord } from 'dnd-core'
import { useDrag, useDrop } from 'react-dnd'
import DragItem from '../features/questionnaire/components/_models/DragItem'

// eslint-disable-next-line import/prefer-default-export
export const useDragDrop = (
  handler: React.RefObject<HTMLDivElement>,
  onDropped: (itemId: string, dragIndex: number, hoverIndex: number) => void,
  accept: string,
  index: number,
  itemId: string | undefined,
  disabled: boolean
) => {
  const [{ isDragging }, drag] = useDrag({
    type: accept,
    item: () => ({ id: itemId, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(itemParam: DragItem, monitor) {
      const dragItem = itemParam

      if (!handler.current) {
        return
      }
      const dragIndex = dragItem.index
      const hoverIndex = index

      if (dragIndex !== hoverIndex) {
        const hoverBoundingRect = handler.current?.getBoundingClientRect()
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        const clientOffset = monitor.getClientOffset()
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }

        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }

        onDropped(dragItem.id, dragIndex, hoverIndex)

        dragItem.index = hoverIndex
      }
    }
  })

  if (disabled === false) {
    drag(drop(handler))
  }

  return isDragging
}
