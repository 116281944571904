import { Button } from '@bdo/kitchensink'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { FallbackProps } from 'react-error-boundary'
import ErrorIcon from '../../assets/images/404.svg'
import styles from './BoundaryErrorPage.module.scss'
import translate from '../../i18n/translate'

function ErrorBoundaryPage(props: Readonly<FallbackProps>) {
  const { error, resetErrorBoundary } = props

  const [errorMessage, setErrorMessage] = useState<string>()
  const intl = useIntl()
  const navigate = useNavigate()

  useEffect(() => {
    if (error.response?.status) {
      switch (error.response.status) {
        case 401: {
          setErrorMessage(intl.$t({ id: 'message_error_not_authorized' }))
          break
        }
        case 404: {
          setErrorMessage(intl.$t({ id: 'message_error_page_not_found' }))
          break
        }
        default: {
          setErrorMessage(intl.$t({ id: 'message_error_sth_went_wrong' }))
          break
        }
      }
    } else {
      setErrorMessage(intl.$t({ id: 'message_error_sth_went_wrong' }))
    }
  }, [])

  const goBack = () => {
    navigate(-1)
  }

  return (
    <div className={clsx(styles.errorInfoWrapper, 'flex')}>
      <img src={ErrorIcon} alt='404-page-icon' />
      <h3>{errorMessage}</h3>
      <div>
        <Button onClick={resetErrorBoundary} type='primary' className='mr-2'>
          {translate('button_reload_the_page')}
        </Button>
        <Button onClick={goBack} type='default' style={{ background: '#fff' }}>
          {translate('button_goBack')}{' '}
        </Button>
      </div>
    </div>
  )
}

export default ErrorBoundaryPage
