import { Badge } from 'antd'
import translate from 'i18n/translate'
import { Document } from 'services/documents.service'
import TableEmptyState from 'ui-components/TableEmptyState/TableEmptyState'
import { Table } from '@bdo/kitchensink'
import { SorterResult, TablePaginationConfig, TableProps } from '@bdo/kitchensink/lib/components/table/Table'
import moment from 'moment'
import getSorterTooltipText from 'utils/TooltipTextHelper/tooltipTextHelper'
import { useDocumentStore } from '../DocumentsStore'
import ExportSingleDocument from '../ExportSingleDocument/ExportSingleDocument'

type Props = {
  isLoading: boolean
}

function DocumentsList(props: Readonly<Props>) {
  const { isLoading } = props
  const documents = useDocumentStore((state) => state.documents)
  const page = useDocumentStore((state) => state.page)
  const pageSize = useDocumentStore((state) => state.pageSize)
  const latestOnly = useDocumentStore((state) => state.latestOnly)
  const total = useDocumentStore((state) => state.total)
  const setPagination = useDocumentStore((state) => state.setPagination)
  const sortField = useDocumentStore((state) => state.sortField)
  const sortDirection = useDocumentStore((state) => state.sortDirection)
  const resetSortOrder = useDocumentStore((state) => state.resetSortOrder)
  const setOrder = useDocumentStore((state) => state.setOrder)

  const columns = [
    {
      title: translate('table_column_file_name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sortField === 'name' ? sortDirection : undefined,
      render: (name: string, item: Document) => (!latestOnly && item.lastExported === true ? <Badge status='success' text={name} /> : <div>{name}</div>)
    },
    {
      title: translate('table_column_questionnaire'),
      dataIndex: 'questionnaireName',
      key: 'questionnaireName',
      sorter: true,
      sortOrder: sortField === 'questionnaireName' ? sortDirection : undefined
    },
    {
      title: translate('table_column_export_time'),
      dataIndex: 'modifiedDate',
      key: 'modifiedDate',
      sorter: true,
      sortOrder: sortField === 'modifiedDate' ? sortDirection : undefined,
      render: (modifiedDate: Date) => <>{moment.utc(modifiedDate).local().format('DD.MM.YYYY HH:mm')}</>
    },
    {
      title: translate('table_column_modified_by'),
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
      sorter: true,
      sortOrder: sortField === 'modifiedBy' ? sortDirection : undefined
    },
    {
      title: translate('actions'),
      dataIndex: 'action',
      key: 'action',
      width: 60,
      render: (id: number, item: Document) => <ExportSingleDocument documentId={item.id} />
    }
  ]

  const onTableChange: TableProps<Document>['onChange'] = (pagination: TablePaginationConfig, _, sorters: SorterResult<Document> | SorterResult<Document>[]) => {
    const sorter = sorters as SorterResult<Document>

    resetSortOrder()
    if (sorter.field) {
      setOrder(sorter.field.toString(), sorter.order ?? null)
    }
    if (pagination.current && pagination.pageSize && pagination.total) {
      setPagination(pagination.current, pagination.pageSize, total)
    }
  }

  return (
    <Table
      dataSource={documents}
      columns={columns}
      rowKey='id'
      loading={isLoading}
      onChange={onTableChange}
      pagination={{
        current: page,
        pageSize,
        total,
        hideOnSinglePage: true
      }}
      locale={{
        emptyText: <TableEmptyState />
      }}
      showSorterTooltip={{ title: getSorterTooltipText(sortDirection) }}
    />
  )
}

export default DocumentsList
