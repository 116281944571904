import { PublicClientApplication } from '@azure/msal-browser'
import { authConfig } from 'app.config'

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: authConfig.clientId ?? '',
    authority: authConfig.authority,
    redirectUri: authConfig.redirectUri,
    postLogoutRedirectUri: authConfig.logoutRedirect
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  },
  system: {
    pollIntervalMilliseconds: 5
  }
})

export default msalInstance
