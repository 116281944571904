import { Switch, Message } from '@bdo/kitchensink'
import translate from 'i18n/translate'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDocumentStore } from '../DocumentsStore'

function PreviousExportsSwitch() {
  const setLatestOnly = useDocumentStore((state) => state.setLatestOnly)
  const latestOnly = useDocumentStore((state) => state.latestOnly)
  const searchValue = useDocumentStore((state) => state.searchValue)
  const intl = useIntl()

  useEffect(
    () =>
      // eslint-disable-next-line consistent-return
      function resetSwitch() {
        setLatestOnly(true)
      },
    []
  )

  const onSwitchChange = () => {
    setLatestOnly(!latestOnly)
    if (searchValue === '') {
      Message.success(intl.$t({ id: 'user_message_documents_list_updated' }), 5)
    }
  }

  return (
    <div className='flex alignItems'>
      <Switch onChange={onSwitchChange} checked={!latestOnly} />
      <span className='ml-1'>{translate('table_exports_switch')}</span>
    </div>
  )
}

export default PreviousExportsSwitch
