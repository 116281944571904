/* eslint-disable import/prefer-default-export */
import { AccountType, LedgerAccount } from 'services/generalLedger.service'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

type AccountState = {
  accountInfo: LedgerAccount
  snapshot: string
  isDirty: boolean
}

type AccountActions = {
  setAccount: (account: LedgerAccount) => void
  checkIsDirty: (account: LedgerAccount) => void
}

const initialState: AccountState = {
  accountInfo: { id: 0, accountNumber: '', type: AccountType.BAS, englishDescription: '', dutchDescription: '', alwaysOn: false },
  snapshot: '',
  isDirty: false
}

export const useAccountStore = create<AccountState & AccountActions, [['zustand/devtools', AccountState & AccountActions]]>(
  devtools(
    (set) => ({
      ...initialState,
      setAccount: (account: LedgerAccount) => set(setAccount(account), false, 'Update account'),
      checkIsDirty: (account: LedgerAccount) => set(checkIsDirty(account), false, 'Check dirty')
    }),
    { name: 'Onboarding tool - Account store' }
  )
)

function setAccount(account: LedgerAccount): (state: AccountState) => AccountState {
  return (state) => ({
    ...state,
    accountInfo: account,
    isDirty: false,
    snapshot: getSnapshot(account)
  })
}

function getSnapshot(account: LedgerAccount) {
  const data = {
    accountNumber: account.accountNumber,
    type: account.type,
    dutchDescription: account.dutchDescription,
    englishDescription: account.englishDescription,
    alwaysOn: account.alwaysOn
  }
  const snapshot = JSON.stringify(data)
  return snapshot
}

function checkIsDirty(newAccount: LedgerAccount): (state: AccountState) => AccountState {
  return (state) => {
    const isDirty = state.snapshot !== getSnapshot(newAccount)
    return { ...state, isDirty }
  }
}
