import { Link } from 'react-router-dom'
import translate from '../../i18n/translate'

type Props = {
  link: string
  page: string
}

function BackButton(props: Readonly<Props>) {
  const { link, page } = props

  return (
    <div className='mb-1'>
      {translate('general_ui_back_link')}{' '}
      <Link to={link} className='textButton' data-testid='back_button'>
        {page}
      </Link>
    </div>
  )
}

export default BackButton
