import clsx from 'clsx'
import styles from './TabServicesApps.module.scss'

type Props = {
  children: JSX.Element
  name: string
}

export default function ServiceCard(props: Readonly<Props>) {
  const { children, name } = props
  return (
    <div className={clsx(styles.service, 'card')}>
      <div className={clsx(styles.serviceTitleWrapper)}>
        <h5>{name}</h5>
      </div>
      <div className={clsx(styles.appsWrapper)}>{children}</div>
    </div>
  )
}
