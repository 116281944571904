/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Checkbox, Form, Icon, Message, Radio, Select } from '@bdo/kitchensink'
import { Input, InputNumber, Modal, RadioChangeEvent, Tooltip } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import clsx from 'clsx'
import { useQuestion, useQuestionnaireStore, useParentQuestion, useQuestionnaireApplicationName } from 'features/questionnaire/questionaryZustandStore'
import translate from 'i18n/translate'
import { from } from 'linq-to-typescript'
import { useEffect, useRef, useState, FocusEvent } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useIntl } from 'react-intl'
import { Apps, QuestionAccountConnection, QuestionTypes, SharedQuestionsOptions } from 'services/Questionnaire/questionnaire.models'
import { v4 as uuidv4 } from 'uuid'
import AddButton from 'ui-components/AddButton/AddButton'
// eslint-disable-next-line import/no-cycle
import IconButton from 'ui-components/IconButton/IconButton'
import { getAccountConnection } from 'services/Questionnaire/questionnaire.service'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import FieldOption from '../FieldOption/FieldOption'
import { OptionViewModel } from '../_models/OptionViewModel'
import { QuestionViewModel } from '../_models/QuestionViewModel'
import styles from './QuestionOpened.module.scss'
import { useDragDrop } from '../../../../hooks/useDragDrop'
// eslint-disable-next-line import/no-cycle
import Question from '../Question/Question'
import ConnectionWarningMessage from '../ConnectionWarningMessage/ConnectionWarningMessage'
import NoneOptionCheckbox from '../NoneOptionCheckbox/NoneOptionCheckbox'

type Props = {
  questionKey: string
  level: number
  idx: number
}

export default function QuestionOpened(props: Readonly<Props>) {
  const { questionKey, level, idx } = props
  const question = useQuestion(questionKey)
  const [initialMultiplicationFactor] = useState(question?.multiplicationFactor ?? 0)
  const parentQuestion = useParentQuestion(questionKey)
  const [form] = Form.useForm()
  const refOpen = useRef<HTMLDivElement>(null)
  const intl = useIntl()
  const [isTypeChangeWarningOpen, setIsTypeChangeWarningOpen] = useState<boolean>(false)
  const [buttonIsDisabled, setButtonIsDisabled] = useState<boolean>(true)
  const selectedDefaultOptionKey = question && from(question.options).firstOrDefault((x) => x.isDefault)?.key
  const { Option } = Select
  const RadioGroup = Radio.Group
  const [isMultiplicationFactorModalOpen, setIsMultiplicationFactorModalOpen] = useState<boolean>(false)
  const [connectionsCount, setConnectionsCount] = useState<number>(0)
  const applicationName = useQuestionnaireApplicationName()
  const swapQuestions = useQuestionnaireStore((state) => state.swapQuestions)
  const updateQuestion = useQuestionnaireStore((state) => state.updateQuestion)
  const updateCheckingMultiplicationNumber = useQuestionnaireStore((state) => state.updateCheckingMultiplicationNumber)
  const isReadOnly = useQuestionnaireStore((state) => state.isReadOnly)
  const formFieldIsReadOnly = useQuestionnaireStore((state) => state.formFieldIsReadOnly)
  const isPublishedQuestionnaire = useQuestionnaireStore((state) => state.isPublishedQuestionnaire)

  useDragDrop(refOpen, swapQuestions, `qitem${level}`, idx, question?.key, isReadOnly)

  useEffect(() => {
    const questionType = question?.type
    form.setFields([
      { name: 'questionText', value: question?.text },
      { name: 'questionType', value: questionType },
      { name: 'maxNumberOfCharacters', value: question?.maxNumberOfCharacters },
      { name: 'isMandatory', value: question?.isMandatory },
      { name: 'tip', value: question?.tip },
      { name: 'multiplicationFactor', value: question?.multiplicationFactor }
    ])
  }, [question])

  useEffect(() => {
    if (!question?.isOpen) {
      return
    }

    form.validateFields(['questionText', 'multiplicationFactor']).catch(() => {
      handleOnChange()
    })
  }, [question?.isOpen])

  const handleOnChange = (
    newOptions?: OptionViewModel[],
    newTemplateQuestions?: QuestionViewModel[],
    newSharedQuestions?: QuestionViewModel[],
    newSharedQuestionOptions?: SharedQuestionsOptions[]
  ) => {
    if (!question) return

    const questionType: QuestionTypes = form.getFieldValue('questionType')
    const options = newOptions ?? question.options
    const questionsTemplate = newTemplateQuestions ?? question.questionsTemplate
    const sharedQuestions = newSharedQuestions ?? question.sharedQuestions
    const sharedQuestionsOptions = newSharedQuestionOptions ?? question.sharedQuestionsOptions
    const updatedQuestion: QuestionViewModel = {
      ...question,
      isMandatory: form.getFieldValue('isMandatory'),
      maxNumberOfCharacters: questionType === QuestionTypes.Text ? form.getFieldValue('maxNumberOfCharacters') || 164 : null,
      text: form.getFieldValue('questionText'),
      tip: form.getFieldValue('tip'),
      type: questionType,
      isOpen: question.isOpen,
      options,
      isDefaultOptionEnabled: from(options).any((x) => x.isDefault === true),
      multiplicationFactor: questionType === QuestionTypes.Multiplied ? form.getFieldValue('multiplicationFactor') || 1 : null,
      questionsTemplate,
      sharedQuestions,
      sharedQuestionsOptions
    }
    updateQuestion(updatedQuestion)
  }

  const openQuestionTypeChangeWarningModal = (value: QuestionTypes) => {
    if (!question) {
      return
    }

    if (isMultiChoiceQuestion(question.type) && isMultiChoiceQuestion(value)) {
      onQuestionTypeChanged(value)
      return
    }

    if (applicationName !== Apps.twinfield) {
      checkIfUserShouldBeWarned(question.options.length > 0 || question.questionsTemplate.length > 0, value)
    } else {
      getAccountConnection({ questionId: [questionKey] })
        .then((connections) => {
          checkIfUserShouldBeWarned(connections.length > 0 || question.options.length > 0 || question.questionsTemplate.length > 0, value)
        })
        .catch((e) => {
          LoggerHelper(e)
        })
    }
  }

  function checkIfUserShouldBeWarned(condition: boolean, value: QuestionTypes) {
    if (condition) {
      setIsTypeChangeWarningOpen(true)
    } else {
      onQuestionTypeChanged(value)
    }
  }

  const changeQuestionTypeConfirmed = () => {
    const questionType = form.getFieldValue('questionType')
    onQuestionTypeChanged(questionType)
    setIsTypeChangeWarningOpen(false)
  }

  const changeQuestionTypeCancelled = () => {
    form.setFields([{ name: 'questionType', value: question?.type }])
    setIsTypeChangeWarningOpen(false)
  }

  const handleMultiplicationFactorOnChange = (): void => {
    updateCheckingMultiplicationNumber(true)
  }

  const handleMultiplicationFactorChange = (event: FocusEvent<HTMLInputElement>): void => {
    if (!question) return

    const value: number = Number.parseInt(event.target.value || '0', 10)
    if (value >= initialMultiplicationFactor) {
      handleOnChange()
      updateCheckingMultiplicationNumber(false)
      return
    }
    if (applicationName !== Apps.twinfield) {
      handleOnChange()
    } else {
      getAccountConnection({ questionId: [questionKey] })
        .then((connections) => {
          updateCheckingMultiplicationNumber(false)

          if (connections.length === 0) {
            handleOnChange()
            return
          }

          const { affectedQuestionWithDirectCondition, affectedQuestionsWithRangeCondition } = checkAffectedQuestions(value, connections)

          const rangeConnectionsWithinScope = affectedQuestionsWithRangeCondition.filter((map) => (map?.range?.max ?? 0) > value)

          const allFoundConnnectionsAccountNumbers = affectedQuestionWithDirectCondition.concat(rangeConnectionsWithinScope).map((x) => x?.accountId)
          const uniqueAccountNumbersList: Array<string | undefined> = []

          allFoundConnnectionsAccountNumbers.forEach((item, index) => {
            if (allFoundConnnectionsAccountNumbers.indexOf(item) === index) {
              uniqueAccountNumbersList.push(item)
            }
          })

          setConnectionsCount(uniqueAccountNumbersList.length)

          if (uniqueAccountNumbersList.length > 0) {
            setIsMultiplicationFactorModalOpen(true)
          } else {
            handleOnChange()
          }
        })
        .catch((error) => {
          LoggerHelper(error)
          Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
        })
    }
  }

  function checkAffectedQuestions(value: number, connections: QuestionAccountConnection[]) {
    const factorDifference = initialMultiplicationFactor - value
    const involvedGeneratedQuestionsCount = factorDifference * question.questionsTemplate.length
    const affectedQuestionWithDirectCondition: Array<QuestionAccountConnection | undefined> = []
    let affectedQuestionsWithRangeCondition: Array<QuestionAccountConnection | undefined> = []

    if (question?.isQuestionTemplate && parentQuestion) {
      parentQuestion.generatedQuestions
        .map((gq) => gq.generatedQuestions.slice(-involvedGeneratedQuestionsCount))
        .flatMap((x) => x)
        .forEach((q) => {
          connections
            .filter((c) => c.questionId === q.key)
            .forEach((x) => {
              affectedQuestionWithDirectCondition.push(x)
            })
        })

      parentQuestion.generatedQuestions
        .filter((x) => x.type === QuestionTypes.Multiplied)
        .forEach((q) => {
          connections
            .filter((c) => c.questionId === q.key)
            .forEach((x) => {
              affectedQuestionsWithRangeCondition.push(x)
            })
        })
    } else {
      question?.generatedQuestions
        .slice(-involvedGeneratedQuestionsCount)
        .flatMap((x) => [x, ...x.generatedQuestions])
        .forEach((q) => {
          connections
            .filter((c) => c.questionId === q.key)
            .forEach((x) => {
              affectedQuestionWithDirectCondition.push(x)
            })
        })

      affectedQuestionsWithRangeCondition = connections.filter((c) => c.questionId === question.id)
    }

    return { affectedQuestionWithDirectCondition, affectedQuestionsWithRangeCondition }
  }

  const changeMultiplicationFactorConfirmed = () => {
    setIsMultiplicationFactorModalOpen(false)
    handleOnChange()
  }

  const changeMultiplicationFactorCancelled = () => {
    form.setFields([{ name: 'multiplicationFactor', value: question?.multiplicationFactor }])
    setIsMultiplicationFactorModalOpen(false)
  }

  const onQuestionTypeChanged = (value: QuestionTypes) => {
    if (!question) {
      return
    }
    let { options, questionsTemplate, sharedQuestions, sharedQuestionsOptions } = question
    if (isMultiChoiceQuestion(value) && options.length === 0) {
      options = getDefaultOptions()
    } else if (!isMultiChoiceQuestion(value)) {
      options = []
    }

    if (value !== QuestionTypes.Radio && value !== QuestionTypes.Checkbox) {
      options = setAllOptionsToNotBeDefault(options)
    }

    if (value !== QuestionTypes.Multiplied) {
      questionsTemplate = []
    }

    if (!isMultiChoiceQuestion(value)) {
      sharedQuestions = []
      sharedQuestionsOptions = []
    }

    handleOnChange(options, questionsTemplate, sharedQuestions, sharedQuestionsOptions)
  }

  const setAllOptionsToNotBeDefault = (options: OptionViewModel[]): OptionViewModel[] => options.map((o: OptionViewModel) => ({ ...o, isDefault: false }))

  const onDefaultOptionEnabledChanged = (e: CheckboxChangeEvent) => {
    if (!question) {
      return
    }
    const isDefaultEnabled = e.target.checked

    let options: OptionViewModel[] = []
    if (!isDefaultEnabled) {
      options = setAllOptionsToNotBeDefault(question.options)
    } else {
      options = setFirstOptionToBeDefault(question.options)
    }

    handleOnChange(options)
  }

  const setFirstOptionToBeDefault = (options: OptionViewModel[]): OptionViewModel[] => {
    const arrWithFirstOptionDefault = options.map((option: OptionViewModel, index) => {
      if (index === 0) {
        return { ...option, isDefault: true }
      }
      return { ...option }
    })

    return arrWithFirstOptionDefault
  }

  const onSelectDefaultOption = (e: RadioChangeEvent) => {
    const newOptions = question?.options.map((option: OptionViewModel) => {
      if (option.key === e.target.value) {
        return { ...option, isDefault: true }
      }
      return { ...option, isDefault: false }
    })

    handleOnChange(newOptions)
  }

  const addOption = () => {
    if (!question) {
      return
    }

    const uuid = uuidv4()
    const newOption: OptionViewModel = {
      key: uuid,
      text: '',
      hasLinkedQuestions: false,
      isDefault: false,
      isNoneOption: false,
      linkedQuestions: [],
      hasErrors: false,
      hasValidationErrors: true
    }

    handleOnChange([...question.options, newOption])
  }

  const setIsQuestionOpen = (isOpen: boolean) => {
    if (!question) {
      return
    }
    const updatedQuestion = {
      ...question,
      isOpen
    }

    updateQuestion(updatedQuestion)
  }

  const addQuestionTemplate = () => {
    if (!question) {
      return
    }
    if (question.questionsTemplate.length >= 5) {
      return
    }
    const newTemplateQuestion: QuestionViewModel = {
      key: uuidv4(),
      text: '',
      isMandatory: false,
      type: 'Text',
      tip: '',
      isOpen: true,
      maxNumberOfCharacters: 164,
      hasErrors: false,
      isDisplay: false,
      options: [],
      hasValidationErrors: true,
      questionsTemplate: [],
      isQuestionTemplate: true,
      generatedQuestions: [],
      sharedQuestions: [],
      isSharedQuestion: false,
      sharedQuestionsOptions: []
    }

    const newTemplate = [...question.questionsTemplate, newTemplateQuestion]
    handleOnChange([], newTemplate)
  }

  const addSharedQuestion = () => {
    if (!question) {
      return
    }
    const newSharedQuestion: QuestionViewModel = {
      key: uuidv4(),
      text: '',
      isMandatory: false,
      type: 'Text',
      tip: '',
      isOpen: true,
      maxNumberOfCharacters: 164,
      hasErrors: false,
      options: [],
      hasValidationErrors: true,
      isDisplay: false,
      questionsTemplate: [],
      isQuestionTemplate: false,
      generatedQuestions: [],
      sharedQuestions: [],
      isSharedQuestion: true,
      sharedQuestionsOptions: []
    }

    const sharedQuestions = [...question.sharedQuestions, newSharedQuestion]
    handleOnChange(undefined, [], sharedQuestions)
  }

  return (
    <Form name='question' form={form} layout='vertical' initialValues={{ multiplicationFactor: '1' }}>
      <div className={clsx(styles.questionWrapper, 'card', 'mb-3', parentQuestion?.isQuestionTemplate ? 'childTemplateRef' : 'questionRef')}>
        <div className={clsx(styles.titleWrapper, 'flex', 'alignItems')} ref={question?.isSharedQuestion ? null : refOpen}>
          <div className='flex alignItems'>
            {!question?.isSharedQuestion && <Icon type='Waffle' className={styles.dragIcon} />}
            <h4 data-testid='question-title'>{question?.text ? question.text.trim() : translate('question_add_new')}</h4>
          </div>
          <Tooltip title={translate('button_close')} mouseEnterDelay={1} placement='right'>
            <IconButton type='ChromeClose' onClick={() => setIsQuestionOpen(false)} testId='ChromeCloseIcon-element' customStyles={clsx(styles.closeIcon, 'ml-3')} />
          </Tooltip>
        </div>

        <div className={clsx(styles.formWrapper, 'flex')}>
          <div className={styles.column}>
            <Form.Item
              name='questionText'
              label={translate('question_text')}
              rules={[
                {
                  required: true,
                  message: translate('forms_required_field')
                },
                {}
              ]}
            >
              <Input
                onBlur={() => handleOnChange()}
                placeholder={intl.$t({ id: 'forms_placeholder_no_characters' }, { value: '164' })}
                showCount
                maxLength={164}
                disabled={isReadOnly || formFieldIsReadOnly}
                data-testid='questionText'
              />
            </Form.Item>

            <Form.Item
              name='questionType'
              label={translate('form_field_type')}
              rules={[
                {
                  required: true,
                  message: translate('forms_required_field')
                }
              ]}
            >
              <Select
                onChange={openQuestionTypeChangeWarningModal}
                placeholder={intl.$t({ id: 'form_select' })}
                disabled={isReadOnly || formFieldIsReadOnly || isPublishedQuestionnaire}
                data-testid='questionType'
              >
                <Option value='Text'>{translate('form_type_text')}</Option>
                <Option value='Number'>{translate('form_type_number')}</Option>
                <Option value='Date'>{translate('form_type_date')}</Option>
                <Option value='Dropdown'>{translate('form_type_dropdown')}</Option>
                <Option value='Radio'>{translate('form_type_radio')}</Option>
                <Option value='Checkbox'>{translate('form_type_checkbox')}</Option>
                {!parentQuestion?.isQuestionTemplate && <Option value='Multiplied'>{translate('form_type_multiplied')}</Option>}
              </Select>
            </Form.Item>

            <Modal
              title={translate('user_message_change_question_type')}
              open={isTypeChangeWarningOpen}
              onOk={changeQuestionTypeConfirmed}
              okButtonProps={{ disabled: buttonIsDisabled }}
              onCancel={changeQuestionTypeCancelled}
              okText={translate('button_confirm')}
              cancelText={translate('button_cancel')}
            >
              <ConnectionWarningMessage
                filter={{ questionId: [questionKey] }}
                onConnectionsCheckFinished={() => setButtonIsDisabled(false)}
                warningMessageId='user_message_when_change_type_question_condition_description_warning_message'
              />
              {(question?.type === QuestionTypes.Checkbox || question?.type === QuestionTypes.Radio || question?.type === QuestionTypes.Dropdown) &&
                translate('user_message_change_question_type_info')}
              {question && question.questionsTemplate.length > 0 && translate('user_message_change_type_from_question_generator')}
            </Modal>

            {question?.type === QuestionTypes.Text && (
              <Form.Item
                name='maxNumberOfCharacters'
                label={translate('form_max_no_characters')}
                rules={[
                  {
                    required: true,
                    message: translate('forms_required_field')
                  }
                ]}
              >
                <InputNumber
                  onBlur={() => handleOnChange()}
                  placeholder={intl.$t({
                    id: 'form_placeholder_max_no_characters'
                  })}
                  min={0}
                  max={164}
                  disabled={isReadOnly || formFieldIsReadOnly}
                  type='number'
                  data-testid='questionMaxCharacterNumber'
                />
              </Form.Item>
            )}

            <Form.Item name='isMandatory' valuePropName='checked'>
              <Checkbox onChange={() => handleOnChange()} disabled={isReadOnly || formFieldIsReadOnly}>
                {translate('form_mandatory_field')}
              </Checkbox>
            </Form.Item>
          </div>
          <div className={styles.column}>
            <Form.Item name='tip' label={translate('question_hint')}>
              <Input.TextArea onBlur={() => handleOnChange()} autoSize={{ minRows: 5, maxRows: 9 }} disabled={isReadOnly || formFieldIsReadOnly} data-testid='questionTip' />
            </Form.Item>
          </div>
        </div>
        <div className={styles.typesContent}>
          {(question?.type === QuestionTypes.Dropdown || question?.type === QuestionTypes.Radio || question?.type === QuestionTypes.Checkbox) && (
            <>
              <div className={styles.optionsWrapper} data-testid='optionsContainer'>
                <h5>{translate('question_options')}</h5>
                <div className='flex alignItems'>
                  <div className={styles.optionSwitch}>
                    <Checkbox checked={question.isDefaultOptionEnabled} onChange={onDefaultOptionEnabledChanged} disabled={isReadOnly || formFieldIsReadOnly}>
                      {translate('form_set_default_option')}
                    </Checkbox>
                  </div>
                  {question?.type === QuestionTypes.Checkbox && (
                    <div className={clsx(styles.optionSwitch, 'flex', 'alignItems')}>
                      <NoneOptionCheckbox
                        questionKey={questionKey}
                        addNoneOption={(newOptions) => {
                          handleOnChange(newOptions)
                        }}
                      />
                    </div>
                  )}
                </div>

                <RadioGroup onChange={onSelectDefaultOption} value={selectedDefaultOptionKey} disabled={isReadOnly || formFieldIsReadOnly}>
                  <DndProvider backend={HTML5Backend}>
                    {question.options?.map((option: OptionViewModel, optIdx: number) => (
                      <FieldOption
                        form={form}
                        key={option.key}
                        optionKey={option.key}
                        canBeDeleted={question.options.length > 2}
                        level={level}
                        idx={optIdx}
                        isSetDefaultOptionEnabled={question.isDefaultOptionEnabled ?? false}
                        questionKey={question.key}
                      />
                    ))}
                  </DndProvider>
                  {!isReadOnly && !isPublishedQuestionnaire && (
                    <AddButton handleAction={addOption} data-testId='add_option_button'>
                      {translate('question_more_options')}
                    </AddButton>
                  )}
                </RadioGroup>
              </div>
              {level < 2 && !question.isQuestionTemplate && (
                <div className={styles.sharedQuestionsWrapper} data-testid='shared_questions_container'>
                  <h5>{translate('linked_questions')}</h5>
                  <div className={clsx('questionWrapper', level === 1 && 'questionWrapperNestedOne')}>
                    {question.sharedQuestions?.map((q: QuestionViewModel, index: number) => (
                      <Question questionKey={q.key} key={q.key} level={level + 1} idx={index} />
                    ))}
                  </div>
                  {!isPublishedQuestionnaire && !isReadOnly && <AddButton handleAction={addSharedQuestion}>{translate('question_linked_questions')}</AddButton>}
                </div>
              )}
            </>
          )}
          {question?.type === QuestionTypes.Multiplied && (
            <div className={styles.multipliedWrapper}>
              <div className={clsx(styles.multiplied)}>
                <Form.Item
                  name='multiplicationFactor'
                  label={translate('form_multiplication_number')}
                  rules={[
                    { required: true, message: translate('forms_required_field') },
                    {
                      validator: (_, value) =>
                        value > 0 && value <= 100 ? Promise.resolve() : Promise.reject(new Error(intl.$t({ id: 'user_message_number_field_range' }, { value: '100' })))
                    }
                  ]}
                >
                  <InputNumber
                    min={1}
                    onChange={handleMultiplicationFactorOnChange}
                    onBlur={handleMultiplicationFactorChange}
                    disabled={isReadOnly || formFieldIsReadOnly || isPublishedQuestionnaire}
                    data-testid='multiplication_factor'
                    type='number'
                  />
                </Form.Item>
              </div>
              <h5>{translate('form_generated_questions_template')}</h5>
              <div className='questionTemplateRef'>
                {(question.questionsTemplate?.length || 0) === 0 && (
                  <span className={clsx(styles.templateWarning, 'flex', 'alignItems')}>
                    <Icon type='Error' />
                    <span>{translate('user_message_at_least_one_template_question')}</span>
                  </span>
                )}
                {question.questionsTemplate?.map((q: QuestionViewModel, index: number) => (
                  <Question questionKey={q.key} key={q.key} level={0} idx={index} />
                ))}
              </div>
              {!isPublishedQuestionnaire && !isReadOnly && (
                <AddButton handleAction={addQuestionTemplate} disabled={question.questionsTemplate.length >= 5 || isPublishedQuestionnaire}>
                  {translate('question_template_add_new')}
                </AddButton>
              )}

              <Modal
                style={{ whiteSpace: 'pre-line' }}
                title={translate('user_message_factor_number_decreasing_question')}
                open={isMultiplicationFactorModalOpen}
                onOk={changeMultiplicationFactorConfirmed}
                onCancel={changeMultiplicationFactorCancelled}
                okText={translate('button_confirm')}
                cancelText={translate('button_cancel')}
              >
                {translate('user_message_when_factor_decreasing_message', { value: connectionsCount })}
              </Modal>
            </div>
          )}
        </div>

        <Button type='default' onClick={() => setIsQuestionOpen(false)} className='buttonWhite'>
          {translate('button_done')}
        </Button>
      </div>
    </Form>
  )

  function isMultiChoiceQuestion(questionType: string) {
    return questionType === QuestionTypes.Dropdown || questionType === QuestionTypes.Radio || questionType === QuestionTypes.Checkbox
  }

  function getDefaultOptions(): OptionViewModel[] {
    const newOption1: OptionViewModel = {
      key: uuidv4(),
      text: '',
      hasLinkedQuestions: false,
      isDefault: false,
      isNoneOption: false,
      linkedQuestions: [],
      hasErrors: false,
      hasValidationErrors: true
    }
    const newOption2: OptionViewModel = {
      key: uuidv4(),
      text: '',
      hasLinkedQuestions: false,
      isDefault: false,
      isNoneOption: false,
      linkedQuestions: [],
      hasErrors: false,
      hasValidationErrors: true
    }

    return [newOption1, newOption2]
  }
}
