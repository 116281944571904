/* eslint-disable no-useless-escape */
export function toLimitString(text: string | undefined, maxLength: number): string | undefined {
  if (maxLength <= 0) throw new Error('maxLength cannot be less than 1')

  if (text && text?.length > maxLength) {
    return `${text.substring(0, maxLength)}...`
  }

  return text
}

export function toKey(text: string): string {
  return text
    .toLowerCase()
    .replace(/\s\s+/g, ' ')
    .replace(/\s/g, '_')
    .replace(/[!?@#$%^&*()+<>;:/\"\'\`]/g, '')
}
