import { Modal, Input, Tooltip } from 'antd'
import { Form, Icon, Message } from '@bdo/kitchensink'
import { useState } from 'react'
import translate from 'i18n/translate'
import { updateTemplate as requestUpdateTemplate, Template } from 'services/templates.service'
import { useTemplatesStore } from 'features/generalLedger/TemplatesStore/TemplatesStore'
import axios, { AxiosError } from 'axios'
import { ErrorModel } from 'services/Questionnaire/questionnaire.models'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import Loader from 'ui-components/Loader/Loader'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import styles from './TemplateEdit.module.scss'

type Props = {
  name: string
  id: string
}

function TemplateEdit(props: Readonly<Props>) {
  const { name, id } = props
  const intl = useIntl()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isFilled, setIsFilled] = useState(false)
  const [form] = Form.useForm()
  const { updateTemplate } = useTemplatesStore()
  // eslint-disable-next-line no-useless-escape
  const regexExcludingSomeSpecialChar = /^[a-zA-Z0-9 \/.-]+$/

  const showModal = () => {
    setIsModalOpen(true)
    form.setFields([{ name: 'name', value: name }])
  }

  const handleOk = (values: Template) => {
    setIsSaving(true)
    const newName = { ...values, id }
    requestUpdateTemplate(newName)
      .then((data) => {
        updateTemplate(data)
        setIsModalOpen(false)
        form.resetFields()
        Message.success(intl.$t({ id: 'user_message_when_update_template' }), 5)
        setIsSaving(false)
      })
      .catch(onRequestError)
      .finally(() => {
        setIsFilled(false)
        setIsSaving(false)
      })
  }

  const onRequestError = (e: Error | AxiosError) => {
    LoggerHelper(e)
    setIsModalOpen(true)
    if (axios.isAxiosError(e)) {
      if (e.response && e.response.status === 400) {
        const errors = e.response.data
        form.setFields([
          {
            name: 'name',
            touched: true,
            errors: errors.Name.map((error: ErrorModel) => intl.$t({ id: `api_${'template'}_${error.errorCode}` }))
          }
        ])
      }
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    form.resetFields()
    setIsFilled(false)
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFilled(true)
    if (e.target.value === '' || regexExcludingSomeSpecialChar.exec(e.target.value) === null) {
      setIsFilled(false)
    }
  }

  return (
    <>
      <Tooltip title={translate('button_edit')} mouseEnterDelay={0.5}>
        <button type='button' aria-label='button' onClick={showModal} className={clsx(styles.editButton, 'iconButton')}>
          <Icon type='Edit' />
        </button>
      </Tooltip>
      <Modal
        title={translate('description_template_edit')}
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form.submit}
        okButtonProps={{ disabled: !isFilled }}
        okText={translate('button_save')}
        cancelText={translate('button_cancel')}
      >
        <Form name='edit_template' form={form} layout='vertical' onFinish={handleOk}>
          <Form.Item
            name='name'
            label={translate('description_template')}
            rules={[
              { pattern: regexExcludingSomeSpecialChar, message: translate('user_message_integer_string') },
              { required: true, message: translate('forms_required_field') },
              { whitespace: true, message: translate('user_message_field_empty') }
            ]}
          >
            <Input placeholder={intl.$t({ id: 'form_placeholder_no_brackets' })} maxLength={164} showCount onChange={handleOnChange} />
          </Form.Item>
        </Form>
      </Modal>
      <Loader open={isSaving} text={intl.$t({ id: 'action_saving' })} />
    </>
  )
}

export default TemplateEdit
