import axios from 'axios'
import buildQuery from 'odata-query'
import { onboardingToolApiConfig } from '../../app.config'
import { PaginatedResult } from '../service.models'
import { ClientListItem, ClientDto } from './client.models'

const clientsUri = `${onboardingToolApiConfig.uri}/clients`

export async function getClients(page: number, pageSize: number, search?: string, sorter?: string): Promise<PaginatedResult<ClientListItem>> {
  if (page < 1) {
    throw Error('page cannot be lower than 1')
  }

  let filterValue = ''
  const top = pageSize
  const skip = pageSize * (page - 1)
  const orderBy = sorter
  filterValue = buildQuery({ orderBy, top, skip })
  if (search) {
    const lowerCaseValue = search.toLowerCase()
    const filter = { or: [{ 'tolower(Name)': { contains: lowerCaseValue } }, { 'cast(Id,Edm.String)': { contains: search } }] }
    filterValue = buildQuery({ filter, orderBy, top, skip })
  }

  const getUri = `${clientsUri}${filterValue}`

  const response = await axios.get<PaginatedResult<ClientListItem>>(getUri)
  return response.data
}

export async function getClient(id: number): Promise<ClientDto> {
  const getUri = `${clientsUri}/${id}`
  const client = await axios.get<ClientDto>(getUri)
  return client.data
}
