import axios from 'axios'
import queryString from 'query-string'
import { onboardingToolApiConfig } from '../app.config'

export type AccountDescription = {
  id?: number
  accountId: number
  questionnaireId: string
  groupId: string
  questionId: string
  descriptionTemplateId: string
  parentQuestionId?: string
}

type AccountDescriptionRequest = {
  id?: number
  accountId: number
  descriptionTemplateId: string
  questionnaireId: string
  groupId: string
  questionId: string
  parentQuestionId?: string
}

const accountDescriptionsUri = `${onboardingToolApiConfig.uri}/accountDescriptions`

export async function getAccountDescriptions(accountId: number): Promise<AccountDescription[]> {
  const getUri = `${accountDescriptionsUri}/${accountId}`
  const getResponse = await axios.get<AccountDescription[]>(getUri)

  return getResponse.data
}

export async function addAccountDescription(description: AccountDescription): Promise<AccountDescription> {
  const body: AccountDescriptionRequest = {
    accountId: description.accountId,
    descriptionTemplateId: description.descriptionTemplateId,
    questionnaireId: description.questionnaireId,
    groupId: description.groupId,
    questionId: description.questionId,
    parentQuestionId: description.parentQuestionId
  }

  const postResponse = await axios.post<AccountDescription>(accountDescriptionsUri, body)

  return postResponse.data
}

export async function updateAccountDescription(description: AccountDescription): Promise<AccountDescription> {
  const body: AccountDescriptionRequest = {
    id: description.id,
    accountId: description.accountId,
    descriptionTemplateId: description.descriptionTemplateId,
    groupId: description.groupId,
    questionId: description.questionId,
    questionnaireId: description.questionnaireId,
    parentQuestionId: description.parentQuestionId
  }

  const putResponse = await axios.put<AccountDescription>(accountDescriptionsUri, body)

  return putResponse.data
}

export async function deleteAccountDescription(accountId: number, questionnaireId: string) {
  const deleteUri = queryString.stringifyUrl({ url: accountDescriptionsUri, query: { accountId, questionnaireId } })
  await axios.delete(deleteUri)
}
