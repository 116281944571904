import create from 'zustand'
import { devtools } from 'zustand/middleware'
import { Document } from 'services/documents.service'
import { SortOrder } from 'antd/lib/table/interface'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'

type DocumentsState = {
  documents: Document[]
  page: number
  pageSize: number
  total: number
  searchValue: string
  latestOnly: boolean
  sortField: string | undefined
  sortDirection: SortOrder
}

type DocumentsActions = {
  setDocuments: (documents: Document[]) => void
  setPagination: (page: number, pageSize: number, total: number) => void
  setSearchValue: (searchValue: string) => void
  setLatestOnly: (value: boolean) => void
  setOrder: (sortField: string | undefined, sortDirection: SortOrder) => void
  resetSortOrder: () => void
  cleanUpStore: () => void
}

const initialState: DocumentsState = {
  documents: [],
  page: 1,
  pageSize: 10,
  total: 0,
  searchValue: '',
  latestOnly: true,
  sortField: 'modifiedDate',
  sortDirection: 'descend'
}

// eslint-disable-next-line import/prefer-default-export
export const useDocumentStore = create<DocumentsState & DocumentsActions, [['zustand/devtools', DocumentsState & DocumentsActions]]>(
  devtools(
    (set) => ({
      ...initialState,
      setDocuments: (documents) => set(setDocuments(documents), false, 'Set documents'),
      setPagination: (page, pageSize, total) => set(setPagination(page, pageSize, total), false, 'Set pagination'),
      setSearchValue: (searchValue) => set(setSearchValue(searchValue), false, 'Set search value'),
      setOrder: (sortField, sortDirection) => set(setOrder(sortField, sortDirection), false, 'Set order'),
      resetSortOrder: () => set(resetSortOrder(), false, 'Reset sort order'),
      setLatestOnly: (value) => {
        set(setLatestOnly(value), false, 'Update value of latestOnly')
      },
      cleanUpStore: () => {
        set((state) => ({ ...state, ...initialState }), false, 'Reset state to initial values')
      }
    }),
    { name: 'Onboarding tool - Documents store' }
  )
)

export const useDocument = (documentId: number | undefined) =>
  useDocumentStore((state) => {
    const document = state.documents.find((d) => d.id === documentId)
    try {
      if (!document) {
        throw new Error(`Cannot find document with id: ${documentId}`)
      }
    } catch (error) {
      LoggerHelper(error)
    }

    return document
  })

function setDocuments(documents: Document[]): (state: DocumentsState) => DocumentsState {
  return (state) => ({ ...state, documents })
}

function setPagination(page: number, pageSize: number, total: number): (state: DocumentsState) => DocumentsState {
  return (state) => ({ ...state, page, pageSize, total })
}

function setSearchValue(searchValue: string): (state: DocumentsState) => DocumentsState {
  return (state) => ({ ...state, searchValue })
}

function setLatestOnly(value: boolean): (state: DocumentsState) => DocumentsState {
  return (state) => ({ ...state, latestOnly: value })
}

function setOrder(sortField: string | undefined, sortDirection: SortOrder): (state: DocumentsState) => DocumentsState {
  return (state) => ({ ...state, sortField, sortDirection })
}

function resetSortOrder(): (state: DocumentsState) => DocumentsState {
  return (state) => ({ ...state, sortField: initialState.sortField, sortDirection: initialState.sortDirection })
}
