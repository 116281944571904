import clsx from 'clsx'
import styles from './TabClient.module.scss'

type Props = {
  label: string
  handleOnChangeTab: () => void
  isSelected: boolean
}

function TabClient(props: Readonly<Props>) {
  const { label, handleOnChangeTab, isSelected } = props

  return (
    <button type='button' className={clsx(styles.tab, isSelected && styles.tabActive, 'flex', 'alignItems')} onClick={handleOnChangeTab}>
      <span>{label}</span>
    </button>
  )
}

export default TabClient
