import { Skeleton } from '@bdo/kitchensink'
import translate from 'i18n/translate'
import clsx from 'clsx'
import { Link, useParams } from 'react-router-dom'
import { AnswerQuestionnaireStatus } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import { usePublishedQuestionnaireData } from 'features/clients/clientZustandStore'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { getProjects } from 'services/portalsAndProjects.service'
import { GlobalPortalProject } from 'services/Client/client.models'
import { AxiosError } from 'axios'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import { from } from 'linq-to-typescript'
import getErrorMessage from 'utils/getErrorMessage'
import styles from './PublishedCreateProject.module.scss'

type Props = {
  isPortalCreated: boolean
  appId: number
  isProjectLoading: boolean
  setIsProjectLoading: (value: boolean) => void
}

function ProjectInfo(props: Readonly<Props>) {
  const { isPortalCreated, appId, isProjectLoading, setIsProjectLoading } = props
  const { id } = useParams<string>()
  const clientId = parseInt(id ?? '0', 10)
  const publishedQuestionnaireData = usePublishedQuestionnaireData(appId)
  const publishedQuestionnaireStatus = publishedQuestionnaireData ? publishedQuestionnaireData.status : AnswerQuestionnaireStatus.NotAvailable
  const publishedQuestionnaireName = publishedQuestionnaireData ? publishedQuestionnaireData.questionnaireName : ''
  const intl = useIntl()
  const [lastCreatedProjectName, setLastCreatedProjectName] = useState<string>('')

  useEffect(() => {
    if (!isPortalCreated) {
      return
    }
    getAllProjects()
  }, [isPortalCreated])

  function getAllProjects() {
    getProjects(clientId)
      .then((projects) => {
        const lastProjectName = getLatestProjectName(projects)
        setLastCreatedProjectName(lastProjectName ?? '')
      })
      .catch((error: AxiosError | Error) => {
        getErrorMessage(intl, error, 400, 'api_projects_do_not_exist_')
        LoggerHelper(error)
      })
      .finally(() => {
        setIsProjectLoading(false)
      })
  }

  function getLatestProjectName(projects: GlobalPortalProject[]) {
    const result = from(projects)
      .orderByDescending((x) => x.projectCreated)
      .firstOrDefault()?.projectName

    return result
  }

  return (
    <div className={styles.publishedQuestionnaireTitle}>
      <h4 className='flex alignItems'>{publishedQuestionnaireStatus !== AnswerQuestionnaireStatus.NotAvailable ? publishedQuestionnaireName : translate('application_Project')}</h4>
      {isProjectLoading ? (
        <Skeleton active title={{ width: 300 }} paragraph={false} />
      ) : (
        <div className={clsx(styles.infoLastProject)}>
          <span className={styles.label}>{translate('project_last_created')}</span>
          <span className={styles.projectName}>{lastCreatedProjectName !== '' ? lastCreatedProjectName : '-'}</span>
        </div>
      )}
      <Link to='../portal_and_projects' className={clsx('textButton', styles.projectsLink)}>
        {translate('projects_view_all')}
      </Link>
    </div>
  )
}

export default ProjectInfo
