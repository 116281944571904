// eslint-disable-next-line import/no-cycle

import { SortOrder } from 'antd/lib/table/interface'
import { from } from 'linq-to-typescript'
import { LedgerAccount } from 'services/generalLedger.service'
import { FilterItem } from 'utils/FilterHelper/filter-helper'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

type GeneralLedgerStoreType = GeneralLedgerState | Partial<GeneralLedgerState> | ((state: GeneralLedgerState) => GeneralLedgerState | Partial<GeneralLedgerState>)

type GeneralLedgerState = {
  accounts: LedgerAccount[]
  page: number
  pageSize: number
  total: number
  filters: FilterItem[]
  appliedFilters: FilterItem[]
  sortField: string | undefined
  sortDirection: SortOrder
  isDirty: boolean
  snapshot: string
  hasErrors: boolean
}

type GeneralLedgerActions = {
  checkFilterIsDirty: (newFilter: FilterItem) => void
  setAccounts: (accounts: LedgerAccount[]) => void
  setPagination: (page: number, pageSize: number, total: number) => void
  removeAccount: (id: number) => void
  addFilter: (filter: FilterItem) => void
  removeFilter: (id: string) => void
  updateFilter: (newFilter: FilterItem) => void
  clearFilters: () => void
  setHasErrors: (value: boolean) => void
  setOrder: (sortField: string | undefined, sortDirection: SortOrder) => void
  resetSortOrder: () => void
  applyFilters: () => void
}

const initialState: GeneralLedgerState = {
  accounts: [],
  page: 1,
  pageSize: 10,
  total: 0,
  filters: [],
  appliedFilters: [],
  sortField: 'accountNumber',
  sortDirection: 'ascend',
  isDirty: false,
  snapshot: '',
  hasErrors: false
}

// eslint-disable-next-line import/prefer-default-export
export const useGeneralLedgerStore = create<GeneralLedgerState & GeneralLedgerActions, [['zustand/devtools', GeneralLedgerState & GeneralLedgerActions]]>(
  devtools(
    (set) => ({
      ...initialState,
      checkFilterIsDirty: (newFilter) => set(checkFilterIsDirty(newFilter), false, 'Check if form was filled'),
      setAccounts: (accounts) => set(setAccounts(accounts), false, 'Set accounts'),
      setPagination: (page, pageSize, total) => set(setPagination(page, pageSize, total), false, 'Set pagination'),
      removeAccount: (id) => set(removeAccount(id), false, 'Remove account'),
      addFilter: (filter) => set(addFilter(filter), false, 'Add filter'),
      removeFilter: (id) => set(removeFilter(id), false, 'Remove filter'),
      updateFilter: (newFilter) => set(updateFilter(newFilter), false, 'Update filter'),
      clearFilters: () => set(clearFilters(), false, 'ClearFilters'),
      setHasErrors: (value) => set(setHasErrors(value), false, 'Set as errors'),
      setOrder: (sortField, sortDirection) => set(setOrder(sortField, sortDirection), false, 'Set order'),
      resetSortOrder: () => set(resetSortOrder(), false, 'Reset sort order'),
      applyFilters: () => set(applyFilters(), false, 'Apply filters')
    }),
    { name: 'Onboarding tool - General Ledger store' }
  )
)

export const useFilterById = (id: string) =>
  useGeneralLedgerStore((state) => {
    const singleFilter = state.filters.find((f) => f.id === id)
    if (!singleFilter) throw Error(`No filter with ${id}`)
    return singleFilter
  })

export const useAccounts = () => useGeneralLedgerStore((state) => state.accounts)

function getSnapshot(filter: FilterItem) {
  const data = {
    filteredInfo: filter.field,
    operator: filter.operator,
    value: filter.value
  }
  const snapshot = JSON.stringify(data)
  return snapshot
}

function checkFilterIsDirty(newFilter: FilterItem): GeneralLedgerStoreType {
  return (state) => {
    const isDirty = state.snapshot !== getSnapshot(newFilter)
    return { ...state, isDirty }
  }
}

function setAccounts(accounts: LedgerAccount[]): GeneralLedgerStoreType {
  return (state) => ({ ...state, accounts })
}

function setPagination(page: number, pageSize: number, total: number): GeneralLedgerStoreType {
  return (state) => ({ ...state, page, pageSize, total })
}

function removeAccount(id: number): GeneralLedgerStoreType {
  return (state) => ({ ...state, accounts: state.accounts.filter((account) => account.id !== id) })
}

function addFilter(filter: FilterItem): GeneralLedgerStoreType {
  return (state) => ({ ...state, filters: [...state.filters, filter] })
}

function removeFilter(id: string): GeneralLedgerStoreType {
  return (state) => ({ ...state, filters: state.filters.filter((filter) => filter.id !== id) })
}

function updateFilter(newFilter: FilterItem): GeneralLedgerStoreType {
  return (state) => {
    const newFilters = state.filters.map((filter) => {
      if (filter.id === newFilter.id) return newFilter
      return filter
    })
    return {
      ...state,
      filters: newFilters,
      isDirty: state.snapshot !== getSnapshot(newFilter),
      hasErrors: from(newFilters)
        .where((f) => f.hasErrors)
        .any()
    }
  }
}

function clearFilters(): GeneralLedgerStoreType {
  return (state) => ({ ...state, filters: [], isDirty: false, snapshot: '' })
}

function setHasErrors(value: boolean): GeneralLedgerStoreType {
  return (state) => ({ ...state, hasErrors: value })
}

function setOrder(sortField: string | undefined, sortDirection: SortOrder): GeneralLedgerStoreType {
  return (state) => ({ ...state, sortField, sortDirection })
}

function resetSortOrder(): GeneralLedgerStoreType {
  return (state) => ({ ...state, sortField: initialState.sortField, sortDirection: initialState.sortDirection })
}

function applyFilters(): GeneralLedgerStoreType {
  return (state) => ({ ...state, appliedFilters: state.filters })
}
