import { Switch } from '@bdo/kitchensink'
import translate from 'i18n/translate'
import { useState } from 'react'

type Props = {
  onChange: (value: boolean) => void
}

function ArchivedShowSwitch(props: Readonly<Props>) {
  const { onChange } = props
  const [hideArchived, setHideArchived] = useState<boolean>(false)

  const onSwitchChanged = (checked: boolean) => {
    setHideArchived(checked)
    onChange(checked)
  }

  return (
    <div className='flex alignItems'>
      <Switch checked={hideArchived} onChange={onSwitchChanged} />
      <span className='ml-1'>{translate('questionnaire_show_archived')}</span>
    </div>
  )
}

export default ArchivedShowSwitch
