import { Spinner } from '@bdo/kitchensink'
import { Modal } from 'antd'

type Props = {
  open: boolean
  text: string
}

function Loader(props: Readonly<Props>) {
  const { open, text } = props
  return (
    <Modal
      centered
      closable={false}
      footer={null}
      keyboard={false}
      maskClosable={false}
      open={open}
      width={180}
      className='loaderRef'
      bodyStyle={{ padding: '32px', textAlign: 'center' }}
    >
      <Spinner tip={text} style={{ lineHeight: '19px' }} />
    </Modal>
  )
}

export default Loader
