import translate from 'i18n/translate'
import clsx from 'clsx'
import { AnswerQuestionnaireStatus } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import moment from 'moment'
import { AnswersInfo } from 'services/Client/client.models'
import styles from './ModificationInfo.module.scss'

type Props = {
  modificationInfo: AnswersInfo
}

function ModificationInfo(props: Readonly<Props>) {
  const { modificationInfo } = props

  return (
    <>
      {modificationInfo && (modificationInfo.status === AnswerQuestionnaireStatus.InProgress || modificationInfo.status === AnswerQuestionnaireStatus.Ready) && (
        <div className={clsx(styles.infoWrapper, 'flex', 'alignItems')}>
          <span className={styles.info}>{translate('general_edited_by')}</span>
          {modificationInfo.lastModification && (
            <div className={styles.editInfo}>
              <span>{`${modificationInfo.lastModification.user.firstName} ${modificationInfo.lastModification.user.lastName}`}</span>
              <span>{moment.utc(modificationInfo.lastModification.date).local().format('DD-MM-YYYY HH:mm')}</span>
            </div>
          )}
        </div>
      )}

      {modificationInfo && modificationInfo.status === AnswerQuestionnaireStatus.Completed && (
        <div>
          <div className={clsx(styles.infoWrapper, 'flex', 'alignItems')}>
            <span className={styles.info}>{translate('general_completed_by')}</span>
            {modificationInfo.lastExport && (
              <div className={styles.editInfo}>
                <span>{`${modificationInfo.lastExport.user.firstName} ${modificationInfo.lastExport.user.lastName}`}</span>
                <span>{moment.utc(modificationInfo.lastExport.date).local().format('DD-MM-YYYY HH:mm')}</span>
              </div>
            )}
          </div>{' '}
        </div>
      )}
    </>
  )
}

export default ModificationInfo
