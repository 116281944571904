import { Button, Icon, Spinner } from '@bdo/kitchensink'
import { Tooltip } from 'antd'
import clsx from 'clsx'
import styles from './IconButton.module.scss'

type Props = {
  testId?: string
  onClick: () => void
  disabled?: boolean
  type: string
  customStyles?: string
  toolTipTitle?: string
  loading?: boolean
  customIconStyles?: string
}

function IconButton(props: Readonly<Props>) {
  const { testId, disabled, onClick, type, customStyles, toolTipTitle, loading, customIconStyles } = props

  return (
    <Tooltip title={toolTipTitle} mouseEnterDelay={0.5}>
      <Button shape='circle' onClick={onClick} data-testid={testId} disabled={disabled} className={customStyles}>
        {loading ? <Spinner style={{ lineHeight: '19px', color: '#333' }} /> : <Icon type={type} className={clsx(styles.buttonIcon, customIconStyles)} />}
      </Button>
    </Tooltip>
  )
}

export default IconButton
