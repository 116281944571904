/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { QuestionnaireViewModel } from 'pages/_models/QuestionnaireViewModel'
import create from 'zustand'
import update from 'immutability-helper'
import { v4 as uuidv4 } from 'uuid'
import { from } from 'linq-to-typescript'
import { devtools } from 'zustand/middleware'
import { QuestionnaireStatuses, SharedQuestionsOptions } from 'services/Questionnaire/questionnaire.models'
import { GroupContentViewModel } from './components/_models/GroupContentViewModel'
import { ColumnViewModel } from './components/_models/ColumnViewModel'
import { QuestionViewModel } from './components/_models/QuestionViewModel'
import { isValidate, Validate } from './components/_models/Validate'
import { OptionViewModel } from './components/_models/OptionViewModel'

type QuestionnaireState = {
  questionnaireVM: QuestionnaireViewModel
  selectedGroupKey: string | undefined
  isDirty: boolean
  snapshot: string
  isReadOnly: boolean
  formFieldIsReadOnly: boolean
  isPublishedQuestionnaire: boolean
  isCheckingMultiplicationNumber: boolean
}

type QuestionnaireActions = {
  setQuestionnaireVM: (questionnaire: QuestionnaireViewModel) => void
  setQuestionnaireName: (newName: string) => void
  updateStatus: (status: QuestionnaireStatuses) => void
  addGroup: (group: GroupContentViewModel) => void
  selectGroup: (groupKey: string) => void
  updateGroup: (updatedGroup: GroupContentViewModel) => void
  updateSelectedGroupName: (groupName: string) => void
  swapGroups: (dragIndex: number, hoverIndex: number) => void
  switchGroupTo2Columns: () => void
  switchGroupTo1Column: () => void
  removeGroup: (groupKey: string | undefined) => void
  updateColumn: (groupKey: string, updatedColumn: ColumnViewModel) => void
  moveQuestionToColumn: (targetColumn: string, groupKey: string, itemId: string) => void
  addQuestion: (groupKey: string, columnKey: string, newQuestion: QuestionViewModel) => void
  updateQuestion: (newQuestion: QuestionViewModel, source?: string) => void
  removeQuestion: (questionKey: string | undefined) => void
  swapQuestions: (dragQuestionKey: string, dragIndex: number, hoverIndex: number) => void
  swapSharedQuestions: (dragQuestionKey: string, dragIndex: number, hoverIndex: number) => void
  updateOption: (newOption: OptionViewModel) => void
  removeOption: (optionKey: string | undefined) => void
  swapOptions: (draggedOptionKey: string, dragIndex: number, hoverIndex: number) => void
  updateSharedQuestionsOptions: (questionId: string, sharedQuestionId: string | undefined, optionsId: string[]) => void
  updateFormFieldIsReadOnly: (value: boolean) => void
  updateCheckingMultiplicationNumber: (value: boolean) => void
  reset: () => void
}

type QuestionnaireStateActionResult = QuestionnaireState | Partial<QuestionnaireState> | ((state: QuestionnaireState) => QuestionnaireState | Partial<QuestionnaireState>)

const initialState: QuestionnaireState = {
  questionnaireVM: {
    groups: [],
    id: '',
    modifiedBy: '',
    modifiedDate: new Date(),
    name: '',
    status: '',
    hasErrors: false,
    hasValidationErrors: false,
    service: '',
    application: ''
  },
  selectedGroupKey: undefined,
  isDirty: false,
  isReadOnly: false,
  formFieldIsReadOnly: false,
  isPublishedQuestionnaire: false,
  isCheckingMultiplicationNumber: false,
  snapshot: ''
}

export const useQuestionnaireStore = create<QuestionnaireState & QuestionnaireActions, [['zustand/devtools', QuestionnaireState & QuestionnaireActions]]>(
  devtools(
    (set) => ({
      ...initialState,
      setQuestionnaireVM: (questionnaire: QuestionnaireViewModel) => set(setQuestionnaireVM(questionnaire), false, 'Set questionnaire'),
      setQuestionnaireName: (newName) => set(setQuestionnaireName(newName), false, 'Set questionnaire name'),
      updateStatus: (status) => {
        set(updateStatus(status), false, 'Update status')
      },
      addGroup: (group) => set(addGroup(group), false, 'Add group'),
      selectGroup: (groupKey) => set(selectGroup(groupKey), false, 'Select group'),
      updateGroup: (updatedGroup) => set(updateGroup(updatedGroup), false, 'Update group'),
      updateSelectedGroupName: (groupName) => set(updateSelectedGroupName(groupName), false, 'Update selected group name'),
      swapGroups: (dragIndex, hoverIndex) => {
        set(swapGroups(dragIndex, hoverIndex), false, 'Swap groups')
      },
      switchGroupTo2Columns: () => {
        set(switchGroupTo2Columns(), false, 'Switch group layout to 2 columns')
      },
      switchGroupTo1Column: () => {
        set(switchGroupTo1Column(), false, 'Switch group layout to single column')
      },
      removeGroup: (groupKey) => {
        set(removeGroup(groupKey), false, 'Remove group')
      },
      updateColumn: (groupKey, updatedColumn) => {
        set(updateColumn(groupKey, updatedColumn), false, 'Update column')
      },
      moveQuestionToColumn: (targetColumn, groupKey, itemId) => {
        set(moveQuestionToColumn(targetColumn, groupKey, itemId), false, 'Swap questions')
      },
      addQuestion: (groupKey, columnKey, newQuestion) => {
        set(addQuestion(groupKey, columnKey, newQuestion), false, 'Add question')
      },
      updateQuestion: (newQuestion) => {
        set(updateQuestion(newQuestion), false, `Update question`)
      },
      removeQuestion: (questionKey) => set(removeQuestion(questionKey), false, 'Remove question'),
      swapQuestions: (dragQuestionKey, dragIndex, hoverIndex) => set(swapQuestions(dragQuestionKey, dragIndex, hoverIndex), false, 'Swap questions'),
      swapSharedQuestions: (sharedQuestionKey, dragIndex, hoverIndex) => set(swapSharedQuestions(sharedQuestionKey, dragIndex, hoverIndex), false, 'Swap shared questions'),
      updateOption: (newOption) => {
        set(updateOption(newOption), false, 'Update option req')
      },
      removeOption: (optionKey) => set(removeOption(optionKey), false, 'Remove option'),
      swapOptions: (draggedOptionKey, dragIndex, hoverIndex) => set(swapOptions(draggedOptionKey, dragIndex, hoverIndex), false, 'Swap options'),

      updateSharedQuestionsOptions: (questionKey, sharedQuestionId, optionsId) =>
        set(updateSharedQuestionsOptions(questionKey, sharedQuestionId, optionsId), false, 'Update Shared Questions Options array'),
      updateFormFieldIsReadOnly: (value) => set(updateFormFieldIsReadOnly(value), false, 'Update ReadOnly'),
      updateCheckingMultiplicationNumber: (value) => set(updateCheckingMultiplicationNumber(value), false, 'Update checking multiplication number'),
      reset: () => {
        set((state) => ({ ...state, ...initialState }), false, 'Reset state to initial values')
      }
    }),
    { name: 'Onboarding tool - Create questionnaire store' }
  )
)

export const useQuestionnaireVM = () => useQuestionnaireStore((state) => state.questionnaireVM)
export const useQuestionnaireName = () => useQuestionnaireStore((state) => state.questionnaireVM.name)
export const useQuestionnaireApplicationName = () => useQuestionnaireStore((state) => state.questionnaireVM.application)
export const useQuestionnaireHasErrors = () => useQuestionnaireStore((state) => state.questionnaireVM.hasErrors)
export const useQuestionnaireId = () => useQuestionnaireStore((state) => state.questionnaireVM.id)
export const useQuestionnaireGroups = () => useQuestionnaireStore((state) => state.questionnaireVM.groups)
export const useSelectedGroupKey = () => useQuestionnaireStore((state) => state.selectedGroupKey)
export const useIsDirty = () => useQuestionnaireStore((state) => state.isDirty)
export const useIsReadOnly = () => useQuestionnaireStore((state) => state.isReadOnly)
export const useStatus = () => useQuestionnaireStore((state) => state.questionnaireVM.status)
export const useFormFieldIsReadOnly = () => useQuestionnaireStore((state) => state.formFieldIsReadOnly)
export const useSelectedGroup = () =>
  useQuestionnaireStore((state) => {
    const group = state.questionnaireVM.groups.find((x) => x.key === state.selectedGroupKey)
    if (!group) {
      return { ...initialState.questionnaireVM.groups[0] }
    }

    return group
  })
export const useSelectedGroupColumnKey = () =>
  useQuestionnaireStore((state) => {
    const group = state.questionnaireVM.groups.find((x) => x.key === state.selectedGroupKey)
    if (!group) {
      return ''
    }

    return group.columns[0].key
  })

export const useSelectedGroupName = () =>
  useQuestionnaireStore((state) => {
    const group = state.questionnaireVM.groups.find((x) => x.key === state.selectedGroupKey)
    if (!group) {
      const g = { ...initialState.questionnaireVM.groups[0] }
      return g.name
    }

    return group.name
  })

export const useSelectedGroupNumberOfColumn = () =>
  useQuestionnaireStore((state) => {
    const group = state.questionnaireVM.groups.find((x) => x.key === state.selectedGroupKey)
    if (!group) {
      const g = { ...initialState.questionnaireVM.groups[0] }
      return g.numberOfColumns
    }

    return group.numberOfColumns
  })

export const useColumn = (columnKey: string) =>
  useQuestionnaireStore((state) => {
    const column = state.questionnaireVM.groups.flatMap((x) => x.columns).find((x) => x.key === columnKey)
    if (!column) {
      throw new Error(`Cannot find column with key: ${columnKey}`)
    }
    return column
  })

export const useGroup = (groupKey: string) =>
  useQuestionnaireStore((state) => {
    const group = state.questionnaireVM.groups.find((g) => g.key === groupKey)
    if (!group) {
      throw new Error(`Cannot find group with key: ${groupKey}`)
    }

    return group
  })

export const useGroupHasErrors = (groupKey: string) =>
  useQuestionnaireStore((state) => {
    const group = state.questionnaireVM.groups.find((g) => g.key === groupKey)
    if (!group) {
      throw new Error(`Cannot find group with key: ${groupKey}`)
    }

    return group?.hasErrors
  })

export const useParentQuestion = (childQuestionKey: string): QuestionViewModel | undefined =>
  useQuestionnaireStore((state) => {
    let parentQuestion: QuestionViewModel | undefined
    state.questionnaireVM.groups.forEach((g: GroupContentViewModel) => {
      g.columns.forEach((c: ColumnViewModel) => {
        c.questions.forEach((q: QuestionViewModel) => {
          const question = getParentQuestionRecursively(q, childQuestionKey)
          if (question !== undefined) {
            parentQuestion = question
          }
        })
      })
    })

    return parentQuestion
  })

function getParentQuestionRecursively(question: QuestionViewModel, childQuestionKey: string): QuestionViewModel | undefined {
  if (question.sharedQuestions.find((x) => x.key === childQuestionKey)) {
    return question
  }

  if (question.questionsTemplate.find((x) => x.key === childQuestionKey)) {
    return question
  }

  let parentQuestion: QuestionViewModel | undefined
  question.sharedQuestions.forEach((q: QuestionViewModel) => {
    const questionParent = getParentQuestionRecursively(q, childQuestionKey)
    if (questionParent !== undefined) {
      parentQuestion = questionParent
    }
  })

  question.questionsTemplate.forEach((t: QuestionViewModel) => {
    const questionParent = getParentQuestionRecursively(t, childQuestionKey)
    if (questionParent !== undefined) {
      parentQuestion = questionParent
    }
  })

  return parentQuestion
}

export const useQuestion = (questionKey: string) =>
  useQuestionnaireStore((state) => {
    const question = findQuestionRecursively(state, questionKey)
    if (!question) {
      throw new Error(`Cannot find question with key: ${questionKey}`)
    }

    return question
  })

export const useOptions = (questionKey: string) =>
  useQuestionnaireStore((state) => {
    const question = findQuestionRecursively(state, questionKey)
    if (!question) {
      throw new Error(`Cannot find question with key: ${questionKey}`)
    }

    return question.options
  })

function findQuestionRecursively(state: QuestionnaireState, questionKey: string): QuestionViewModel | null {
  const question = from(state.questionnaireVM.groups)
    .selectMany((x) => x.columns)
    .selectMany((x) => x.questions)
    .selectMany((x) => getQuestionsRecursively(x))
    .firstOrDefault((x) => x.key === questionKey)
  return question
}

function getQuestionsRecursively(question: QuestionViewModel) {
  // don't return generated questions (questions that are generated from template) for validation
  const templateQuestions = from(question?.questionsTemplate || []).toArray()
  const sharedQuestions = from(question?.sharedQuestions || []).toArray()

  const result: QuestionViewModel[] = [question]

  templateQuestions.forEach((q) => {
    if (q !== undefined) result.push(...getQuestionsRecursively(q))
  })

  sharedQuestions.forEach((q) => {
    if (q !== undefined) result.push(...getQuestionsRecursively(q))
  })

  return result
}

export const useOption = (optionKey: string): OptionViewModel | null =>
  useQuestionnaireStore((state) => {
    const option = from(state.questionnaireVM.groups)
      .selectMany((x) => x.columns)
      .selectMany((x) => x.questions)
      .selectMany((x) => getQuestionsRecursively(x))
      .selectMany((x) => x.options)
      .firstOrDefault((x) => x.key === optionKey)

    if (!option) {
      throw new Error(`Cannot find option with key: ${optionKey}`)
    }

    return option
  })

function setQuestionnaireName(newName: string): QuestionnaireStateActionResult {
  return (state) => {
    const newQuestionnaireVM = { ...state.questionnaireVM, name: newName }

    const isDirty = state.snapshot !== getQuestionnaireSnapshot(newQuestionnaireVM)
    return { ...state, questionnaireVM: newQuestionnaireVM, isDirty }
  }
}

function setQuestionnaireVM(questionnaire: QuestionnaireViewModel): QuestionnaireStateActionResult {
  return (state) => {
    const oldSelectedGroupIdx = state.questionnaireVM.groups.findIndex((x) => x.key === state.selectedGroupKey)
    const newState: QuestionnaireState = {
      questionnaireVM: { ...questionnaire },
      snapshot: getQuestionnaireSnapshot(questionnaire),
      isDirty: false,
      isCheckingMultiplicationNumber: false,
      selectedGroupKey: '',
      isReadOnly: questionnaire.status === QuestionnaireStatuses.Archived,
      isPublishedQuestionnaire: questionnaire.status === QuestionnaireStatuses.Published,
      formFieldIsReadOnly: false
    }

    if (oldSelectedGroupIdx !== -1 && questionnaire?.groups[oldSelectedGroupIdx]) {
      newState.selectedGroupKey = questionnaire.groups[oldSelectedGroupIdx].key
    }
    return newState
  }
}

function updateStatus(status: QuestionnaireStatuses): QuestionnaireStateActionResult {
  return (state) => {
    const newQuestionnaireVM = { ...state.questionnaireVM, status }
    const isReadOnly = status === QuestionnaireStatuses.Archived
    const isPublishedQuestionnaire = status === QuestionnaireStatuses.Published
    return { ...state, questionnaireVM: newQuestionnaireVM, isReadOnly, isPublishedQuestionnaire }
  }
}

function addGroup(group: GroupContentViewModel): QuestionnaireStateActionResult {
  return (state) => {
    const newQuestionnaireVM = {
      ...state.questionnaireVM,
      groups: [...state.questionnaireVM.groups, group]
    }
    const isDirty = state.snapshot !== getQuestionnaireSnapshot(newQuestionnaireVM)
    return { ...state, questionnaireVM: newQuestionnaireVM, isDirty, selectedGroupKey: group.key }
  }
}

function selectGroup(groupKey: string): QuestionnaireStateActionResult {
  return (state) => ({
    ...state,
    selectedGroupKey: groupKey
  })
}

function updateGroup(updatedGroup: GroupContentViewModel): QuestionnaireStateActionResult {
  return (state) => {
    const newQuestionnaireVM = {
      ...state.questionnaireVM,
      groups: state.questionnaireVM.groups.map((g: GroupContentViewModel) => {
        if (g.key === updatedGroup.key) {
          return updatedGroup
        }
        return g
      })
    }
    const isDirty = state.snapshot !== getQuestionnaireSnapshot(newQuestionnaireVM)
    return { ...state, questionnaireVM: newQuestionnaireVM, isDirty, selectedGroupKey: updatedGroup.key }
  }
}

function updateSelectedGroupName(groupName: string): QuestionnaireStateActionResult {
  return (state) => {
    const newQuestionnaireVM = {
      ...state.questionnaireVM,
      groups: state.questionnaireVM.groups.map((g: GroupContentViewModel) => {
        if (g.key === state.selectedGroupKey) {
          return { ...g, name: groupName }
        }
        return g
      })
    }
    const isDirty = state.snapshot !== getQuestionnaireSnapshot(newQuestionnaireVM)
    return { ...state, questionnaireVM: newQuestionnaireVM, isDirty, selectedGroupKey: state.selectedGroupKey }
  }
}

function removeGroup(groupKey: string | undefined): QuestionnaireStateActionResult {
  return (state) => {
    let newQuestionnaireVM = {
      ...state.questionnaireVM,
      groups: state.questionnaireVM.groups.filter((group: GroupContentViewModel) => group.key !== groupKey)
    }

    let newSelectedGroupKey = state.selectedGroupKey
    if (state.selectedGroupKey === groupKey) {
      newSelectedGroupKey = newQuestionnaireVM.groups[0]?.key || undefined
    }

    const isDirty = state.snapshot !== getQuestionnaireSnapshot(newQuestionnaireVM)
    newQuestionnaireVM = markErrors(newQuestionnaireVM)
    return { ...state, questionnaireVM: newQuestionnaireVM, isDirty, selectedGroupKey: newSelectedGroupKey }
  }
}

function switchGroupTo1Column(): QuestionnaireStateActionResult {
  return (state) => {
    const newQuestionnaireVM = {
      ...state.questionnaireVM,
      groups: state.questionnaireVM.groups.map((g: GroupContentViewModel) => {
        if (g.key === state.selectedGroupKey) {
          const leftColumn: ColumnViewModel = {
            ...g.columns[0],
            name: '',
            key: uuidv4(),
            questions: getAllQuestionsFromColumns(g.columns),
            isDeleted: false
          }

          const others = g.columns
            .map((c: ColumnViewModel) => {
              const removedColumn = { ...c, isDeleted: true, questions: [] }
              return removedColumn
            })
            .slice(1)
            .filter(savedOrNew)

          return { ...g, columns: [leftColumn, ...others], numberOfColumns: 1 }
        }
        return g
      })
    }

    const isDirty = state.snapshot !== getQuestionnaireSnapshot(newQuestionnaireVM)
    return { ...state, questionnaireVM: newQuestionnaireVM, isDirty }
  }
}

function getAllQuestionsFromColumns(columns: ColumnViewModel[]): QuestionViewModel[] {
  return columns.flatMap((x) => x.questions)
}

function savedOrNew(x: ColumnViewModel) {
  return x.id !== undefined || (x.id === undefined && x.isDeleted === false)
}

function switchGroupTo2Columns(): QuestionnaireStateActionResult {
  return (state) => {
    const newQuestionnaireVM = {
      ...state.questionnaireVM,
      groups: state.questionnaireVM.groups.map((g: GroupContentViewModel) => {
        if (g.key === state.selectedGroupKey) {
          const leftColumn = { ...g.columns[0], name: 'Left Column', key: uuidv4() }
          const rightColumn: ColumnViewModel = g.columns[1] || {
            name: 'Right Column',
            key: uuidv4(),
            hasErrors: false,
            hasValidationErrors: false
          }
          rightColumn.isDeleted = false
          rightColumn.questions = []

          return { ...g, columns: [leftColumn, rightColumn], numberOfColumns: 2 }
        }
        return g
      })
    }

    const isDirty = state.snapshot !== getQuestionnaireSnapshot(newQuestionnaireVM)
    return { ...state, questionnaireVM: newQuestionnaireVM, isDirty }
  }
}

function swapGroups(dragIndex: number, hoverIndex: number): QuestionnaireStateActionResult {
  return (state) => {
    const newQuestionnaireVM = {
      ...state.questionnaireVM,
      groups: update(state.questionnaireVM.groups, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, state.questionnaireVM.groups[dragIndex]]
        ]
      })
    }

    const isDirty = state.snapshot !== getQuestionnaireSnapshot(newQuestionnaireVM)

    return { ...state, questionnaireVM: newQuestionnaireVM, isDirty }
  }
}

function updateColumn(groupKey: string, updatedColumn: ColumnViewModel): QuestionnaireStateActionResult {
  return (state) => {
    const newQuestionnaireVM = {
      ...state.questionnaireVM,
      groups: state.questionnaireVM.groups.map((g: GroupContentViewModel) => {
        if (g.key === groupKey) {
          return {
            ...g,
            columns: g.columns.map((c: ColumnViewModel) => {
              if (c.key === updatedColumn.key) {
                return updatedColumn
              }
              return c
            })
          }
        }
        return g
      })
    }
    const isDirty = state.snapshot !== getQuestionnaireSnapshot(newQuestionnaireVM)
    return { ...state, questionnaireVM: newQuestionnaireVM, isDirty }
  }
}

function moveQuestionToColumn(targetColumn: string, groupKey: string, dragItemId: string): QuestionnaireStateActionResult {
  return (state) => {
    let newQuestionnaireVM = {
      ...state.questionnaireVM,
      groups: state.questionnaireVM.groups.map((g: GroupContentViewModel) => {
        if (g.key === groupKey) {
          const dragItem = g.columns.flatMap((c: ColumnViewModel) => c.questions.filter((q: QuestionViewModel) => q.key === dragItemId))[0]
          return {
            ...g,
            columns: g.columns.map((c: ColumnViewModel) => {
              if (c.key !== targetColumn) {
                const newArray = c.questions.filter((q: QuestionViewModel) => q.key !== dragItem.key)
                return {
                  ...c,
                  questions: newArray
                }
              }
              if (dragItem !== undefined && c.questions.filter((q: QuestionViewModel) => q !== undefined && q.key === dragItem.key).length === 0) c.questions.push(dragItem)
              return c
            })
          }
        }
        return g
      })
    }
    const isDirty = state.snapshot !== getQuestionnaireSnapshot(newQuestionnaireVM)
    newQuestionnaireVM = markErrors(newQuestionnaireVM)
    return { ...state, questionnaireVM: newQuestionnaireVM, isDirty }
  }
}

function addQuestion(groupKey: string, columnKey: string, newQuestion: QuestionViewModel): QuestionnaireStateActionResult {
  const validatedQuestion: QuestionViewModel = { ...newQuestion, hasValidationErrors: !isValidQuestion(newQuestion) }

  return (state) => {
    let newQuestionnaireVM = {
      ...state.questionnaireVM,
      groups: state.questionnaireVM.groups.map((g: GroupContentViewModel) => {
        if (g.key === groupKey) {
          return {
            ...g,
            columns: g.columns.map((c: ColumnViewModel) => {
              if (c.key === columnKey) {
                return { ...c, questions: [...c.questions, validatedQuestion] }
              }
              return c
            })
          }
        }
        return g
      })
    }
    const isDirty = state.snapshot !== getQuestionnaireSnapshot(newQuestionnaireVM)
    newQuestionnaireVM = markErrors(newQuestionnaireVM)
    return { ...state, questionnaireVM: newQuestionnaireVM, isDirty }
  }
}

function updateQuestion(newQuestion: QuestionViewModel): QuestionnaireStateActionResult {
  const validatedQuestion = { ...newQuestion, hasValidationErrors: !isValidQuestion(newQuestion) }

  return (state) => {
    const groups: GroupContentViewModel[] = state.questionnaireVM.groups?.map((g: GroupContentViewModel) => ({
      ...g,
      columns: g.columns?.map((c: ColumnViewModel) => ({
        ...c,
        questions: c.questions?.map((q: QuestionViewModel) => updateFoundQuestion(q, validatedQuestion))
      }))
    }))

    let newQuestionnaireVM = { ...state.questionnaireVM, groups }

    const isDirty = state.snapshot !== getQuestionnaireSnapshot(newQuestionnaireVM)
    newQuestionnaireVM = markErrors(newQuestionnaireVM)
    return { ...state, questionnaireVM: newQuestionnaireVM, isDirty }
  }
}

function isValidQuestion(newQuestion: QuestionViewModel): boolean {
  const validText = newQuestion.text !== ''
  const validMaxCharNumber = newQuestion.type !== 'Text' || (newQuestion.maxNumberOfCharacters !== undefined && newQuestion.maxNumberOfCharacters > 0)
  const validTemplateNumber = newQuestion.type !== 'Multiplied' || newQuestion.questionsTemplate.length > 0
  const validLinkedQuestionConnection = newQuestion.sharedQuestionsOptions.length >= newQuestion.sharedQuestions.length

  return validText && validMaxCharNumber && validTemplateNumber && validLinkedQuestionConnection
}

function updateFoundQuestion(sourceQuestion: QuestionViewModel, newQuestion: QuestionViewModel): QuestionViewModel {
  if (sourceQuestion.key === newQuestion.key) {
    return newQuestion
  }

  return {
    ...sourceQuestion,
    questionsTemplate: sourceQuestion.questionsTemplate?.map((tq: QuestionViewModel) => {
      if (tq.key === newQuestion.key) {
        return newQuestion
      }
      return updateFoundQuestion(tq, newQuestion)
    }),
    sharedQuestions: sourceQuestion.sharedQuestions?.map((q: QuestionViewModel) => {
      if (q.key === newQuestion.key) {
        return newQuestion
      }
      q.questionsTemplate.forEach((qt: QuestionViewModel) => {
        if (qt.key === newQuestion.key) {
          return newQuestion
        }
        return updateFoundQuestion(q, newQuestion)
      })
      return updateFoundQuestion(q, newQuestion)
    })
  }
}

function swapQuestions(dragQuestionKey: string, dragIndex: number, hoverIndex: number): QuestionnaireStateActionResult {
  return (state) => {
    const groups = state.questionnaireVM.groups.map((g: GroupContentViewModel) => {
      if (g.key === state.selectedGroupKey) {
        return {
          ...g,
          columns: g.columns.map((c: ColumnViewModel) => {
            if (from(c.questions).any((x) => x.key === dragQuestionKey)) {
              const newQuestionsArray = update(c.questions, {
                $splice: [
                  [dragIndex, 1],
                  [hoverIndex, 0, c.questions[dragIndex]]
                ]
              })
              return { ...c, questions: newQuestionsArray }
            }

            return {
              ...c,
              questions: c.questions.map((q: QuestionViewModel) => ({
                ...getAllGeneratorsSwapped(q, dragQuestionKey, dragIndex, hoverIndex),
                sharedQuestions: q.sharedQuestions.map((sq1: QuestionViewModel) => ({
                  ...getAllGeneratorsSwapped(sq1, dragQuestionKey, dragIndex, hoverIndex),
                  sharedQuestions: sq1.sharedQuestions.map((sq2: QuestionViewModel) => ({
                    ...getAllGeneratorsSwapped(sq2, dragQuestionKey, dragIndex, hoverIndex)
                  }))
                }))
              }))
            }
          })
        }
      }

      return g
    })

    const newQuestionnaireVM = { ...state.questionnaireVM, groups }
    const isDirty = state.snapshot !== getQuestionnaireSnapshot(newQuestionnaireVM)

    return { ...state, questionnaireVM: newQuestionnaireVM, isDirty }
  }
}

function getAllGeneratorsSwapped(question: QuestionViewModel, dragQuestionKey: string, dragIndex: number, hoverIndex: number): QuestionViewModel {
  return {
    ...question,
    questionsTemplate: swapQuestionTemplates(question, dragQuestionKey, dragIndex, hoverIndex).map((qt: QuestionViewModel) => ({
      ...qt,
      questionsTemplate: swapQuestionTemplates(qt, dragQuestionKey, dragIndex, hoverIndex)
    }))
  }
}

function swapQuestionTemplates(question: QuestionViewModel, dragQuestionKey: string, dragIndex: number, hoverIndex: number): QuestionViewModel[] {
  const isDraggedQuestionInTemplates = question.questionsTemplate.find((x) => x.key === dragQuestionKey)

  if (isDraggedQuestionInTemplates) {
    const newQuestionsArray = update(question.questionsTemplate, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, question.questionsTemplate[dragIndex]]
      ]
    })
    return newQuestionsArray
  }
  return question.questionsTemplate
}

function removeQuestion(questionKeyToRemove: string | undefined): QuestionnaireStateActionResult {
  return (state) => {
    const groups = state.questionnaireVM.groups.map((g: GroupContentViewModel) => ({
      ...g,
      columns: g.columns.map((c: ColumnViewModel) => ({
        ...c,
        questions: c.questions.filter((q: QuestionViewModel) => q.key !== questionKeyToRemove).map((q: QuestionViewModel) => removeQuestionRecursively(q, questionKeyToRemove))
      }))
    }))

    let newQuestionnaireVM = { ...state.questionnaireVM, groups }
    const isDirty = state.snapshot !== getQuestionnaireSnapshot(newQuestionnaireVM)
    newQuestionnaireVM = markErrors(newQuestionnaireVM)

    return { ...state, questionnaireVM: newQuestionnaireVM, isDirty }
  }
}

function removeQuestionRecursively(question: QuestionViewModel, questionKeyToRemove: string | undefined): QuestionViewModel {
  const sharedQuestionToRemove = question.sharedQuestions?.find((sq: QuestionViewModel) => sq.key === questionKeyToRemove)
  if (sharedQuestionToRemove) {
    const sharedQuestionsOptionsUpdated = question.sharedQuestionsOptions.filter((sqo: SharedQuestionsOptions) => sqo.sharedQuestionId !== questionKeyToRemove)
    const updatedQuestion = {
      ...question,
      sharedQuestions: question.sharedQuestions.filter((sq: QuestionViewModel) => sq.key !== questionKeyToRemove),
      sharedQuestionsOptions: sharedQuestionsOptionsUpdated
    }
    return { ...updatedQuestion, hasValidationErrors: !isValidQuestion(updatedQuestion) }
  }

  const templateQuestionToRemove = question.questionsTemplate?.find((qt: QuestionViewModel) => qt.key === questionKeyToRemove)
  if (templateQuestionToRemove) {
    const updatedTemplates = question.questionsTemplate.filter((qt: QuestionViewModel) => qt.key !== questionKeyToRemove)
    return {
      ...question,
      questionsTemplate: updatedTemplates,
      hasValidationErrors: updatedTemplates.length < 1
    }
  }

  return {
    ...question,
    sharedQuestions: question.sharedQuestions.map((sq: QuestionViewModel) => removeQuestionRecursively(sq, questionKeyToRemove)),
    questionsTemplate: question.questionsTemplate.map((qt: QuestionViewModel) => removeQuestionRecursively(qt, questionKeyToRemove))
  }
}

function updateOption(newOption: OptionViewModel): QuestionnaireStateActionResult {
  const validatedOption: OptionViewModel = { ...newOption, hasValidationErrors: !isValidOption(newOption) }
  return (state) => {
    const groups: GroupContentViewModel[] = state.questionnaireVM.groups?.map((g: GroupContentViewModel) => ({
      ...g,
      columns: g.columns?.map((c: ColumnViewModel) => ({
        ...c,
        questions: c.questions?.map((q: QuestionViewModel) => getQuestions(q, validatedOption))
      }))
    }))

    let newQuestionnaireVM = { ...state.questionnaireVM, groups }
    const isDirty = state.snapshot !== getQuestionnaireSnapshot(newQuestionnaireVM)
    newQuestionnaireVM = markErrors(newQuestionnaireVM)

    return { ...state, questionnaireVM: newQuestionnaireVM, isDirty }
  }
}

function isValidOption(newOption: OptionViewModel): boolean {
  const validText = newOption.text !== ''

  return validText
}

function getQuestions(question: QuestionViewModel, newOption: OptionViewModel): QuestionViewModel {
  question.questionsTemplate.forEach((qt: QuestionViewModel) => ({ ...qt, options: qt.options.forEach((o: OptionViewModel) => updateFoundOption(o, newOption)) }))
  question.sharedQuestions.forEach((sq: QuestionViewModel) => ({ ...sq, options: sq.options.forEach((o: OptionViewModel) => updateFoundOption(o, newOption)) }))

  return {
    ...question,
    options: question.options.map((o: OptionViewModel) => updateFoundOption(o, newOption)),
    questionsTemplate: question.questionsTemplate.map((qt: QuestionViewModel) => getQuestions(qt, newOption)),
    sharedQuestions: question.sharedQuestions.map((sq: QuestionViewModel) => getQuestions(sq, newOption))
  }
}

function updateFoundOption(sourceOption: OptionViewModel, newOption: OptionViewModel): OptionViewModel {
  if (sourceOption.key === newOption.key) {
    return newOption
  }
  return sourceOption
}

function removeOption(optionKey: string | undefined): QuestionnaireStateActionResult {
  return (state) => {
    const groups = state.questionnaireVM.groups?.map((g: GroupContentViewModel) => ({
      ...g,
      columns: g.columns?.map((c: ColumnViewModel) => ({
        ...c,
        questions: c.questions?.map((q: QuestionViewModel) => removeOptionRecursively(q, optionKey))
      }))
    }))

    let newQuestionnaireVM = { ...state.questionnaireVM, groups }
    const isDirty = state.snapshot !== getQuestionnaireSnapshot(newQuestionnaireVM)
    newQuestionnaireVM = markErrors(newQuestionnaireVM)

    return { ...state, questionnaireVM: newQuestionnaireVM, isDirty }
  }
}

function removeOptionRecursively(question: QuestionViewModel, optionKeyToRemove: string | undefined): QuestionViewModel {
  const optionToRemove = question.options.find((x: OptionViewModel) => x.key === optionKeyToRemove)

  if (optionToRemove) {
    return {
      ...question,
      options: question.options.filter((x: OptionViewModel) => x.key !== optionKeyToRemove),
      sharedQuestionsOptions: question.sharedQuestionsOptions.filter((x) => x.optionId !== optionKeyToRemove)
    }
  }

  return {
    ...question,
    questionsTemplate: question.questionsTemplate.map((qt: QuestionViewModel) => removeOptionRecursively(qt, optionKeyToRemove)),
    sharedQuestions: question.sharedQuestions.map((sq) => removeOptionRecursively(sq, optionKeyToRemove))
  }
}

function swapOptions(draggedOptionKey: string, dragIndex: number, hoverIndex: number): QuestionnaireStateActionResult {
  return (state) => {
    const groups = state.questionnaireVM.groups.map((g: GroupContentViewModel) => ({
      ...g,
      columns: g.columns.map((c: ColumnViewModel) => ({
        ...c,
        questions: c.questions.map((q: QuestionViewModel) => swapOptionsInQuestions(q, draggedOptionKey, dragIndex, hoverIndex))
      }))
    }))

    const newQuestionnaireVM = { ...state.questionnaireVM, groups }
    const isDirty = state.snapshot !== getQuestionnaireSnapshot(newQuestionnaireVM)

    return { ...state, questionnaireVM: newQuestionnaireVM, isDirty }
  }
}

function swapOptionsInQuestions(question: QuestionViewModel, draggedOptionKey: string, dragIndex: number, hoverIndex: number): QuestionViewModel {
  const questionContainOption = question.options.find((x: OptionViewModel) => x.key === draggedOptionKey) !== undefined
  if (questionContainOption) {
    const newOptionsArray = update(question.options, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, question.options[dragIndex]]
      ]
    })
    return {
      ...question,
      options: newOptionsArray
    }
  }

  return {
    ...question,
    questionsTemplate: question.questionsTemplate.map((qt: QuestionViewModel) => swapOptionsInQuestions(qt, draggedOptionKey, dragIndex, hoverIndex)),
    sharedQuestions: question.sharedQuestions.map((sq: QuestionViewModel) => ({
      ...swapOptionsInQuestions(sq, draggedOptionKey, dragIndex, hoverIndex),
      questionsTemplate: sq.questionsTemplate.map((qt1: QuestionViewModel) => swapOptionsInQuestions(qt1, draggedOptionKey, dragIndex, hoverIndex))
    }))
  }
}

function swapSharedQuestions(draggedSharedQuestionKey: string, dragIndex: number, hoverIndex: number): QuestionnaireStateActionResult {
  return (state) => {
    const groups = state.questionnaireVM.groups.map((g: GroupContentViewModel) => ({
      ...g,
      columns: g.columns.map((c: ColumnViewModel) => ({
        ...c,
        questions: c.questions.map((q: QuestionViewModel) => swapSharedQuestionsInOption(q, draggedSharedQuestionKey, dragIndex, hoverIndex))
      }))
    }))

    const newQuestionnaireVM = { ...state.questionnaireVM, groups }
    const isDirty = state.snapshot !== getQuestionnaireSnapshot(newQuestionnaireVM)

    return { ...state, questionnaireVM: newQuestionnaireVM, isDirty }
  }
}

function swapSharedQuestionsInOption(question: QuestionViewModel, draggedSharedQuestionKey: string, dragIndex: number, hoverIndex: number): QuestionViewModel {
  const questionContainOption = question.sharedQuestionsOptions.find((x: SharedQuestionsOptions) => x.sharedQuestionId === draggedSharedQuestionKey) !== undefined
  if (questionContainOption) {
    const newOptionsArray = update(question.sharedQuestionsOptions, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, question.sharedQuestionsOptions[dragIndex]]
      ]
    })
    return {
      ...question,
      sharedQuestionsOptions: newOptionsArray
    }
  }

  return {
    ...question,
    questionsTemplate: question.questionsTemplate.map((qt: QuestionViewModel) => swapSharedQuestionsInOption(qt, draggedSharedQuestionKey, dragIndex, hoverIndex)),
    sharedQuestions: question.sharedQuestions.map((sq: QuestionViewModel) => ({
      ...swapSharedQuestionsInOption(sq, draggedSharedQuestionKey, dragIndex, hoverIndex),
      questionsTemplate: sq.questionsTemplate.map((qt1: QuestionViewModel) => swapSharedQuestionsInOption(qt1, draggedSharedQuestionKey, dragIndex, hoverIndex))
    }))
  }
}

function updateSharedQuestionsOptions(questionKey: string, sharedQuestionId: string | undefined, optionsId: string[]): QuestionnaireStateActionResult {
  return (state) => {
    const groups = state.questionnaireVM.groups.map((g: GroupContentViewModel) => ({
      ...g,
      columns: g.columns.map((c: ColumnViewModel) => ({
        ...c,
        questions: c.questions.map((q: QuestionViewModel) => {
          if (q.key === questionKey) {
            return sharedQuestionsOptionsUpdate(q, sharedQuestionId, optionsId)
          }

          const sharedQuestions = q.sharedQuestions.map((sq: QuestionViewModel) => {
            if (sq.key === questionKey) {
              return sharedQuestionsOptionsUpdate(sq, sharedQuestionId, optionsId)
            }

            return sq
          })
          return { ...q, sharedQuestions }
        })
      }))
    }))
    let newQuestionnaireVM = { ...state.questionnaireVM, groups }
    const isDirty = state.snapshot !== getQuestionnaireSnapshot(newQuestionnaireVM)
    newQuestionnaireVM = markErrors(newQuestionnaireVM)

    return { ...state, questionnaireVM: newQuestionnaireVM, isDirty }
  }
}

function sharedQuestionsOptionsUpdate(question: QuestionViewModel, sharedQuestionId: string | undefined, optionsId: string[]) {
  const sharedQuestionsOptionsNew = question.sharedQuestionsOptions.filter((sqo) => sqo.sharedQuestionId !== sharedQuestionId)
  optionsId.forEach((optionId: string) =>
    sharedQuestionsOptionsNew.filter((sqo) => sqo.optionId === optionId && sqo.sharedQuestionId === sharedQuestionId).length === 0
      ? sharedQuestionsOptionsNew.push({ optionId, sharedQuestionId } as SharedQuestionsOptions)
      : ''
  )
  return { ...question, sharedQuestionsOptions: sharedQuestionsOptionsNew }
}

function markErrors(questionnaire: QuestionnaireViewModel): QuestionnaireViewModel {
  const [newQuestionnaire] = getObjectWithErrorsMarked(questionnaire)
  return newQuestionnaire
}

function getObjectWithErrorsMarked<T extends Validate>(obj: T): [T, boolean] {
  const objCopy = { ...obj }
  let foundNestedErrors = false
  let foundErrors = false

  // eslint-disable-next-line no-restricted-syntax
  for (const propName in objCopy) {
    if (Array.isArray(objCopy[propName])) {
      const array = objCopy[propName]
      // eslint-disable-next-line no-restricted-syntax
      for (const idx in array) {
        if (Object.prototype.hasOwnProperty.call(array, idx)) {
          const item = array[idx]
          const [markedObject, foundError] = markObject(item)
          objCopy[propName][idx] = markedObject
          foundNestedErrors = foundError || foundNestedErrors
        }
      }
    } else if (typeof objCopy[propName] === 'object' && objCopy[propName] !== null) {
      const propObj = objCopy[propName]
      const [markedObject, foundError] = markObject(propObj)
      objCopy[propName] = markedObject
      foundNestedErrors = foundError || foundNestedErrors
    }
  }

  foundErrors = objCopy.hasValidationErrors || foundNestedErrors
  objCopy.hasErrors = foundErrors

  return [objCopy, foundErrors]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function markObject(propObj: any): [any, boolean] {
  let foundNestedErrors = false
  if (isValidate(propObj)) {
    const [nestedObj, hasNestedErrors] = getObjectWithErrorsMarked(propObj)
    foundNestedErrors = hasNestedErrors || foundNestedErrors
    return [nestedObj, foundNestedErrors]
  }

  return [propObj, false]
}

const getQuestionnaireSnapshot = (questionnaireParam: QuestionnaireViewModel) => {
  const data = {
    name: questionnaireParam.name,
    id: questionnaireParam.id,
    groups: questionnaireParam.groups?.map((g: GroupContentViewModel) => ({
      name: g.name,
      id: g.id,
      numberOfColumns: g.numberOfColumns,
      columns: g.columns?.map((c: ColumnViewModel) => ({
        id: c.id,
        name: c.name,
        questions: c.questions?.map((q: QuestionViewModel) => getQuestionSnapshot(q))
      }))
    }))
  }

  return JSON.stringify(data)
}

const getQuestionSnapshot = (question: QuestionViewModel): string => {
  const data = {
    id: question.id,
    text: question.text,
    tip: question.tip,
    isMandatory: question.isMandatory,
    type: question.type,
    maxNumberOfCharacters: question.maxNumberOfCharacters,
    multiplicationFactor: question.multiplicationFactor,
    questionsTemplate: question.questionsTemplate.length > 0 ? question.questionsTemplate.map((tq: QuestionViewModel) => getQuestionSnapshot(tq)) : [],
    sharedQuestions: question.sharedQuestions.length > 0 ? question.sharedQuestions.map((sq: QuestionViewModel) => getQuestionSnapshot(sq)) : [],
    sharedQuestionsOptions:
      question.sharedQuestionsOptions.length > 0
        ? question.sharedQuestionsOptions.map((qo: SharedQuestionsOptions) => ({ optionId: qo.optionId, sharedQuestionId: qo.sharedQuestionId }))
        : [],
    options: question.options.map((o: OptionViewModel) => getOptionSnapshot(o))
  }

  return JSON.stringify(data)
}

const getOptionSnapshot = (option: OptionViewModel): string => {
  const data = {
    id: option.id,
    text: option.text,
    isDefault: option.isDefault,
    linkedQuestions: option.linkedQuestions?.length > 0 ? option.linkedQuestions?.map((linkedQ: QuestionViewModel) => getQuestionSnapshot(linkedQ)) : []
  }

  return JSON.stringify(data)
}

function updateFormFieldIsReadOnly(value: boolean): QuestionnaireStateActionResult {
  return (state) => ({ ...state, formFieldIsReadOnly: value })
}

function updateCheckingMultiplicationNumber(value: boolean): QuestionnaireStateActionResult {
  return (state) => ({ ...state, isCheckingMultiplicationNumber: value })
}
