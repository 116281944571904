import { Badge, Icon, Message, Tooltip } from '@bdo/kitchensink'
import GlobalPortalLogo from 'assets/images/global-portal-logo.svg'
import translate from 'i18n/translate'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { AnswerQuestionnaireStatus, StatusColors } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import useClientStore, { useApplicationName, useClientId, usePublishedQuestionnaireData } from 'features/clients/clientZustandStore'
import IconButton from 'ui-components/IconButton/IconButton'
import getStatusHint from 'utils/getStatusHint'
import { useEffect, useState } from 'react'
import { getAnswersForClient } from 'services/QuestionsAnswers/QuestionAnswer.service'
import { useErrorBoundary } from 'react-error-boundary'
import { useIntl } from 'react-intl'
import { isProcessing } from 'services/Client/clientProcessedQuestionnaireStatus.service'
import { GlobalPortal } from 'services/Client/client.models'
import { answersImport } from 'services/answerQuestionnaireConfig.service'
import styles from './PublishedCreateProject.module.scss'
import ProjectInfo from './ProjectInfo'

export enum PortalStatus {
  Active = 'Active',
  Processing = 'Processing'
}

type Props = {
  appId: number
  portal: GlobalPortal | undefined
  isPortalInfoLoading: boolean
  isProjectLoading: boolean
  setIsProjectLoading: (value: boolean) => void
}

function PublishedCreateProject(props: Readonly<Props>) {
  const { appId, portal, isPortalInfoLoading, isProjectLoading, setIsProjectLoading } = props
  const clientId = useClientId()
  const navigate = useNavigate()
  const publishedQuestionnaireData = usePublishedQuestionnaireData(appId)
  const [isPortalCreated, setIsPortalCreated] = useState(false)
  const publishedQuestionnaireStatus = publishedQuestionnaireData ? publishedQuestionnaireData.status : AnswerQuestionnaireStatus.NotAvailable
  const publishedQuestionnaireId = publishedQuestionnaireData ? publishedQuestionnaireData.questionnaireId : ''
  const instanceId = publishedQuestionnaireData?.instanceId
  const applicationName = useApplicationName(appId)
  const removeAppIdsThatHaveAnswersAvailable: (appId: number) => void = useClientStore((state) => state.removeAppIdsThatHaveAnswersAvailable)
  const addAppIdsThatHaveAnswersAvailable: (appId: number) => void = useClientStore((state) => state.addAppIdsThatHaveAnswersAvailable)
  const { showBoundary } = useErrorBoundary()
  const intl = useIntl()
  const { isAnswersImportEnabled } = answersImport[applicationName]

  const handleClick = () => {
    navigate(`../questionnaires/${publishedQuestionnaireId}/application/${appId}/instance/${instanceId}/fill`)
  }

  const indexOfS = Object.values(AnswerQuestionnaireStatus).indexOf(publishedQuestionnaireStatus)
  const statusKey = Object.keys(AnswerQuestionnaireStatus)[indexOfS]
  const colorStatus = Object.values(StatusColors)[indexOfS]

  useEffect(() => {
    if (!instanceId || !clientId || !isAnswersImportEnabled) {
      return
    }
    getAnswersForClient(clientId, instanceId)
      .then((answers) => {
        if (answers.length > 0 && !answers[0].id) {
          addAppIdsThatHaveAnswersAvailable(appId)
        } else if ((answers.length > 0 && answers[0].id) || answers.length === 0) {
          removeAppIdsThatHaveAnswersAvailable(appId)
        }
      })
      .catch((e) => {
        showBoundary(e)
      })
  }, [publishedQuestionnaireData])

  useEffect(() => {
    if (!clientId || !portal) {
      setIsProjectLoading(false)
      return
    }

    const isPortalStatusActive = portal.portalStatus === PortalStatus.Active
    const isPortalStatusProcessing = portal.portalStatus === PortalStatus.Processing

    if (isPortalStatusProcessing) {
      Message.warning(intl.$t({ id: 'portal_processed' }), 10)
      setIsProjectLoading(false)
    }

    setIsPortalCreated(isPortalStatusActive)
  }, [portal])

  return (
    <div className={clsx(styles.publishedQuestionnaire, 'flex', 'alignItems')}>
      <div className={clsx(styles.publishedQuestionnaireWrapper, 'flex', 'alignItems')}>
        <img src={GlobalPortalLogo} alt='' />

        <ProjectInfo isPortalCreated={isPortalCreated} appId={appId} isProjectLoading={isProjectLoading} setIsProjectLoading={setIsProjectLoading} />

        <div className={clsx(styles.statusBadge, 'flex', 'alignItems')}>
          <Badge color={`${colorStatus}`} text={translate(`status_${statusKey}`)} className={styles.status} />
          {publishedQuestionnaireData && publishedQuestionnaireData.status !== AnswerQuestionnaireStatus.NotStarted && (
            <Tooltip title={getStatusHint(publishedQuestionnaireStatus, applicationName)} mouseEnterDelay={0.1} placement='right'>
              <Icon type='Unknown' className={styles.hintIcon} />
            </Tooltip>
          )}
        </div>
      </div>
      <Tooltip title={!isPortalCreated ? intl.$t({ id: 'portal_not_created' }) : ''} mouseEnterDelay={0.3} placement='topRight'>
        <div className={styles.editButton}>
          <IconButton
            type='Edit'
            toolTipTitle={intl.$t({ id: 'button_edit' })}
            testId='editIcon'
            onClick={handleClick}
            loading={isPortalInfoLoading}
            disabled={
              (isProcessing(clientId, appId) && publishedQuestionnaireStatus === AnswerQuestionnaireStatus.Processing) ||
              publishedQuestionnaireStatus === AnswerQuestionnaireStatus.NotAvailable ||
              !isPortalCreated
            }
          />
        </div>
      </Tooltip>
    </div>
  )
}

export default PublishedCreateProject
