export type QuestionAnswer = {
  id?: string
  questionId: string
  text: string
}

export enum AnswerQuestionnaireStatus {
  NotAvailable = 'NotAvailable',
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Ready = 'Ready',
  Completed = 'Completed',
  Processing = 'Processing'
}

export enum StatusColors {
  NotAvailable = '#959597',
  NotStarted = '#959597',
  InProgress = '#ed1a3b',
  Ready = '#fd7e14',
  Completed = '#54a575',
  Processing = '#54a576'
}
