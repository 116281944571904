import { useSelectedGroup, useSelectedGroupNumberOfColumn } from 'features/questionnaire/questionaryZustandStore'
import { ColumnViewModel } from '../_models/ColumnViewModel'
import Columns from './Columns'

type ColumnsContainerProps = {
  onAddQuestion: (columnKey: string | undefined) => void
}

function ColumnsContainer(props: Readonly<ColumnsContainerProps>) {
  const { onAddQuestion } = props

  const selectedGroup = useSelectedGroup()
  const isSingleColumn = useSelectedGroupNumberOfColumn() <= 1

  return (
    <div>
      {selectedGroup.columns
        ?.filter((x: ColumnViewModel) => x.isDeleted !== true)
        .map((column: ColumnViewModel) => (
          <Columns columnKey={column.key} withHeader={!isSingleColumn} onAddQuestion={onAddQuestion} key={column.key} />
        ))}
    </div>
  )
}

export default ColumnsContainer
