import { onboardingToolApiConfig } from 'app.config'
import axios from 'axios'
import { GlobalPortal, GlobalPortalProject } from './Client/client.models'

type APIProject = {
  instanceId: string | null
  lastUpdate: LastUpdate | null
  projectCreated: string
  projectName: string
  projectStatusDetails: string
  questionnaireId: string | null
  questionnaireName: string | null
}

type LastUpdate = {
  date: Date
  user: User
}

type User = {
  id: string
  firstName: string
  lastName: string
}

const projectsUri = `${onboardingToolApiConfig.uri}/portals/projects`
const portalUri = `${onboardingToolApiConfig.uri}/portals`

export async function getPortalsInfoByClientId(clientId: number): Promise<GlobalPortal> {
  const url = `${portalUri}?clientId=${clientId}`
  const response = await axios.get(url)
  const portal = response.data

  const globalPortal: GlobalPortal = {
    clientId: portal.clientId,
    portalName: portal.siteStatus.portalName,
    questionnaireName: portal.siteStatus.questionnaireName,
    questionnaireId: portal.siteStatus.questionnaireId,
    portalStatus: portal.siteStatus.portalStatusDetails,
    portalCreated: portal.siteStatus.lastUpdate,
    createdBy: portal.siteStatus.lastUpdate,
    instanceId: portal.siteStatus.instanceId,
    action: portal.siteStatus.questionnaireId !== null && portal.siteStatus.instanceId !== null
  }

  return globalPortal
}

export async function getProjects(clientId: number): Promise<GlobalPortalProject[]> {
  const url = `${projectsUri}?clientId=${clientId}`
  const response = await axios.get(url)

  const globalPortalProjects: GlobalPortalProject[] = response.data.map((project: APIProject) => ({
    projectName: project.projectName,
    questionnaireName: project.questionnaireName,
    questionnaireId: project.questionnaireId,
    projectCreated: project.projectCreated,
    createdBy: project.lastUpdate,
    instanceId: project.instanceId,
    action: project.projectName !== 'General Information'
  }))

  return globalPortalProjects
}
