/* eslint-disable react/require-default-props */
import EditValueModal from 'ui-components/EditValueModal/EditValueModal'
import translate from 'i18n/translate'
import { useIntl } from 'react-intl'
import { QuestionViewModel } from 'features/questionnaire/components/_models/QuestionViewModel'
import clsx from 'clsx'
import { useColumn, useFormFieldIsReadOnly, useQuestionnaireStore } from 'features/questionnaire/questionaryZustandStore'
import { useDrop } from 'react-dnd'
import AddButton from 'ui-components/AddButton/AddButton'
import styles from './Columns.module.scss'
import DragItem from '../_models/DragItem'
import Question from '../Question/Question'

type ColumnProps = {
  columnKey: string
  withHeader?: boolean
  onAddQuestion: (columnKey: string | undefined) => void
}

export default function Columns(props: Readonly<ColumnProps>) {
  const { columnKey, withHeader, onAddQuestion } = props

  const selectedGroupKey = useQuestionnaireStore((state) => state.selectedGroupKey)
  const updateColumn = useQuestionnaireStore((state) => state.updateColumn)
  const moveQuestionToColumn = useQuestionnaireStore((state) => state.moveQuestionToColumn)
  const isReadOnly = useQuestionnaireStore((state) => state.isReadOnly)
  const column = useColumn(columnKey)
  const intl = useIntl()
  const formFieldIsReadOnly = useFormFieldIsReadOnly()
  const isPublishedQuestionnaire = useQuestionnaireStore((state) => state.isPublishedQuestionnaire)

  if (!selectedGroupKey) {
    throw new Error('No group is selected')
  }

  const setName = (newColumnName: string) => {
    if (!column || !selectedGroupKey) {
      return
    }
    const updatedColumn = {
      ...column,
      name: newColumnName
    }
    updateColumn(selectedGroupKey, updatedColumn)
  }

  const onMoveQuestionToColumn = (itemId: string) => {
    if (!column || !selectedGroupKey) {
      return
    }
    moveQuestionToColumn(column.key, selectedGroupKey, itemId)
  }

  const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
    accept: `qitem0`,
    drop(itemParam: DragItem) {
      const item = itemParam

      onMoveQuestionToColumn(item.id)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }))

  const onColumnUpdate = (newName: string) => {
    setName(newName)
    return ''
  }

  return (
    <div
      key={column?.key}
      ref={dropRef}
      className={clsx(styles.columnWrapper, isOver && styles.columnWrapperOver, canDrop && styles.columnWrapperCanDrop)}
      data-testid='column-container'
    >
      {withHeader ? (
        <div className={clsx(styles.headerWrapper, 'flex', 'alignItems')}>
          <h5>{column?.name}</h5>
          <div className={clsx('flex', 'alignItems', 'ml-3', isReadOnly ? 'hidden' : '')}>
            <span className={clsx(styles.addQuestionButton, isPublishedQuestionnaire ? 'hidden' : '')}>
              <AddButton handleAction={() => onAddQuestion(column?.key)} size='small' disabled={formFieldIsReadOnly}>
                {translate('question_add_new')}
              </AddButton>
            </span>

            <EditValueModal
              onConfirmEnteredValue={onColumnUpdate}
              currentValue={column?.name}
              title={intl.$t({ id: 'column_name_edit' })}
              fieldName={intl.$t({ id: 'column_name' })}
              okText={intl.$t({ id: 'button_confirm' })}
              cancelText={intl.$t({ id: 'button_cancel' })}
              buttonText=''
              closePopover={() => null}
            />
          </div>
        </div>
      ) : null}
      {column?.questions.length ? column.questions.map((q: QuestionViewModel, idx: number) => <Question questionKey={q.key} key={q.key} level={0} idx={idx} />) : null}
    </div>
  )
}
