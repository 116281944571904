import { useQuestion } from 'features/questionnaire/questionaryZustandStore'

import QuestionClosed from '../QuestionClosed/QuestionClosed'
// eslint-disable-next-line import/no-cycle
import QuestionOpened from '../QuestionOpened/QuestionOpened'

type Props = {
  questionKey: string
  level: number
  idx: number
}

export default function Question(props: Readonly<Props>) {
  const { questionKey, level, idx } = props
  const question = useQuestion(questionKey)

  return (
    <div key={question?.key} data-testid='question-object'>
      {!question?.isOpen ? <QuestionClosed questionKey={questionKey} level={level} idx={idx} /> : <QuestionOpened questionKey={questionKey} level={level} idx={idx} />}
    </div>
  )
}
