/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import TopBar from 'ui-components/TopBar/TopBar'
import GroupTabs from 'features/questionnaire/components/GroupTabs/GroupTabs'
import { getQuestionnaire, getQuestionnaires } from 'services/Questionnaire/questionnaire.service'
import { useIntl } from 'react-intl'
import StatusBadge from 'features/questionnaires/components/StatusBadge/StatusBadge'
import { useQuestionnaireApplicationName, useQuestionnaireStore, useStatus } from 'features/questionnaire/questionaryZustandStore'
import GroupContent from 'features/questionnaire/components/GroupContent/GroupContent'
import EditValueModal from 'ui-components/EditValueModal/EditValueModal'
import { toLimitString } from 'utils/StringHelper/string-helper'
import SaveAndPublish from 'features/questionnaire/components/SaveAndPublish/SaveAndPublish'
import usePrompt from 'hooks/usePromptHook'
import Loader from 'ui-components/Loader/Loader'
import PageNotification from 'ui-components/PageNotification/PageNotification'
import EmptyQuestionnaire from 'features/questionnaire/components/EmptyQuestionnaire/EmptyQuestionnaire'
import { from } from 'linq-to-typescript'
import { QuestionnaireStatuses } from 'services/Questionnaire/questionnaire.models'
import { useErrorBoundary } from 'react-error-boundary'
import styles from './Questionnaire.module.scss'

function Questionnaire() {
  const intl = useIntl()
  const isPublishedQuestionnaire = useQuestionnaireStore((state) => state.isPublishedQuestionnaire)
  const isReadOnly = useQuestionnaireStore((state) => state.isReadOnly)
  const isDirty = useQuestionnaireStore((state) => state.isDirty)
  const setQuestionnaireName = useQuestionnaireStore((state) => state.setQuestionnaireName)
  const reset = useQuestionnaireStore((state) => state.reset)
  const selectGroup = useQuestionnaireStore((state) => state.selectGroup)
  const setQuestionnaireVM = useQuestionnaireStore((state) => state.setQuestionnaireVM)
  const selectedGroupKey = useQuestionnaireStore((state) => state.selectedGroupKey)
  const questionnaireName = useQuestionnaireStore((state) => state.questionnaireVM.name)
  const applicationName = useQuestionnaireApplicationName()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { id } = useParams<string>()
  const status = useStatus()
  const { showBoundary } = useErrorBoundary()

  usePrompt(intl.$t({ id: 'user_message_unsaved_changes' }), isDirty)

  useEffect(() => {
    if (!id) {
      return
    }
    setIsLoading(true)

    getQuestionnaire(id)
      .then((questionnaireData) => {
        setQuestionnaireVM(questionnaireData)
        if (questionnaireData.groups.length > 0) {
          selectGroup(questionnaireData.groups[0].key)
        }
      })
      .catch((e) => {
        showBoundary(e)
      })
      .finally(() => {
        setIsLoading(false)
      })

    // eslint-disable-next-line consistent-return
    return function cleanupStore() {
      reset()
    }
  }, [id])

  const onQuestionnaireNameUpdate = (newName: string) => {
    let result = ''
    return getQuestionnaires(newName, [QuestionnaireStatuses.Published, QuestionnaireStatuses.Draft, QuestionnaireStatuses.Archived]).then((questionnaires) => {
      const questionnaireNameAlreadyExists =
        from(questionnaires).any((x) => x.name.toLowerCase() === newName.toLowerCase()) && questionnaireName.toLowerCase() !== newName.toLowerCase()
      if (questionnaireNameAlreadyExists) {
        result = intl.$t({ id: 'message_error_questionnaire_name_exists' })
      } else {
        setQuestionnaireName(newName)
        result = ''
      }
      return result
    })
  }

  return (
    <div className={styles.wrapper}>
      <Loader open={isLoading} text={intl.$t({ id: 'action_loading' })} />
      <TopBar
        topBarData={{
          pageName: `${toLimitString(questionnaireName, 40)}`,
          icon: 'QuestionnaireMirrored',
          backLink: '/questionnaires',
          backLinkPageNameTranslation: intl.$t({ id: 'questionnaires' }),
          isPageNameEditable: !isReadOnly,
          editPageNamePopup: !isLoading && (
            <EditValueModal
              onConfirmEnteredValue={onQuestionnaireNameUpdate}
              currentValue={questionnaireName}
              title={intl.$t({ id: 'questionnaire_name_edit' })}
              fieldName={intl.$t({ id: 'questionnaire_name' })}
              okText={intl.$t({ id: 'button_confirm' })}
              cancelText={intl.$t({ id: 'button_cancel' })}
              closePopover={() => null}
            />
          ),
          info1: intl.$t({ id: `application_${applicationName}` }),
          info3: status && <StatusBadge questionnaireStatus={status} />,
          notification:
            (isReadOnly ? <PageNotification infoNotification notification={intl.$t({ id: 'user_message_when_archived_questionnaire_opened' })} /> : undefined) ??
            (isPublishedQuestionnaire ? <PageNotification warningNotification notification={intl.$t({ id: 'user_message_when_published_questionnaire_opened' })} /> : undefined)
        }}
      >
        <SaveAndPublish isLoading={isLoading} />
      </TopBar>
      <div className={`${styles.contentWrapper} flex`}>
        <div className={styles.questionGroupsWrapper}>
          <GroupTabs isLoading={isLoading} />
        </div>
        <div className={styles.questionsWrapper}>{selectedGroupKey ? <GroupContent /> : <EmptyQuestionnaire isLoading={isLoading} />}</div>
      </div>
    </div>
  )
}

export default Questionnaire
