// eslint-disable-next-line import/prefer-default-export
import { RcFile } from 'antd/lib/upload'
import axios from 'axios'
import queryString from 'query-string'
import toQueryString, { SelectedFilter, FilterItem } from 'utils/FilterHelper/filter-helper'
import { onboardingToolApiConfig } from '../app.config'
import { PaginatedResult } from './service.models'

export type LedgerAccount = {
  id: number
  accountNumber: string
  type: AccountType
  dutchDescription: string
  englishDescription: string
  alwaysOn: boolean
}

type LedgerAccountApiModel = {
  id: number
  accountNumber: number
  type: AccountType
  dutchDescription: string
  englishDescription: string
  alwaysOn: boolean
}

export enum AccountType {
  BAS = 'BAS',
  PNL = 'PNL'
}

export enum AccountFields {
  AccountNumber = 'accountNumber',
  DescriptionNL = 'dutchDescription',
  DescriptionEN = 'englishDescription',
  AlwaysOn = 'alwaysOn'
}

export const accountFilterableFields: SelectedFilter[] = [
  { fieldName: AccountFields.AccountNumber, type: 'number' },
  { fieldName: AccountFields.DescriptionNL, type: 'string' },
  { fieldName: AccountFields.DescriptionEN, type: 'string' },
  { fieldName: AccountFields.AlwaysOn, type: 'boolean' }
]

const ledgerUri = `${onboardingToolApiConfig.uri}/ledger`

export async function getAccounts(page: number, pageSize: number, filters: FilterItem[], sorter?: string): Promise<PaginatedResult<LedgerAccount>> {
  if (page < 1) {
    throw Error('page cannot be lower than 1')
  }
  const filterString = toQueryString(filters)
  const sorterString = sorter
  const queryParams = { skip: (page - 1) * pageSize, top: pageSize, filter: filterString, orderBy: sorterString }
  const getUri = queryString.stringifyUrl({ url: ledgerUri, query: queryParams }, { skipEmptyString: true })
  const getResponse = await axios.get<PaginatedResult<LedgerAccountApiModel>>(getUri)

  const result: PaginatedResult<LedgerAccount> = {
    total: getResponse.data.total,
    rows: getResponse.data.rows.map((a) => mapLedgerAccount(a))
  }
  return result
}

export async function exportAccounts(filters: FilterItem[], sorter?: string) {
  const filterString = toQueryString(filters)
  const sorterString = sorter
  const queryParams = { filter: filterString, orderBy: sorterString }
  const exportUri = `${ledgerUri}/export?`
  const getUri = queryString.stringifyUrl({ url: exportUri, query: queryParams }, { skipEmptyString: true })
  const response = await axios.get(getUri, { responseType: 'blob' })

  return response
}

export async function getAccount(id: number): Promise<LedgerAccount> {
  const accountUri = `${onboardingToolApiConfig.uri}/ledger/${id}`
  const response = await axios.get<LedgerAccountApiModel>(accountUri)
  return mapLedgerAccount(response.data)
}

function mapLedgerAccount(apiModel: LedgerAccountApiModel): LedgerAccount {
  const accountNumberMaxLength = 7
  const account: LedgerAccount = { ...apiModel, accountNumber: apiModel.accountNumber.toString().padStart(accountNumberMaxLength, '0') }
  return account
}

export async function createAccount(account: LedgerAccount): Promise<LedgerAccount> {
  const response = await axios.post<LedgerAccountApiModel>(ledgerUri, account)
  return mapLedgerAccount(response.data)
}

export async function saveAccount(account: LedgerAccount): Promise<LedgerAccount> {
  const response = await axios.put<LedgerAccountApiModel>(ledgerUri, account)
  return mapLedgerAccount(response.data)
}

export async function deleteAccount(id: number) {
  const uri = `${onboardingToolApiConfig.uri}/ledger?id=${id}`
  return axios.delete(uri)
}

export async function uploadAccountFile(file: RcFile) {
  const formData = new FormData()
  formData.append('file', file)
  const uploadUri = `${ledgerUri}/upload`
  const response = await axios.post(uploadUri, formData) // TODO: response object with errors if any
  if (response.status === 200) {
    return response.data
  }
  throw new Error('Thrown from uploadAccountFile')
}
