import { Icon } from '@bdo/kitchensink'
import { Tooltip } from 'antd'
import BackButton from 'ui-components/BackButton/BackButton'
import clsx from 'clsx'
import { memo } from 'react'
import styles from './TopBar.module.scss'

type TopBarData = {
  pageName: string
  icon: string
  backLink: string
  backLinkPageNameTranslation: string
  isPageNameEditable?: boolean
  editPageNamePopup?: JSX.Element | boolean
  info1?: string
  info2?: string
  info3?: JSX.Element | string
  notification?: JSX.Element
}

type Props = {
  topBarData: TopBarData
  children?: JSX.Element
}

function TopBar(props: Readonly<Props>) {
  const { topBarData, children } = props
  return (
    <div className={styles.topBarWrapper}>
      {topBarData.notification}
      <div className={clsx(styles.wrapper, 'flex', 'alignItems')}>
        <div>
          <BackButton page={topBarData.backLinkPageNameTranslation} link={topBarData.backLink} />

          <div className={`${styles.pageTitle} flex alignItems`}>
            <Icon type={topBarData.icon} className={styles.avatar} />
            <div>
              <div className={clsx('flex', 'alignItems')}>
                <Tooltip title={topBarData.pageName} mouseEnterDelay={1}>
                  <h2 data-testid='page_name'>{topBarData.pageName}</h2>
                </Tooltip>
                {topBarData.isPageNameEditable && topBarData.editPageNamePopup}
              </div>
              <div className={clsx(styles.questionnaireInfo, 'flex', 'alignItems')}>
                <p>{topBarData.info1}</p>
                {topBarData.info2 && (
                  <>
                    <span className={styles.pipe}>|</span>
                    <p>{topBarData.info2}</p>
                  </>
                )}
                {topBarData.info3 && (
                  <>
                    <span className={styles.pipe}>|</span>
                    <p>{topBarData.info3}</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='flex alignItems'>{children}</div>
      </div>
    </div>
  )
}

export default memo(TopBar)
