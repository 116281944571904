import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import TopBar from 'ui-components/TopBar/TopBar'
import { useEffect, useState } from 'react'
import { getClient } from 'services/Client/client.service'
import { ClientDto } from 'services/Client/client.models'
import { useIntl } from 'react-intl'
import useClientStore from 'features/clients/clientZustandStore'
import { toLimitString } from 'utils/StringHelper/string-helper'
import TabClient from 'features/clientDetails/components/TabClient/TabClient'
import clsx from 'clsx'
import Loader from 'ui-components/Loader/Loader'
import { useErrorBoundary } from 'react-error-boundary'
import { AnswerQuestionnaireStatus } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import { isProcessing } from 'services/Client/clientProcessedQuestionnaireStatus.service'
import { globalPortalInfo } from 'app.config'
import styles from './ClientPage.module.scss'

function ClientPage() {
  const { id } = useParams<string>()
  const clientId = parseInt(id ?? '0', 10)
  const clientName = useClientStore((state) => state.client.name)
  const setClient = useClientStore((state) => state.setClient)
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { showBoundary } = useErrorBoundary()
  const allAppsThatAreSendingRequest = useClientStore((state) => state.appsThatAreSendingRequest)
  const isGlobalPortalDisabled = globalPortalInfo.isDisabled

  useEffect(() => {
    if (!clientId) {
      return
    }

    setIsLoading(true)
    getClient(clientId)
      .then((data: ClientDto) => {
        const serviceApps = data.serviceApps.map((app) => {
          if (isProcessing(data.id, app.id)) {
            return { ...app, publishedQuestionnaire: { ...app.publishedQuestionnaire, status: AnswerQuestionnaireStatus.Processing } }
          }
          return app
        })
        setClient({ ...data, serviceApps })
      })
      .catch((e) => {
        showBoundary(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [allAppsThatAreSendingRequest])

  return (
    <div className={styles.wrapper}>
      <Loader open={isLoading} text={intl.$t({ id: 'action_loading' })} />
      {!isLoading && (
        <div>
          <TopBar
            topBarData={{
              pageName: `${toLimitString(clientName, 100)}`,
              icon: 'Contact',
              backLink: '/clients',
              backLinkPageNameTranslation: intl.$t({ id: 'clients' }),
              info1: `${intl.$t({ id: 'client_relation_number' })}: ${clientId}`
            }}
          />
          <div className={clsx(styles.tabsWrapper, styles.noNotification, 'flex')}>
            <TabClient label={intl.$t({ id: 'services_and_apps' })} handleOnChangeTab={() => navigate('services_apps')} isSelected={location.pathname.includes('services_apps')} />
            <TabClient label={intl.$t({ id: 'documents' })} handleOnChangeTab={() => navigate('documents')} isSelected={location.pathname.includes('documents')} />
            {!isGlobalPortalDisabled && (
              <TabClient
                label={intl.$t({ id: 'portal_and_projects' })}
                handleOnChangeTab={() => navigate('portal_and_projects')}
                isSelected={location.pathname.includes('portal_and_projects')}
              />
            )}
            <TabClient label={intl.$t({ id: 'client_details' })} handleOnChangeTab={() => navigate('client_details')} isSelected={location.pathname.includes('client_details')} />
          </div>
          <div className={styles.clientInfo}>
            <Outlet />
          </div>
        </div>
      )}
    </div>
  )
}

export default ClientPage
