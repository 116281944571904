import { useMsal } from '@azure/msal-react'
import { useEffect, useState } from 'react'
import ErrorPage from '../../pages/ErrorPage/ErrorPage'

type RoleProtectedRouteProps = {
  children: JSX.Element
  userRole: string
}

function RoleProtectedRoute(props: Readonly<RoleProtectedRouteProps>) {
  const { instance } = useMsal()
  const [hasAccess, setHasAccess] = useState(false)
  const { userRole, children } = props

  const onLoad = () => {
    const currentAccount = instance.getActiveAccount()
    if (currentAccount?.idTokenClaims && currentAccount.idTokenClaims.roles) {
      const { roles } = currentAccount.idTokenClaims
      setHasAccess(roles.includes(userRole))
    }
  }

  useEffect(() => {
    onLoad()
  }, [instance])

  return <div>{hasAccess ? <div>{children}</div> : <ErrorPage status={401} />}</div>
}

export default RoleProtectedRoute
