import { memo, useState } from 'react'
import { Icon } from '@bdo/kitchensink'
import translate from 'i18n/translate'
import { Modal } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { QuestionViewModel } from 'features/questionnaire/components/_models/QuestionViewModel'
import clsx from 'clsx'
import {
  useQuestionnaireStore,
  useSelectedGroupName,
  useSelectedGroupNumberOfColumn,
  useSelectedGroupColumnKey,
  useSelectedGroup
} from 'features/questionnaire/questionaryZustandStore'
import EditValueModal from 'ui-components/EditValueModal/EditValueModal'
import { useIntl } from 'react-intl'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import AddButton from 'ui-components/AddButton/AddButton'
import { from } from 'linq-to-typescript'
import styles from './GroupContent.module.scss'
import ColumnsContainer from '../Columns/ColumnsContainer'

function GroupContent() {
  const selectedGroupKey = useQuestionnaireStore((state) => state.selectedGroupKey)
  const selectedGroupName = useSelectedGroupName()
  const selectedGroupColumnKey = useSelectedGroupColumnKey()
  const groupHasErrors = useQuestionnaireStore((state) => state.questionnaireVM.hasErrors)
  const formFieldIsReadOnly = useQuestionnaireStore((state) => state.formFieldIsReadOnly)
  const group = useSelectedGroup()
  const intl = useIntl()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const isPublishedQuestionnaire = useQuestionnaireStore((state) => state.isPublishedQuestionnaire)
  const isSingleColumn = useSelectedGroupNumberOfColumn() <= 1

  const isReadOnly = useQuestionnaireStore((state) => state.isReadOnly)
  const switchGroupTo2Columns = useQuestionnaireStore((state) => state.switchGroupTo2Columns)
  const switchGroupTo1Column = useQuestionnaireStore((state) => state.switchGroupTo1Column)
  const addQuestion = useQuestionnaireStore((state) => state.addQuestion)
  const updateSelectedGroupName = useQuestionnaireStore((state) => state.updateSelectedGroupName)
  const groups = useQuestionnaireStore((state) => state.questionnaireVM.groups)

  const onSingleColumn = () => {
    if (isSingleColumn) {
      return
    }
    setIsModalOpen(true)
  }

  const onTwoColumn = () => {
    if (!isSingleColumn) {
      return
    }

    switchGroupTo2Columns()
  }

  const switchToOneColumn = () => {
    setIsModalOpen(false)

    switchGroupTo1Column()
  }

  const onAddQuestion = (columnKey: string | undefined) => {
    const newQuestion: QuestionViewModel = {
      key: uuidv4(),
      text: '',
      isMandatory: false,
      type: 'Text',
      tip: '',
      isOpen: true,
      maxNumberOfCharacters: 164,
      hasErrors: false,
      isDisplay: false,
      multiplicationFactor: 1,
      options: [],
      hasValidationErrors: false,
      questionsTemplate: [],
      isQuestionTemplate: false,
      generatedQuestions: [],
      sharedQuestions: [],
      isSharedQuestion: false,
      sharedQuestionsOptions: []
    }
    if (columnKey) {
      addQuestion(selectedGroupKey ?? '', columnKey, newQuestion)
    }
  }

  const onUpdateGroup = (newName: string) => {
    const groupNameAlreadyExists = from(groups).any((x) => x.name.toLowerCase() === newName.toLowerCase()) && group.name.toLowerCase() !== newName.toLowerCase()
    if (groupNameAlreadyExists) {
      return intl.$t({ id: 'message_error_group_name_exists' })
    }
    updateSelectedGroupName(newName)
    return ''
  }

  return (
    <>
      <div className={clsx(styles.barWrapper, 'card', 'flex', 'alignItems')}>
        <div>
          <h3 className={clsx(!isSingleColumn ? styles.groupTitle : 'modalTitle', 'flex', 'alignItems')}>
            {groupHasErrors && <Icon type='Error' className={clsx(styles.warningIcon, 'redText')} />}
            {selectedGroupName}
            {!isReadOnly && (
              <EditValueModal
                onConfirmEnteredValue={onUpdateGroup}
                closePopover={() => null}
                currentValue={selectedGroupName}
                title={intl.$t({ id: 'question_group_edit_name' })}
                fieldName={intl.$t({ id: 'question_group_name' })}
                okText={intl.$t({ id: 'button_confirm' })}
                cancelText={intl.$t({ id: 'button_cancel' })}
              />
            )}
          </h3>

          {isSingleColumn && !isReadOnly && !isPublishedQuestionnaire && (
            <AddButton handleAction={() => onAddQuestion(selectedGroupColumnKey)}>{translate('question_add_new')}</AddButton>
          )}
        </div>
        <div className={clsx(styles.columns, isReadOnly || isPublishedQuestionnaire ? 'hidden' : '')}>
          <button type='button' className={clsx(isSingleColumn && styles.activeButton, styles.switchButton)} onClick={onSingleColumn} disabled={formFieldIsReadOnly}>
            {translate('column_one')}
          </button>
          <button type='button' className={clsx(!isSingleColumn && styles.activeButton, styles.switchButton)} onClick={onTwoColumn} disabled={formFieldIsReadOnly}>
            {translate('column_two')}
          </button>
        </div>
      </div>
      <div>
        <DndProvider backend={HTML5Backend}>
          <ColumnsContainer onAddQuestion={onAddQuestion} />
        </DndProvider>
      </div>
      <Modal
        title={translate('user_message_combine_columns_question')}
        open={isModalOpen}
        onOk={switchToOneColumn}
        onCancel={() => setIsModalOpen(false)}
        okText={translate('button_combine')}
        cancelText={translate('button_cancel')}
      >
        {translate('user_message_combine_columns_description')}
      </Modal>
    </>
  )
}

export default memo(GroupContent)
