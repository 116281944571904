import translate from 'i18n/translate'
import { Button, Icon, Message } from '@bdo/kitchensink'
import useQuestionnaireAnswerStore, {
  useQuestionnaireAnswer,
  useIsDirty,
  useQuestionnaireHasErrors,
  useAllQuestions
} from 'features/clientDetails/components/questionAnswerZustandStore'
import { saveAnswersForInstance, sendInstanceAnswers } from 'services/QuestionsAnswers/QuestionAnswer.service'
import useClientStore, { useApplicationName, useClientId, useServiceByAppId, useStatusOfCurrentPublishedQuestionnaire } from 'features/clients/clientZustandStore'
import { useIntl } from 'react-intl'
import { memo, useEffect, useState } from 'react'
import { QuestionViewModel } from 'features/questionnaire/components/_models/QuestionViewModel'
import { AnswerQuestionnaireStatus, QuestionAnswer } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import clsx from 'clsx'
import { useNavigate, useParams } from 'react-router-dom'
import axios, { AxiosError } from 'axios'
import Loader from 'ui-components/Loader/Loader'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import SignalRContext from 'utils/SignalRContextHelper/SignalRContextHelper'
import { processingFinished, isProcessing, markTaskCompletionAsStarted } from 'services/Client/clientProcessedQuestionnaireStatus.service'
import { Apps, Services } from 'services/Questionnaire/questionnaire.models'
import styles from './SaveAndComplete.module.scss'

type Props = { appId: number; currentProjectCompletedInstance: string | undefined }

function SaveAndComplete(props: Readonly<Props>) {
  const { appId, currentProjectCompletedInstance } = props
  const { instanceId } = useParams()
  const intl = useIntl()
  const answers = useQuestionnaireAnswer()
  const isDirty = useIsDirty()
  const questionnaireHasErrors = useQuestionnaireHasErrors()
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const applicationName = useApplicationName(appId)
  const [isCurrentQuestionnaireProcessed, setIsCurrentQuestionnaireProcessed] = useState<boolean>(false)
  const publishedQuestionnaireStatus = useStatusOfCurrentPublishedQuestionnaire(appId)
  const isArchived = useQuestionnaireAnswerStore((state) => state.isArchived)
  const clientId = useClientId()
  const currentService = useServiceByAppId(appId)
  const updateStatusOfSelectedPublishedQuestionnaire = useClientStore((state) => state.updateStatusOfSelectedPublishedQuestionnaire)
  const navigate = useNavigate()
  const allQuestions = useAllQuestions()
  const updateAnswers = useQuestionnaireAnswerStore((store) => store.updateAnswers)
  const updateReadOnly = useQuestionnaireAnswerStore((store) => store.updateReadOnly)
  const isSavingAndGenerating = useQuestionnaireAnswerStore((store) => store.isSavingAndGenerating)
  const setIsSavingAndGenerating = useQuestionnaireAnswerStore((store) => store.setIsSavingAndGenerating)
  const addIdOfAppSendingRequest = useClientStore((state) => state.addIdOfAppSendingRequest)
  const removeIdOfAppWhenRequestSent = useClientStore((state) => state.removeIdOfAppWhenRequestSent)

  if (!instanceId) {
    LoggerHelper('Instance does not exist')
    throw new Error('Instance does not exist')
  }

  const indexOfS = Object.values(AnswerQuestionnaireStatus).indexOf(publishedQuestionnaireStatus)
  const status = Object.keys(AnswerQuestionnaireStatus)[indexOfS]

  const onSaveDraft = () => {
    const questionAnswers = clearAnswerNotDisplay()
    setIsSaving(true)
    updateReadOnly(true)

    return saveAnswersForInstance(clientId, instanceId, questionAnswers, status)
      .then((savedAnswers) => {
        updateAnswers(savedAnswers)
        Message.success(intl.$t({ id: 'user_message_questionnaire_saved' }), 5)
      })
      .catch(async (error) => {
        LoggerHelper(error)
        Message.error(intl.$t({ id: 'user_message_questionnaire_save_error' }), 5)
        setIsSavingAndGenerating(false)
        updateReadOnly(false)
        throw Error(error)
      })
      .finally(() => {
        setIsSaving(false)
        updateReadOnly(false)
      })
  }

  const clearAnswerNotDisplay = () => {
    const notDisplayQuestions = allQuestions.filter((q: QuestionViewModel) => q.isDisplay === false).map((vq: QuestionViewModel) => vq.key)
    const validAnswers: QuestionAnswer[] = answers.map((answer: QuestionAnswer) => {
      if (notDisplayQuestions.includes(answer.questionId)) {
        return { ...answer, text: '' }
      }
      return answer
    })
    return validAnswers
  }

  useEffect(() => {
    const isQuestionnaireCurrentlyProcessed = publishedQuestionnaireStatus === AnswerQuestionnaireStatus.Processing && !isProcessing(clientId, appId)

    setIsSavingAndGenerating(isQuestionnaireCurrentlyProcessed)
    updateReadOnly(isQuestionnaireCurrentlyProcessed)

    if (publishedQuestionnaireStatus === AnswerQuestionnaireStatus.Completed && currentService === Services.globalPortal) {
      updateReadOnly(true)
    }
  }, [publishedQuestionnaireStatus])

  useEffect(() => {
    const isQuestionnaireCurrentlyProcessed = publishedQuestionnaireStatus === AnswerQuestionnaireStatus.Processing && isProcessing(clientId, appId)

    setIsSavingAndGenerating(isQuestionnaireCurrentlyProcessed)
    updateReadOnly(isQuestionnaireCurrentlyProcessed)

    if (
      (publishedQuestionnaireStatus === AnswerQuestionnaireStatus.Completed || currentProjectCompletedInstance === AnswerQuestionnaireStatus.Completed) &&
      currentService === Services.globalPortal
    ) {
      updateReadOnly(true)
    }
  }, [currentProjectCompletedInstance])

  const onSaveAndComplete = () => {
    onSaveDraft()
      .then(() => {
        handleQuestionnaireTask()
      })
      .catch((e) => {
        LoggerHelper(e)
      })
  }

  const handleQuestionnaireTask = () => {
    setIsSavingAndGenerating(true)
    setIsCurrentQuestionnaireProcessed(true)

    sendInstanceAnswers(clientId, instanceId, SignalRContext.connection?.connectionId ?? '')
      .then((response) => {
        if (response.status === 202) {
          updateStatusOfSelectedPublishedQuestionnaire(AnswerQuestionnaireStatus.Processing, appId)
          Message.success(intl.$t({ id: 'action_processing_questionnaire' }), 5)
          markTaskCompletionAsStarted(clientId, appId)
          addIdOfAppSendingRequest(clientId, appId)
        }
      })
      .catch((e: Error | AxiosError) => {
        LoggerHelper(e)
        if (axios.isAxiosError(e) && e.response && e.response.status === 403) {
          const responseErrorObject = e.response.data
          const errorMsg = intl.$t({ id: `api_DMS_${responseErrorObject.DMS[0].errorCode}` })
          Message.error(errorMsg, 10)
        } else {
          Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }), 5)
        }
        processingFinished(clientId, appId)
        updateStatusOfSelectedPublishedQuestionnaire(AnswerQuestionnaireStatus.Ready, appId)
        setIsSavingAndGenerating(false)
        removeIdOfAppWhenRequestSent(clientId, appId)
      })
      .finally(() => {
        setIsCurrentQuestionnaireProcessed(false)
      })
      .finally(() => {
        setIsCurrentQuestionnaireProcessed(false)
      })
  }

  const handleViewResults = () => {
    switch (applicationName) {
      case Apps.twinfield:
        navigate(`../clients/${clientId}/documents`)
        break

      case Apps.createPortal:
      case Apps.createProject:
        navigate(`../clients/${clientId}/portal_and_projects`)
        break

      default:
        break
    }
  }

  return (
    <>
      {isDirty && (
        <span className={`${styles.unsavedChanges} flex alignItems`}>
          <Icon type='Error' /> {translate('user_message_questionnaire_unsaved_changes')}
        </span>
      )}

      <Button
        onClick={onSaveDraft}
        disabled={
          !isDirty ||
          isSavingAndGenerating ||
          isSaving ||
          publishedQuestionnaireStatus === AnswerQuestionnaireStatus.Completed ||
          (applicationName === Apps.createProject && currentProjectCompletedInstance === AnswerQuestionnaireStatus.Completed)
        }
        loading={isSaving}
        type={publishedQuestionnaireStatus === AnswerQuestionnaireStatus.Completed ? 'default' : 'primary'}
        className='mr-2'
      >
        {translate('button_save')}
      </Button>
      <Button
        type='default'
        disabled={questionnaireHasErrors || isSaving || isSavingAndGenerating || isArchived}
        onClick={onSaveAndComplete}
        loading={isSavingAndGenerating}
        className={clsx(
          isDirty ||
            (applicationName !== Apps.createProject && publishedQuestionnaireStatus !== AnswerQuestionnaireStatus.Completed) ||
            (applicationName === Apps.createProject &&
              currentProjectCompletedInstance !== AnswerQuestionnaireStatus.Completed &&
              publishedQuestionnaireStatus !== AnswerQuestionnaireStatus.Completed)
            ? 'visible'
            : 'hidden'
        )}
      >
        {translate(`button_complete_${applicationName}`)}
      </Button>
      <Button
        type='primary'
        loading={isSaving}
        className={clsx(
          publishedQuestionnaireStatus === AnswerQuestionnaireStatus.Completed ||
            (applicationName === Apps.createProject && currentProjectCompletedInstance === AnswerQuestionnaireStatus.Completed)
            ? 'visible'
            : 'hidden'
        )}
        onClick={handleViewResults}
      >
        {translate('view_results')}
      </Button>
      <Loader open={isSaving} text={intl.$t({ id: 'action_saving' })} />
      <Loader open={isCurrentQuestionnaireProcessed} text={intl.$t({ id: 'action_prepare' })} />
    </>
  )
}

export default memo(SaveAndComplete)
