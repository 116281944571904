export type FilterItem = {
  id: string
  field: SelectedFilter
  operator: Operators
  value: string
  hasErrors: boolean
}

export enum Operators {
  Equal = 'eq',
  NotEqual = 'ne',
  Contains = 'contains',
  GreaterThan = 'gt',
  GreaterOrEqual = 'ge',
  LowerThan = 'lt',
  LowerOrEqual = 'le',
  StartsWith = 'startswith'
}

export type SelectedFilter = {
  fieldName: string
  type: 'string' | 'number' | 'boolean'
}

export default function toQueryString(filters: FilterItem[]): string {
  const noEmptyStrings: FilterItem[] = filters.filter((filter) => filter.value !== '')

  const filteredStrings = noEmptyStrings.map((filter) => {
    const value = filter.field.type === 'string' ? `'${filter.value}'` : filter.value

    if (filter.operator === Operators.Contains) {
      return `contains(${filter.field.fieldName}, ${value})`
    }

    if (filter.operator === Operators.StartsWith) {
      return `startswith(${filter.field.fieldName}, ${value})`
    }

    return `${filter.field.fieldName} ${filter.operator} ${value}`
  })

  const queryString = filteredStrings.join(' and ')
  return queryString
}
