import translate from 'i18n/translate'
import AddNewModal from 'features/questionnaires/components/AddNewModal/AddNewModal'
import QuestionnairesList from 'features/questionnaires/components/QuestionnairesList/QuestionnairesList'
import ArchivedShowSwitch from 'features/questionnaires/components/ArchivedHideSwitch/ArchivedShowSwitch'
import clsx from 'clsx'
import { useState } from 'react'
import styles from './Questionnaires.module.scss'

function Questionnaires() {
  const [showArchived, setShowArchived] = useState<boolean>(false)

  return (
    <div className='pagePadding'>
      <h2 className='pageTitle'>{translate('questionnaires')}</h2>
      <div className='card cardTable'>
        <div className={clsx(styles.controlsWrapper, 'flex', 'alignItems')}>
          <AddNewModal />
          <ArchivedShowSwitch onChange={(value: boolean) => setShowArchived(value)} />
        </div>
        <QuestionnairesList showArchived={showArchived} />
      </div>
    </div>
  )
}

export default Questionnaires
