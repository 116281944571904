import { useIntl } from 'react-intl'

function getSorterTooltipText(sortDirection: string | null) {
  const intl = useIntl()
  let tooltipText = ''
  switch (sortDirection) {
    case 'ascend':
      tooltipText = intl.$t({ id: 'sorting_direction_descending' })
      break

    case 'descend':
      tooltipText = intl.$t({ id: 'sorting_direction_cancel' })
      break

    default:
      tooltipText = intl.$t({ id: 'sorting_direction_ascending' })
      break
  }

  return tooltipText
}

export default getSorterTooltipText
