import { Button } from '@bdo/kitchensink'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import ErrorIcon from '../../assets/images/404.svg'
import styles from './ErrorPage.module.scss'
import translate from '../../i18n/translate'

type Props = {
  status: number | undefined
}

function ErrorPage(props: Readonly<Props>) {
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState<string>()
  const intl = useIntl()
  const { status } = props

  useEffect(() => {
    switch (status) {
      case 401: {
        setErrorMessage(intl.$t({ id: 'message_error_not_authorized' }))
        break
      }
      case 404: {
        setErrorMessage(intl.$t({ id: 'message_error_page_not_found' }))
        break
      }
      default: {
        setErrorMessage(intl.$t({ id: 'message_error_sth_went_wrong' }))
        break
      }
    }
  }, [])

  const goBack = () => {
    navigate(-1)
  }

  return (
    <div className={clsx(styles.errorInfoWrapper, 'flex')}>
      <img src={ErrorIcon} alt='404-page-icon' />
      <h3>{errorMessage}</h3>
      <Button onClick={goBack} type='primary'>
        {translate('button_goBack')}{' '}
      </Button>
    </div>
  )
}

export default ErrorPage
