import { GroupContentViewModel } from 'features/questionnaire/components/_models/GroupContentViewModel'
import GroupTab from 'features/questionnaire/components/GroupTab/GroupTab'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useQuestionnaireGroups, useQuestionnaireStore } from 'features/questionnaire/questionaryZustandStore'
import clsx from 'clsx'
import AddGroupModal from '../AddGroupModal/AddGroupModal'
import styles from './GroupTabs.module.scss'

type Props = {
  isLoading: boolean
}

function GroupTabs(props: Readonly<Props>) {
  const { isLoading } = props
  const isReadOnly = useQuestionnaireStore((state) => state.isReadOnly)
  const groups = useQuestionnaireGroups()
  const isPublishedQuestionnaire = useQuestionnaireStore((state) => state.isPublishedQuestionnaire)

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={styles.wrapper}>
        {!isReadOnly && !isLoading && !isPublishedQuestionnaire && <AddGroupModal buttonType='default' />}
        <div className={clsx(!isPublishedQuestionnaire && !isReadOnly ? styles.groupsWrapper : styles.groupsWrapperReadOnly)}>
          {groups?.map((g: GroupContentViewModel, idx) => (
            <GroupTab idx={idx} groupKey={g.key} key={g.key} />
          ))}
        </div>
      </div>
    </DndProvider>
  )
}

export default GroupTabs
