import clsx from 'clsx'
import translate from 'i18n/translate'
import { QuestionTypes } from 'services/Questionnaire/questionnaire.models'
import { useEffect, useState } from 'react'
import styles from './SharedQuestionPreview.module.scss'
import { QuestionViewModel } from '../_models/QuestionViewModel'

type Props = {
  question: QuestionViewModel
  type: string
  questionLevel: number
}

function QuestionPanel(props: Readonly<Props>) {
  const { question, type, questionLevel } = props

  const [level, setLevel] = useState<number>(0)

  useEffect(() => {
    setLevel(questionLevel)
  })

  return (
    <>
      <div
        className={clsx(
          styles.questionPreview,
          type === 'sharedQuestion' ? styles.sharedSecondLevel : styles.template,
          level === 2 && styles.level2,
          level === 3 && styles.level3,
          level === 4 && styles.level4,
          'flex',
          'alignItems'
        )}
      >
        <h5 className={clsx('flex', 'alignItems')}>{question.text}</h5>
        <div className={clsx('flex', styles.infoWrapper)}>
          <span>{translate(`form_type_${question.type}`.toLowerCase())}</span>
          <span>{question.isMandatory && <span>{translate('form_mandatory_field')}</span>}</span>
        </div>
      </div>
      {question.type === QuestionTypes.Multiplied &&
        question.questionsTemplate.map((qt1) => <QuestionPanel question={qt1} key={qt1.key} type='template' questionLevel={level + 1} />)}
    </>
  )
}

export default QuestionPanel
