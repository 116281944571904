import { Message } from '@bdo/kitchensink'
import axios, { AxiosError } from 'axios'
import { IntlShape } from 'react-intl'
import { ErrorModel } from 'services/Questionnaire/questionnaire.models'

export default function getErrorMessage(intl: IntlShape, e: AxiosError | Error, responseStatus: number, statusErrorMessage: string) {
  if (axios.isAxiosError(e) && e.response && e.response.status === responseStatus) {
    const errorData = e.response.data
    const values: ErrorModel[][] = Object.values(errorData)
    const errorMsg = intl.$t({ id: `${statusErrorMessage}${values[0][0].errorCode}` })
    Message.warning(errorMsg, 5)
  } else {
    Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
  }
}
