/* eslint-disable camelcase */
import { LOCALES } from '../locales'

export default {
  [LOCALES.ENGLISH]: {
    account_number: 'Account number',
    account_type: 'Type',
    account_type_info: 'Account type',
    account_description_en: 'Standard account description EN',
    account_description_nl: 'Standard account description NL',
    account_isActive: 'Always active',
    account_add: 'Add account',
    account_details: 'Account details',
    account_condition: 'Condition',
    account_description: 'Description',
    account_customer_description: 'Customer description',
    account_scheme: 'Account scheme',
    actions: 'Actions',
    action_saving: 'Saving...',
    action_publishing: 'Publishing...',
    action_processing_questionnaire: 'Processing questionnaire...',
    action_exporting: 'Exporting ...',
    action_importing: 'Importing ...',
    action_loading: 'Loading...',
    action_prepare: 'Sending request...',
    action_loading_answers: 'Loading answers...',
    action_downloading: 'Downloading file...',
    action_duplicating: 'Duplicating questionnaire...',
    app_name: 'Onboarding tool',
    app_version: 'Version',
    app_welcome_message: 'Welcome in Onboarding Tool!',
    app_twinfield_general_ledger: 'Twinfield General Ledger',
    application: 'Application',
    application_: 'Application name',
    application_Twinfield: 'Twinfield',
    application_Portal: 'Create portal',
    application_Project: 'Create project',
    button_add: 'Add',
    button_archive: 'Archive',
    button_browse: 'Browse',
    button_cancel: 'Cancel',
    button_clear: 'Clear',
    button_close: 'Close',
    button_create: 'Create',
    button_combine: 'Combine',
    button_confirm: 'Confirm',
    button_delete: 'Delete',
    button_duplicate: 'Duplicate',
    button_done: 'Done',
    button_edit: 'Edit',
    button_view_answers: 'View answers',
    button_goBack: 'Go back to previous page',
    button_save: 'Save',
    button_signIn: 'Sign in',
    button_publish: 'Publish',
    button_apply_filters: 'Apply filters',
    button_clear_close: 'Clear and close',
    button_save_as_draft: 'Save as draft',
    button_complete_Twinfield: 'Save and export',
    button_complete_Portal: 'Save and create portal',
    button_complete_Project: 'Save and create project',
    button_clear_condition: 'Clear condition',
    button_clear_description: 'Clear description',
    button_reload_the_page: 'Reload page',
    description_templates: 'Client description templates',
    description_template: 'Client description template',
    description_template_add: 'Add template',
    description_template_edit: 'Edit template',
    description_template_delete: 'Delete template?',
    description_template_select: 'Select description template',
    download: 'Download',
    empty_page: 'Nothing to display',
    empty_table: 'No data',
    filter: 'Filter',
    filter_add: 'Add filter',
    filter_condition: 'Condition',
    filter_value: 'Value',
    filter_select_placeholder: 'Select filter',
    filter_condition_placeholder: 'Select condition',
    filter_value_placeholder: 'Enter value',
    filter_value_yes: 'Yes',
    filter_value_no: 'No',
    forms_input_placeholder: 'Enter text',
    forms_required_field: 'This field is required',
    forms_too_many_characters: 'The answer contains too many characters',
    forms_placeholder_no_characters: 'Enter text up to {value} characters',
    forms_placeholder_account_number: 'Enter account number of 7 digits',
    form_placeholder_max_no_characters: 'Enter max number of characters',
    form_placeholder_no_brackets: 'Enter template name without square brackets',
    form_placeholder_template_name: 'Enter template name',
    form_placeholder_number: 'Enter number',
    form_placeholder_date: 'Enter date',
    form_field_type: 'Field type',
    form_max_no_characters: 'Max number of characters',
    form_select: 'Select option',
    form_mandatory_field: 'Mandatory field',
    form_type_text: 'Text field',
    form_type_number: 'Number field',
    form_type_date: 'Date field',
    form_type_dropdown: 'Dropdown field',
    form_type_radio: 'Radiobutton field',
    form_type_checkbox: 'Checkbox field',
    form_type_multiplied: 'Questions generator',
    form_multiplication_number: 'Number of multiplied questions',
    form_generated_questions_template: 'Generated questions template',
    form_set_default_option: 'Set default option',
    form_set_none_option: 'Add ”None” option',
    form_set_none_option_hint: 'If checked, it will add "None" option. This option will uncheck all other options when user answers the questionnaire',
    general_ui_page: 'page',
    general_ui_back_link: 'Go back to:',
    general_ui_draft: 'Concept',
    general_ui_status: 'Status',
    general_edited_by: 'Last edited by:',
    general_completed_by: 'Completed by:',
    loading: 'Loading...',
    import: 'Import',
    import_drag_drop_files_here: 'Drag and drop files here',
    import_supported_files: 'Supported files: {value}',
    import_accounts: 'Import accounts',
    import_templates: 'Import templates',
    client_naturalPerson: 'Natural Person',
    client_company: 'Company',
    client: 'Client',
    clients: 'Clients',
    client_relation_number: 'Relation number',
    client_details: 'Client details',
    client_name: 'Name',
    client_address: 'Address',
    client_postcode: 'Postcode',
    client_country: 'Country',
    client_legal_form: 'Legal form',
    client_sbi_main_activity: 'SBI main activity',
    client_description_templates: 'Client description templates',
    column_one: 'One column',
    column_two: 'Two columns',
    column_name: 'Column name',
    column_name_edit: 'Edit column name',
    dashboard: 'Dashboard',
    dashboard_superuser: 'Dashboard SU',
    documents: 'Documents',
    edit_name: 'Edit name',
    export: 'Export',
    start_import: 'Start import',
    import_success: 'Success! Files were successfully imported',
    import_failed: 'Sorry, The files were not imported. Please check the document and try again',
    processing: 'Processing',
    general_ledger: 'General Ledger',
    search_placeholder_clients: 'Search by Client name or Relation number',
    search_placeholder_documents: 'Search by File name or questionnaire name',
    service_outsourcing: 'Outsourcing',
    service_global_portal: 'Global Portal',
    services_and_apps: 'Services & apps',
    service: 'Service',
    status_InProgress: 'In progress',
    status_NotStarted: 'Not started',
    status_NotAvailable: 'Not available',
    status_Ready: 'Ready',
    status_Completed: 'Completed',
    status_Processing: 'Processing...',
    status_not_available_message: 'There is no published questionnaire',
    status_in_progress_message: 'There is some information missing in the questionnaire',
    status_ready_Twinfield_message: 'The questionnaire is ready to be exported',
    status_ready_Portal_message: 'The questionnaire is ready and portal can be created',
    status_ready_Project_message: 'The questionnaire is ready and project can be created',
    status_completed_Twinfield_message: 'Questionnaire is filled and files are exported',
    status_completed_Portal_message: 'Questionnaire is filled and portal is created',
    status_completed_Project_message: 'Questionnaire is filled and project is created',
    status_processing_message: 'Questionnaire is processed...',
    table_column_modification_date: 'Modification date',
    table_column_modified_by: 'Modified by',
    table_column_creation_date: 'Completion date',
    table_column_created_by: 'Completed by',
    table_column_status: 'Status',
    table_column_type: 'Type',
    table_column_name: 'Name',
    table_column_file_name: 'File name',
    table_column_export_time: 'Export time',
    table_exports_switch: 'Show previous exports',
    table_column_questionnaire: 'Questionnaire name',
    nav_back: 'Previous',
    nav_next: 'Next',
    operator_equal: 'is equal to',
    operator_not_equal: 'is not equal to',
    operator_lower_than: 'is lower than',
    operator_lower_equal: 'is lower or equal to',
    operator_greater: 'is greater than',
    operator_greater_equal: 'is greater or equal to',
    operator_contains: 'contains',
    operator_startswith: 'starts with',
    portal_and_projects: 'Portal and projects',
    portal_name: 'Portal name',
    project_name: 'Project name',
    projects_view_all: 'View all completed projects',
    portal_created: 'Portal created:',
    project_last_created: 'Last project completed:',
    portal_last_created: 'Portal name:',
    preview_questionnaire: 'Preview questionnaire',
    questionnaires: 'Questionnaires',
    questionnaire: 'Questionnaire',
    questionnaire_select: 'Select questionnaire',
    questionnaire_create: 'Create questionnaire',
    questionnaire_duplicate: 'Duplicate questionnaire',
    questionnaire_name: 'Questionnaire name',
    questionnaire_new_name: 'New questionnaire name',
    questionnaire_name_edit: 'Edit questionnaire name',
    questionnaire_draft: 'Draft',
    questionnaire_published: 'Published',
    questionnaire_archived: 'Archived',
    questionnaire_archive: 'Archive questionnaire',
    questionnaire_show_archived: 'Show archived questionnaires',
    question_group: 'Question group',
    question_group_select: 'Select question group',
    question_group_name: 'Question group name',
    question_group_add_new: 'Add question group',
    question_group_edit_name: 'Edit question group name',
    question_group_delete: 'Delete group',
    question_options: 'Options',
    question_more_options: 'More options',
    question_linked_questions: 'Add linked questions',
    linked_questions: 'Linked questions',
    question_add_new: 'Add question',
    question_template_add_new: 'Add template question',
    question: 'Question',
    question_select: 'Select question',
    question_text: 'Question text',
    question_hint: 'Question hint',
    question_child: 'Child question',
    no_questions: 'No questions',
    sorting_direction_ascending: 'Click to sort ascending',
    sorting_direction_descending: 'Click to sort descending',
    sorting_direction_cancel: 'Click to cancel sorting',
    user_message_when_delete_question: 'Delete question?',
    user_message_when_delete_question_message: 'Are you sure you want to delete the question? This cannot be undone when questionnaire is saved',
    user_message_when_delete_option_message: 'Are you sure you want to delete the option? This cannot be undone when questionnaire is saved',
    user_message_when_factor_decreasing_message:
      'There is a range or generated questions connected to account descriptions/conditions. By decreasing the number, <b>{value} account(s)</b> will be affected.',
    user_message_when_delete_question_condition_description_warning_message:
      'This question is connected to account description/condition. Removing question will remove description/condition in <b>{connectionsCount} account(s)</b>.',
    user_message_when_change_type_question_condition_description_warning_message:
      'This question is connected to account description/condition. Changing question type will remove description/condition in <b>{connectionsCount} account(s)</b>.',
    user_message_when_delete_group_condition_description_warning_message:
      'This group contains questions connected to account description/condition. Removing group will remove description/condition in <b>{connectionsCount} account(s)</b>.',
    user_message_when_delete_option_condition_warning_message:
      'This option is connected to account condition. Removing option will remove condition in <b>{connectionsCount} account(s)</b>.',
    user_message_when_publish_question: 'Publish questionnaire?',
    user_message_when_publish_message:
      'There is already published version of this questionnaire. If new version is published, the previous one will be archived. This cannot be undone when confirmed',
    user_message_when_delete_group: 'Delete question group?',
    user_message_when_delete_group_message: 'Are you sure you want to delete the group? This cannot be undone when questionnaire is saved',
    user_message_combine_columns_question: 'Combine all questions in one column?',
    user_message_combine_columns_description:
      'When confirmed, you will receive one column layout and all your questions will be combined. This cannot be undone when questionnaire is saved',
    user_message_unsaved_changes: 'Changes will not be saved',
    user_message_questionnaire_unsaved_changes: 'Unsaved changes',
    user_message_questionnaire_saved: 'Success! The questionnaire was saved',
    user_message_questionnaire_save_error: 'Sorry, the questionnaire could not be saved due to error. Please try again',
    user_message_account_saved: 'Success! The account was saved',
    user_message_account_save_error: 'Sorry, the account could not be saved due to error. Please try again',
    user_message_account_deleted: 'Success! The account was deleted',
    user_message_account_deleted_error: 'Sorry, the account could not be deleted due to error. Please try again',
    user_message_change_question_type: 'Change question type?',
    user_message_change_question_type_info:
      'This question has options and may have linked questions. When confirmed, all questions will be deleted and lost when questionnaire is saved',
    user_message_remove_question_with_child_questions: 'This question has linked questions. When confirmed, all questions will be deleted. ',
    user_message_delete_options_question: 'Delete option?',
    user_message_factor_number_decreasing_question: 'Decrease number of multiplied questions?',
    user_message_delete_options_message: 'There are linked questions connected to this option. When deleted, the connection will also be removed. ',
    user_message_change_type_from_question_generator:
      'This question contains question templates. When confirmed all added questions will be deleted and lost when questionnaire is saved. ',
    user_message_remove_question_generator: 'This question contains question templates. When confirmed all added questions will be deleted. ',
    user_message_missing_info: 'Missing information',
    user_message_when_archive_questionnaire_question: 'Archive questionnaire?',
    user_message_when_archive_questionnaire_message: 'Are you sure you want to archive questionnaire <b>{name}</b>? This cannot be undone after save',
    user_message_when_archived_questionnaire_opened: 'Archived questionnaires are read only',
    user_message_read_only_questionnaire: 'This is read only questionnaire',
    user_message_when_published_questionnaire_opened: 'Published questionnaires can be edited to some extend. Saving published questionnaire will affect all client questionnaires',
    user_message_when_delete_account_question: 'Delete account?',
    user_message_when_delete_account_message: 'Are you sure you want to delete account <b>{number}</b>? This cannot be undone when confirmed',
    user_message_7_digits: 'Enter 7 positive digits',
    user_message_integer: 'Invalid value',
    user_message_integer_string: 'No special characters are allowed',
    user_message_number_field_range: 'Enter numbers from range 1 to {value}',
    user_message_field_empty: 'Field cannot be empty',
    user_message_set_account_active_condition: 'Set account to active if below options are selected:',
    user_message_set_account_active_condition_with_generator: 'Set account to active if number from below range is selected:',
    user_message_set_account_active_condition_with_generator_options: 'Available options:',
    user_message_condition_saved: 'Condition has been saved',
    user_message_condition_removed: 'Condition has been removed',
    user_message_description_saved: 'Description has been saved',
    user_message_description_removed: 'Description has been removed',
    user_message_when_delete_template: 'Are you sure you want to delete template <b>[{name}]</b>? This cannot be undone when confirmed',
    user_message_template_deleted: 'Success! The template was deleted',
    user_message_template_deleted_error: 'Sorry, the template could not be deleted due to error. Please try again',
    user_message_when_add_template: 'Success! Template was added',
    user_message_when_update_template: 'Success! Template was updated',
    user_message_filters_updated: 'Success! Accounts list was updated',
    user_message_filters_no_results: 'Sorry, no accounts were found. Please try again',
    user_message_documents_list_updated: 'Documents list was updated',
    user_message_documents_list_no_results: 'Sorry, no documents were found. Please try again',
    user_message_login_error: 'Login failed',
    user_message_at_least_one_template_question: 'Add at least one template question',
    user_message_files_exported: 'Files were successfully exported',
    user_message_files_exported_and_saved: 'Files were successfully exported.',
    user_message_file_downloaded: 'File was successfully downloaded.',
    user_message_file_download_failed: 'File could not be downloaded.',
    user_message_client_search: 'Success! Clients list was updated',
    user_message_client_search_no_results: 'Sorry, no clients were found. Please try again',
    user_message_new_questionnaire_published: 'New questionnaire was published. Answers from previous version, will be imported to the new questionnaire',
    user_message_new_questionnaire_published_no_relations: 'New version of questionnaire was published. There are no answers, to import to the new questionnaire',
    user_message_prev_answered_questionnaire: 'Previously answered questionnaire',
    user_message_answers_imported: 'Success! Answers were imported',
    user_message_answers_imported_error: 'Sorry, the answers could not be imported',
    user_info_child_question_in_account_settings: 'If this field is empty, the condition settings will be applied to parent question',
    view_results: 'View results',
    message_error_page_not_found: 'Page not found',
    message_error_not_authorized: 'Not authorized',
    message_error_sth_went_wrong: 'Something went wrong :(',
    message_error_group_name_exists: 'Group name already exists in this questionnaire',
    message_error_questionnaire_name_exists: 'Questionnaire name already exists',
    message_error_page_content_error: 'Missing data, please contact administrator',
    api_name_NotEmptyValidator: 'Name cannot be empty',
    api_name_UniqueValueConflict: 'Name already exists',
    api_account_number_UniqueValueConflict: 'Account already exists',
    api_template_UniqueValueConflict: 'Template name already exists',
    api_DMS_UnauthorizedUser: 'You are not authorized to access this client documents. Please contact Klanthub to request that access',
    api_DMS_MissingFolderStructure: 'Folder structure in sharepoint does not exist for given client',
    api_template_import_NotAllowedCharacterInName: 'Not allowed characters found. Please use only letters, numbers and spaces. Please correct this field: {value}',
    api_template_import_InvalidImportFile_Ledgers: 'Cannot load file, please verify if Excel worksheet name is "Ledgers"',
    api_template_import_InvalidImportFile_Customerdescriptions: 'Cannot load file, please verify if Excel worksheet name is "Customerdescriptions"',
    api_GlobalPortal_ConfigurationService_WrongNumberOfOrganisations: 'Projects cannot be created, because there is no portal yet. Please create portal first.',
    api_portal_does_not_exist_ConfigurationService_WrongNumberOfOrganisations: 'No portal was found for that client',
    api_projects_do_not_exist_ConfigurationService_WrongNumberOfOrganisations: 'No projects were found for that client',
    portal_not_created: 'Projects cannot be created, because there is no portal yet. Please create portal first.',
    portal_processed: 'It seems that the portal is not yet ready, so projects cannot be created. Please come back in few minutes and reload the page',
    signalR_ExportFilesFinished_title: 'Files were exported to DMS',
    signalR_ExportFilesFinished_message: 'Files were successfully exported to DMS for client with relation number of {clientId}',
    signalR_ExportFilesFailed_title: 'Problem with exporting to DMS',
    signalR_ExportFilesFailed_message: 'Something went wrong',
    signalR_QuestionnairePublished_title: 'New questionnaire has just been published',
    signalR_QuestionnairePublished_message: 'Current questionnaire will not be accessible anymore after leaving this page. Please save your answers, else they will be lost',
    signalR_PortalCreated_title: 'Create portal',
    signalR_PortalNotCreated_title: 'Portal was not created',
    signalR_PortalCreated_message: 'Portal was successfully created for client with relation number of {clientId}',
    signalR_ProjectCreated_title: 'Create project',
    signalR_ProjectNotCreated_title: 'Project was not created',
    signalR_ProjectCreated_message: 'Project was successfully created for client with relation number of {clientId}',
    signalR_GlobalPortal_2504: 'Portal or project name is not unique or is invalid. Please change the name and try again',
    signalR_GlobalPortal_2505: 'Portal name contains not allowed special character. The only characters that can be used are ".", "@", "&", "(", ")"',
    signalR_GlobalPortal_2503: 'Portal name or MemberFirmID is empty. Please provide the information and try again',
    signalR_GlobalPortal_2507: 'Client Site owner field cannot be empty.',
    signalR_GlobalPortal_3065: 'E-mail address given for Client Site owner is not authorized',
    signalR_GlobalPortal_3001: 'Main BDO Contact e-mail is invalid or is not provided',
    signalR_GlobalPortal_3011: 'Project already exists',
    signalR_GlobalPortal_3016: 'Portal or project owner e-mail is not registered as admin',
    signalR_default_error_message: 'Something went wrong. Please try again'
  }
}
