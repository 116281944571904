/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Form, Icon, Select } from '@bdo/kitchensink'
import { Modal } from 'antd'
import clsx from 'clsx'
import { useParentQuestion, useQuestion, useQuestionnaireStore, useFormFieldIsReadOnly } from 'features/questionnaire/questionaryZustandStore'
import translate from 'i18n/translate'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import IconButton from 'ui-components/IconButton/IconButton'
import { OptionViewModel } from '../_models/OptionViewModel'
import styles from './QuestionClosed.module.scss'
import { useDragDrop } from '../../../../hooks/useDragDrop'
import ConnectionWarningMessage from '../ConnectionWarningMessage/ConnectionWarningMessage'

type Props = {
  questionKey: string
  level: number
  idx: number
}

export default function QuestionClosed(props: Readonly<Props>) {
  const { questionKey, level, idx } = props
  const { isReadOnly, updateQuestion, removeQuestion, swapQuestions, updateSharedQuestionsOptions } = useQuestionnaireStore()
  const question = useQuestion(questionKey)
  const formFieldIsReadOnly = useFormFieldIsReadOnly()
  const ref = useRef<HTMLDivElement>(null)
  const [form] = Form.useForm()
  const intl = useIntl()
  useDragDrop(ref, swapQuestions, `qitem${level}`, idx, question?.key, isReadOnly)
  const [isRemoveQuestionModalOpen, setIsRemoveQuestionModalOpen] = useState<boolean>(false)
  const [deleteQuestionBtnIsDisabled, setDeleteQuestionBtnIsDisabled] = useState<boolean>(true)
  const hasSharedQuestions: boolean = question?.sharedQuestions.length !== 0
  const { Option } = Select
  const parentQuestion = useParentQuestion(questionKey)
  const isPublishedQuestionnaire = useQuestionnaireStore((state) => state.isPublishedQuestionnaire)

  useEffect(() => {
    if (!parentQuestion) {
      return
    }

    const fields = [
      {
        name: 'optionSelector',
        value: parentQuestion?.sharedQuestionsOptions?.filter((q) => q.sharedQuestionId === question?.key).map((x) => x.optionId) || []
      }
    ]
    form.setFields(fields)
    updateQuestion(parentQuestion)

    if (question?.isSharedQuestion) {
      form.validateFields(['optionSelector'])
    }
  }, [parentQuestion?.sharedQuestionsOptions])

  const onRemoveQuestion = (questionKeyToRemove: string | undefined) => {
    removeQuestion(questionKeyToRemove)
  }

  const setIsQuestionOpen = (isOpen: boolean) => {
    if (!question) {
      return
    }
    const updatedQuestion = {
      ...question,
      isOpen
    }

    updateQuestion(updatedQuestion)
  }

  const setLinkedQuestionToOption = (optionsId: string[]) => {
    if (parentQuestion) {
      updateSharedQuestionsOptions(parentQuestion.key, question?.key, optionsId)
    }
  }

  return (
    <Form name='question' form={form} layout='vertical'>
      <div
        className={clsx(styles.closedQuestionWrapper, 'card', 'mb-3', parentQuestion?.isQuestionTemplate ? 'childTemplateRef' : 'questionRef')}
        data-testid='closed-question'
        ref={question?.isSharedQuestion ? null : ref}
      >
        <div className={clsx(styles.wrapper, 'flex', 'alignItems')}>
          <div className={clsx(styles.wrapTitle, 'flex', 'alignItems')}>
            {!question?.isSharedQuestion && <Icon type='Waffle' className={clsx(styles.dragIcon, isReadOnly && 'hidden')} />}
            <button type='button' className={styles.titleWrapper} onClick={() => setIsQuestionOpen(true)}>
              <h4>{question?.text?.trim()}</h4>
              <div className={clsx('flex', 'alignItems', styles.infoFont)}>
                <span>{translate(`form_type_${question?.type}`.toLowerCase())}</span>
                <span>
                  {hasSharedQuestions && !question?.isQuestionTemplate && (
                    <span className={clsx(styles.linkedInfo, 'flex', 'alignItems')}>
                      <Icon type='Link' />
                      <span>{translate('linked_questions')}</span>
                    </span>
                  )}
                </span>
              </div>
            </button>
          </div>

          <div className={clsx(styles.wrapItems, 'flex', 'alignItems')}>
            <div className={clsx(styles.optionsSelector, 'optionSelector')}>
              {question?.isSharedQuestion && (
                <Form.Item
                  initialValue={parentQuestion?.sharedQuestionsOptions.filter((sqo) => sqo.sharedQuestionId === question.id).map((sqo) => sqo.optionId)}
                  name='optionSelector'
                  rules={[
                    {
                      required: true,
                      message: translate('forms_required_field')
                    }
                  ]}
                >
                  <Select
                    onChange={setLinkedQuestionToOption}
                    placeholder={intl.$t({ id: 'form_select' })}
                    disabled={isReadOnly || formFieldIsReadOnly || isPublishedQuestionnaire}
                    mode='multiple'
                    allowClear
                    style={{ width: '260px' }}
                  >
                    {parentQuestion?.options.map((option: OptionViewModel) => (
                      <Option key={option.key} value={option.key}>
                        {option.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </div>

            <div className={clsx(styles.iconsWrapper, 'flex', 'alignItems')}>
              <div className={question?.isMandatory ? 'visible' : 'hidden'}>{translate('form_mandatory_field')}</div>
              {question?.hasErrors && <Icon type='Error' className={clsx(question.hasErrors ? '' : 'transparent', styles.errorIcon, 'redText')} />}
              <IconButton
                type='Delete'
                onClick={() => setIsRemoveQuestionModalOpen(true)}
                disabled={formFieldIsReadOnly}
                customStyles={clsx('ml-2', isReadOnly || isPublishedQuestionnaire ? 'hidden' : '')}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        style={{ whiteSpace: 'pre-line' }}
        title={translate('user_message_when_delete_question')}
        open={isRemoveQuestionModalOpen}
        onOk={() => onRemoveQuestion(question?.key)}
        okButtonProps={{ disabled: deleteQuestionBtnIsDisabled, loading: deleteQuestionBtnIsDisabled }}
        onCancel={() => setIsRemoveQuestionModalOpen(false)}
        okText={translate('button_delete')}
        cancelText={translate('button_cancel')}
      >
        <ConnectionWarningMessage
          filter={{ questionId: [questionKey] }}
          onConnectionsCheckFinished={() => setDeleteQuestionBtnIsDisabled(false)}
          warningMessageId='user_message_when_delete_question_condition_description_warning_message'
        />
        {question && question.questionsTemplate.length > 0 && translate('user_message_remove_question_generator')}
        {question && question.sharedQuestions.length > 0 && translate('user_message_remove_question_with_child_questions')}
        {translate('user_message_when_delete_question_message')}
      </Modal>
    </Form>
  )
}
