import { useState } from 'react'
import translate from 'i18n/translate'
import { Modal, Input } from 'antd'
import axios, { AxiosError } from 'axios'
import { ErrorModel } from 'services/Questionnaire/questionnaire.models'
import { Form, Button, Message } from '@bdo/kitchensink'
import { useIntl } from 'react-intl'
import { useTemplatesStore } from 'features/generalLedger/TemplatesStore/TemplatesStore'
import { addTemplate as addNewTemplate, Template, getTemplates } from 'services/templates.service'
import Loader from 'ui-components/Loader/Loader'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'

function AddTemplateModal() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isFilled, setIsFilled] = useState(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [form] = Form.useForm()
  const intl = useIntl()
  const { page, pageSize, setTemplates, setPagination } = useTemplatesStore()
  // eslint-disable-next-line no-useless-escape
  const regexExcludingSomeSpecialChar = /^[a-zA-Z0-9 \/.-]+$/

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = (values: Template) => {
    const trimmedName = values.name.trim()
    const newTemplate = { ...values, name: trimmedName }
    setIsSaving(true)
    addNewTemplate(newTemplate)
      .then(() => {
        getTemplates(page, pageSize).then((templatesData) => {
          setTemplates(templatesData.rows)
          setPagination(page, pageSize, templatesData.total)
        })
        setIsModalOpen(false)
        form.resetFields()
        Message.success(intl.$t({ id: 'user_message_when_add_template' }), 5)
      })
      .catch(onRequestError)
      .finally(() => {
        setIsFilled(false)
        setIsSaving(false)
      })
  }

  const onRequestError = (e: Error | AxiosError) => {
    LoggerHelper(e)
    setIsModalOpen(true)
    if (axios.isAxiosError(e)) {
      if (e.response && e.response.status === 400) {
        const errors = e.response.data
        form.setFields([
          {
            name: 'name',
            touched: true,
            errors: errors.File.map((error: ErrorModel) => intl.$t({ id: `api_${'template'}_${error.errorCode}` }))
          }
        ])
      }
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    form.resetFields()
    setIsFilled(false)
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFilled(true)
    if (e.target.value === '' || regexExcludingSomeSpecialChar.exec(e.target.value) === null) {
      setIsFilled(false)
    }
  }

  return (
    <>
      <Button type='primary' onClick={showModal}>
        {translate('description_template_add')}
      </Button>
      <Modal
        title={translate('description_template_add')}
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form.submit}
        okButtonProps={{ disabled: !isFilled }}
        okText={translate('button_add')}
        cancelText={translate('button_cancel')}
      >
        <Form name='add_template' form={form} layout='vertical' onFinish={handleOk}>
          <Form.Item
            name='name'
            label={translate('description_template')}
            rules={[
              { pattern: regexExcludingSomeSpecialChar, message: translate('user_message_integer_string') },
              { required: true, message: translate('forms_required_field') },
              { whitespace: true, message: translate('user_message_field_empty') }
            ]}
          >
            <Input placeholder={intl.$t({ id: 'form_placeholder_no_brackets' })} maxLength={164} showCount onChange={handleOnChange} />
          </Form.Item>
        </Form>
      </Modal>
      <Loader open={isSaving} text={intl.$t({ id: 'action_saving' })} />
    </>
  )
}

export default AddTemplateModal
