import { Icon, Message } from '@bdo/kitchensink'
import { Tooltip } from 'antd'
import clsx from 'clsx'
import translate from 'i18n/translate'
import { useIntl } from 'react-intl'
import { downloadSingleDocument } from 'services/documents.service'
import { useState } from 'react'
import axios, { AxiosError } from 'axios'
import { ErrorModel } from 'services/Questionnaire/questionnaire.models'
import Loader from 'ui-components/Loader/Loader'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import { useDocument } from '../DocumentsStore'
import styles from './ExportSingleDocument.module.scss'

type Props = {
  documentId: number
}

function ExportSingleDocument(props: Readonly<Props>) {
  const { documentId } = props
  const currentDocument = useDocument(documentId)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const intl = useIntl()

  const onDownloadDocument = () => {
    if (!documentId) return
    setIsLoading(true)
    downloadSingleDocument(documentId)
      .then(async (response) => {
        if (!currentDocument) {
          throw new Error('File could not be downloaded')
        }
        const blob = new Blob([response])
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url

        link.setAttribute('download', `${currentDocument.name}`)
        document.body.appendChild(link)
        link.click()

        window.URL.revokeObjectURL(url)
        Message.success(intl.$t({ id: 'user_message_file_downloaded' }), 5).then()
      })
      .catch(async (e: Error | AxiosError) => {
        LoggerHelper(e)
        if (axios.isAxiosError(e) && e.response && e.response.status === 400) {
          const errorMsg = e.response.data.DMS.map((error: ErrorModel) => intl.$t({ id: `api_${'DMS'}_${error.errorCode}` }))
          Message.error(errorMsg, 5)
        } else {
          Message.error(intl.$t({ id: 'user_message_file_download_failed' }), 5)
        }
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <div className={styles.wrapper}>
      <Loader open={isLoading} text={intl.$t({ id: 'action_downloading' })} />
      <Tooltip title={translate('download')} mouseEnterDelay={0.5}>
        <button
          type='button'
          aria-label='Download'
          className={clsx(styles.downloadButton, 'iconButton', isLoading && styles.buttonDisabled)}
          onClick={onDownloadDocument}
          disabled={isLoading}
        >
          <Icon type='CloudDownload' />
        </button>
      </Tooltip>
    </div>
  )
}

export default ExportSingleDocument
