import clsx from 'clsx'
import styles from './AccountTab.module.scss'

type Props = {
  label: string
  handleAction: () => void
  isSelected: boolean
  disabled?: boolean
}

function AccountTab(props: Readonly<Props>) {
  const { label, handleAction, isSelected, disabled } = props

  const onChangeTab = () => {
    handleAction()
  }

  return (
    <button disabled={disabled} type='button' className={clsx(isSelected ? styles.tabActive : '', styles.tab, 'flex', 'alignItems')} onClick={onChangeTab}>
      <span>{label}</span>
    </button>
  )
}

export default AccountTab
