import { useState } from 'react'
import { LOCALES } from 'i18n/locales'
import clsx from 'clsx'
import styles from './LanguageSwitcher.module.scss'

type Props = {
  onLangSwitch: (langCode: string) => void
}

function LanguageSwitcher(props: Readonly<Props>) {
  const [isEnglishActive, setIsEnglishActive] = useState(true)
  const { onLangSwitch } = props

  const handleSwitch = (langCode: string) => {
    onLangSwitch(langCode)
    setIsEnglishActive((current) => !current)
  }

  return (
    <div className={styles.languageSwitcher}>
      <button onClick={() => handleSwitch(LOCALES.ENGLISH)} className={clsx(isEnglishActive ? styles.active : styles.inactive, 'textButton')} type='button'>
        EN
      </button>{' '}
      /
      <button onClick={() => handleSwitch(LOCALES.DUTCH)} className={clsx(!isEnglishActive ? styles.active : styles.inactive, 'textButton')} type='button'>
        NL
      </button>
    </div>
  )
}

export default LanguageSwitcher
