import translate from 'i18n/translate'
import React from 'react'
import clsx from 'clsx'
import styles from './TabPortalProjects.module.scss'
import ProjectsList from './ProjectsList'
import PortalList from './PortalList'

export default function TabPortalProjects() {
  return (
    <div className={styles.wrapper}>
      <h3>{translate('portal_and_projects')}</h3>
      <div className={clsx(styles.portalCard, 'card', 'mb-3')}>
        <PortalList />
      </div>

      <div className='card cardTable'>
        <ProjectsList />
      </div>
    </div>
  )
}
