import { useMsalAuthentication } from '@azure/msal-react'
import { InteractionRequiredAuthError, InteractionType, SilentRequest } from '@azure/msal-browser'
import msalInstance from 'services/PublicClientApplication.service'
import { useEffect } from 'react'
import { Message } from '@bdo/kitchensink'
import { useIntl } from 'react-intl'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import logoImage from '../../assets/images/bdo_logo_white@2x.png'
import onboardingToolLogoImage from '../../assets/images/onboardingtool_logo.png'
import styles from './Header.module.scss'
import translate from '../../i18n/translate'
import { onboardingToolApiConfig } from '../../app.config'
import HeaderButtons from '../../features/authentication/components/HeaderButtons/HeaderButtons'

function Header() {
  const intl = useIntl()
  const accounts = msalInstance.getAllAccounts()
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
  }

  const account = msalInstance.getAllAccounts()[0]
  const tokenRequest: SilentRequest = {
    scopes: onboardingToolApiConfig.scopes ?? [],
    account
  }
  const { result, error, login } = useMsalAuthentication(InteractionType.Silent, tokenRequest)

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Popup, tokenRequest)
        .then(() => {
          window.location.reload()
        })
        .catch((e) => {
          LoggerHelper(e)

          Message.error(intl.$t({ id: 'user_message_login_error' }))
          // eslint-disable-next-line no-console
          console.error('Login error', e)
        })
    }
  }, [result, error])

  return (
    <header>
      <div className='flex alignItems'>
        <div className={`${styles.logoWrapper} flex alignItems`}>
          <div className={styles.onboardingtoolLogo}>
            <img src={onboardingToolLogoImage} alt='onboarding tool logo' />
          </div>
          <img className={styles.bdoLogo} src={logoImage} alt='bdo logo' />
        </div>
        <div className={`${styles.contentWrapper} flex alignItems`}>
          <h3>{translate('app_name')}</h3>
          <div>
            <HeaderButtons />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
