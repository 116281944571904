import { Message } from '@bdo/kitchensink'
import processingQuestionnaireTimeoutInMiliseconds from 'features/clientDetails/assets/timeoutDuration'
import useQuestionnaireAnswerStore from 'features/clientDetails/components/questionAnswerZustandStore'
import useClientStore from 'features/clients/clientZustandStore'
import moment from 'moment'
import { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { processingFinished, getProcessedQuestionnairesListFromLocalStorage } from 'services/Client/clientProcessedQuestionnaireStatus.service'
import { AnswerQuestionnaireStatus } from 'services/QuestionsAnswers/QuestionsAnswers.model'

export default function TimeController() {
  const timerRef = useRef(0)
  const intl = useIntl()
  const updateReadOnly = useQuestionnaireAnswerStore((store) => store.updateReadOnly)
  const setIsSavingAndGenerating = useQuestionnaireAnswerStore((store) => store.setIsSavingAndGenerating)
  const removeIdOfAppWhenRequestSent = useClientStore((state) => state.removeIdOfAppWhenRequestSent)
  const updateStatusOfSelectedPublishedQuestionnaire = useClientStore((state) => state.updateStatusOfSelectedPublishedQuestionnaire)

  useEffect(() => {
    timerRef.current = window.setInterval(() => {
      const allRequestsInProgress = getProcessedQuestionnairesListFromLocalStorage()

      allRequestsInProgress.forEach((item) => {
        if (moment.unix(Number(item.startTime)).add(processingQuestionnaireTimeoutInMiliseconds, 'ms').unix() < moment().unix()) {
          setIsSavingAndGenerating(false)
          updateReadOnly(false)
          processingFinished(item.clientId, item.appId)
          removeIdOfAppWhenRequestSent(Number(item.clientId), item.appId)
          updateStatusOfSelectedPublishedQuestionnaire(AnswerQuestionnaireStatus.Ready, item.appId)
          Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }), 10)
        }
      })
    }, 5000)

    return () => window.clearInterval(timerRef.current)
  }, [])

  return null
}
