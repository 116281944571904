import axios, { AxiosError, AxiosHeaders, AxiosResponse, AxiosResponseHeaders } from 'axios'
import queryString from 'query-string'
import { from } from 'linq-to-typescript'
import { onboardingToolApiConfig } from '../app.config'
import { PaginatedResult } from './service.models'

export type Document = {
  id: number
  name: string
  questionnaireName: string
  modifiedDate: Date
  modifiedBy: string
  lastExported: boolean
}

const documentsUri = `${onboardingToolApiConfig.uri}/documents`

export async function getDocumentsList(clientId: number, page: number, pageSize: number, latestOnly: boolean, searchValue?: string, sorter?: string) {
  if (page < 1) {
    throw Error('Page cannot be found')
  }
  let searchString = ''
  if (searchValue) {
    const lowerSearchValue = searchValue.toLowerCase()
    searchString = `contains(tolower(Name),'${lowerSearchValue}') or contains(tolower(QuestionnaireName), '${lowerSearchValue}')`
  }
  const queryParams = { $skip: (page - 1) * pageSize, $top: pageSize, $filter: searchString, $latestOnly: latestOnly, $orderby: sorter }
  const url = `${documentsUri}/${clientId}`
  const getUri = queryString.stringifyUrl({ url, query: queryParams }, { skipEmptyString: true })
  const response = await axios.get<PaginatedResult<Document>>(getUri)

  if (!latestOnly && page === 1 && searchValue === '' && sorter === 'modifiedDate desc') {
    const latestDocsIds = from(response.data.rows)
      .groupBy((x) => x.name)
      .select((g) => g.max((d) => d.id))
      .toArray()

    response.data.rows.forEach((doc: Document) => {
      if (latestDocsIds.includes(doc.id)) {
        const document = doc
        document.lastExported = true
      } else {
        const document = doc
        document.lastExported = false
      }
    })
  }

  return response.data
}

export async function downloadSingleDocument(id: number) {
  const getUri = `${documentsUri}/${id}/content`

  try {
    const response = await axios.get(getUri, { responseType: 'arraybuffer' })
    return response.data
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const errorData = convertArrayBufferTypeToJson(e.response?.data)
      const error = createAxiosError(errorData, e)
      return Promise.reject(error)
    }

    return Promise.reject(e)
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createAxiosError(errorData: any, e: AxiosError<any, any>) {
  const headers: AxiosResponseHeaders = new AxiosHeaders()

  const myResponse: AxiosResponse = {
    data: errorData,
    status: e.response?.status ?? 0,
    statusText: e.response?.statusText ?? '',
    headers: { 'Content-Type': 'application/json' },
    config: { headers },
    request: {}
  }
  return new AxiosError(e.message, e.status?.toString(), e.config, e.request, myResponse)
}

function convertArrayBufferTypeToJson(data: Iterable<number>) {
  const uint8array = new Uint8Array(data)
  const textDecoder = new TextDecoder()
  const responseString = textDecoder.decode(uint8array)
  const jsonResponse = JSON.parse(responseString)

  return jsonResponse
}
