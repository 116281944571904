import { Button, Icon, Message } from '@bdo/kitchensink'
import { AxiosResponse } from 'axios'
import clsx from 'clsx'
import translate from 'i18n/translate'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import Loader from 'ui-components/Loader/Loader'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'

type ExportButtonProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getData: () => Promise<AxiosResponse<any, any>>
  disabled?: boolean
  exportedFileName: string
}

export default function ExportButton(props: Readonly<ExportButtonProps>) {
  const { getData, exportedFileName, disabled } = props
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)

  const handleExport = () => {
    setIsLoading(true)
    getData()
      .then((response) => {
        const blob = new Blob([response.data])
        const url = window.URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', exportedFileName)
        document.body.appendChild(link)
        link.click()

        window.URL.revokeObjectURL(url)
        Message.success(intl.$t({ id: 'user_message_files_exported' }), 5)
      })
      .catch((error) => {
        LoggerHelper(error)
        Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }), 5)
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <Button className={clsx('flex', 'alignItems', 'mr-2')} disabled={isLoading || disabled === true} onClick={handleExport} loading={isLoading}>
        <Icon type='CloudDownload' className='mr-1' />
        {translate('export')}
      </Button>
      <Loader open={isLoading} text={intl.$t({ id: 'action_exporting' })} />
    </>
  )
}
