import { useEffect, useState } from 'react'
import { Table } from '@bdo/kitchensink'
import translate from 'i18n/translate'
import TableEmptyState from 'ui-components/TableEmptyState/TableEmptyState'
import { Apps } from 'services/Questionnaire/questionnaire.models'
import { useClientId } from 'features/clients/clientZustandStore'
import moment from 'moment'
import { getPortalsInfoByClientId } from 'services/portalsAndProjects.service'
import { GlobalPortal, LastUpdate } from 'services/Client/client.models'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import { useIntl } from 'react-intl'
import { AxiosError } from 'axios'
import getErrorMessage from 'utils/getErrorMessage'
import RedirectButton from './RedirectButton'

export default function PortalList() {
  const intl = useIntl()
  const clientId = useClientId()
  const [portal, setPortal] = useState<GlobalPortal[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    fetchPortals()
  }, [])

  const fetchPortals = () => {
    if (!clientId) {
      return
    }
    setIsLoading(true)

    getPortalsInfoByClientId(clientId)
      .then((portall: GlobalPortal) => {
        setPortal([portall])
      })
      .catch((error: AxiosError | Error) => {
        LoggerHelper(error)
        getErrorMessage(intl, error, 400, 'api_portal_does_not_exist_')
      })
      .finally(() => setIsLoading(false))
  }

  const columns = [
    {
      title: translate('portal_name'),
      dataIndex: 'portalName',
      key: 'portalName',
      width: '24%'
    },
    {
      title: translate('questionnaire_name'),
      dataIndex: 'questionnaireName',
      key: 'questionnaireName',
      width: '24%',
      render: (questionnaireName: string | null) => questionnaireName ?? '-'
    },
    {
      title: translate('table_column_creation_date'),
      dataIndex: 'portalCreated',
      key: 'portalCreated',
      width: '24%',
      render: (portalCreated: LastUpdate | null) => (portalCreated !== null ? moment.utc(portalCreated.date).local().format('DD-MM-YYYY HH:mm') : '-')
    },
    {
      title: translate('table_column_created_by'),
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: '24%',
      render: (createdBy: LastUpdate | null) => (createdBy !== null ? `${createdBy.user.firstName} ${createdBy.user.lastName}` : '-')
    },
    {
      title: translate('actions'),
      dataIndex: 'action',
      key: 'action',
      width: '4%',
      render: (action: boolean, currentPortal: GlobalPortal) =>
        action && <RedirectButton questionnaireId={currentPortal.questionnaireId} instanceId={currentPortal.instanceId} appName={Apps.createPortal} />
    }
  ]

  return (
    <Table
      dataSource={portal}
      pagination={false}
      columns={columns}
      rowKey='portalName'
      loading={isLoading}
      locale={{
        emptyText: <TableEmptyState />
      }}
    />
  )
}
