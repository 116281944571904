import ReactDOM from 'react-dom/client'
import './index.scss'
import msalInstance from 'services/PublicClientApplication.service'
import { EventType, EventMessage, AuthenticationResult, SilentRequest } from '@azure/msal-browser'
import { BrowserRouter } from 'react-router-dom'
import axios from 'axios'
import { onboardingToolApiConfig } from './app.config'
import reportWebVitals from './reportWebVitals'
import App from './pages/Root/App'

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult
    const { account } = payload
    msalInstance.setActiveAccount(account)
  }
  if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    msalInstance.logoutPopup({
      mainWindowRedirectUri: '/'
    })
  }
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <App pca={msalInstance} />
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

axios.defaults.baseURL = onboardingToolApiConfig.uri
axios.interceptors.request.use(
  async (request) => {
    const account = msalInstance.getAllAccounts()[0]
    const tokenRequest: SilentRequest = {
      scopes: onboardingToolApiConfig.scopes ?? [],
      account
    }
    const msalResponse = await msalInstance.acquireTokenSilent(tokenRequest)

    if (request.headers) {
      request.headers.Authorization = `Bearer ${msalResponse.accessToken}`
      request.headers['x-version'] = '1.1'
    }

    return request
  },
  (error) => Promise.reject(error)
)

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // eslint-disable-next-line no-console
    console.log('Error during API call: ', error)
    return Promise.reject(error)
  }
)
