import { Icon, Button, Message } from '@bdo/kitchensink'
import translate from 'i18n/translate'
import clsx from 'clsx'
import { v4 as uuidv4 } from 'uuid'
import { useGeneralLedgerStore } from 'features/generalLedger/generalLedgerStore/generalLedgerStore'
import { FilterItem, Operators } from 'utils/FilterHelper/filter-helper'
import { useIntl } from 'react-intl'
import { accountFilterableFields, getAccounts } from 'services/generalLedger.service'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import FilterRow from '../FilterRow/FilterRow'
import styles from './FilterPanel.module.scss'

type Props = {
  closeFilterPanel: () => void
}

function FilterPanel(props: Readonly<Props>) {
  const { closeFilterPanel } = props
  const intl = useIntl()

  const setPagination = useGeneralLedgerStore((state) => state.setPagination)
  const setAccounts = useGeneralLedgerStore((state) => state.setAccounts)
  const applyFilters = useGeneralLedgerStore((state) => state.applyFilters)
  const addFilter = useGeneralLedgerStore((state) => state.addFilter)
  const clearFilters = useGeneralLedgerStore((state) => state.clearFilters)
  const setOrder = useGeneralLedgerStore((state) => state.setOrder)
  const pageSize = useGeneralLedgerStore((state) => state.pageSize)
  const filters = useGeneralLedgerStore((state) => state.filters)
  const page = useGeneralLedgerStore((state) => state.page)
  const isDirty = useGeneralLedgerStore((state) => state.isDirty)
  const hasErrors = useGeneralLedgerStore((state) => state.hasErrors)
  const sortField = useGeneralLedgerStore((state) => state.sortField)
  const sortDirection = useGeneralLedgerStore((state) => state.sortDirection)
  const sortOrder = sortDirection === 'ascend' ? `${sortField}` : `${sortField} desc`

  const onAddFilterRow = () => {
    const newFilter: FilterItem = {
      id: uuidv4(),
      field: accountFilterableFields[0],
      operator: Operators.Equal,
      value: '',
      hasErrors: false
    }
    if (filters.length < 5) {
      addFilter(newFilter)
    }
  }

  const onClearAndClose = () => {
    clearFilters()
    closeFilterPanel()
    getAccounts(page, pageSize, [], sortOrder).then((data) => {
      applyFilters()
      setAccounts(data.rows)
      setPagination(1, pageSize, data.total)
      setOrder('accountNumber', 'ascend')
      Message.success(intl.$t({ id: 'user_message_filters_updated' }), 5)
    })
  }

  const onApplyFilters = () => {
    getAccounts(page, pageSize, filters, sortOrder)
      .then((data) => {
        applyFilters()
        setAccounts(data.rows)
        setPagination(1, pageSize, data.total)
        if (data.total === 0) {
          Message.error(intl.$t({ id: 'user_message_filters_no_results' }), 5)
        } else {
          Message.success(intl.$t({ id: 'user_message_filters_updated' }), 5)
        }
      })
      .catch((error) => {
        Message.error(intl.$t({ id: 'user_message_filters_no_results' }))
        LoggerHelper(error)
      })
  }

  return (
    <div className={clsx(styles.filterWrapper)}>
      <div className={clsx(styles.labels, 'flex', 'alignItems')}>
        <span>{translate('filter')}</span>
        <span>{translate('filter_condition')}</span>
        <span>{translate('filter_value')}</span>
      </div>
      {filters.map((filter) => (
        <span key={filter.id}>
          <FilterRow id={filter.id} />
        </span>
      ))}

      <button type='button' className={clsx(styles.addFilterButton, 'flex', 'alignItems')} onClick={onAddFilterRow} disabled={filters.length > 4}>
        <Icon type='CircleAddition' />
        {translate('filter_add')}
      </button>
      <div className='mt-3 mb-1'>
        <Button type='primary' className={styles.applyFiltersButton} disabled={!isDirty || hasErrors} onClick={onApplyFilters}>
          {translate('button_apply_filters')}
        </Button>
        <Button type='default' className={clsx(styles.clearCloseButton, isDirty ? '' : 'transparent')} disabled={!isDirty} onClick={onClearAndClose}>
          {translate('button_clear_close')}
        </Button>
      </div>
    </div>
  )
}

export default FilterPanel
