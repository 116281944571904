import axios from 'axios'
import { AuthenticationResult, SilentRequest } from '@azure/msal-browser'
import msalInstance from 'services/PublicClientApplication.service'
import { authConfig, graphApiConfig } from '../../../app.config'

export type UserProfileData = {
  displayName: string
  jobTitle: string
  mail: string
  businessPhones: string[]
  officeLocation: string
}

export async function getUserProfileData(): Promise<UserProfileData | undefined> {
  const account = msalInstance.getActiveAccount()
  if (!account) {
    return
  }

  const tokenRequest: SilentRequest = {
    scopes: authConfig.scopes ?? [],
    account
  }
  // eslint-disable-next-line consistent-return
  return fetchUserData(await msalInstance.acquireTokenSilent(tokenRequest))
}

function fetchUserData(authResponse: AuthenticationResult) {
  const graphMeHttp = axios.create({
    baseURL: graphApiConfig.uri,
    headers: { Authorization: `Bearer ${authResponse.accessToken}` }
  })

  return (
    graphMeHttp
      .get('/')
      .then((response) => response.data)
      // eslint-disable-next-line no-console
      .catch((e) => console.log('Graph call error', e))
  )
}
