import clsx from 'clsx'
import styles from './PageNotification.module.scss'

type Props = {
  notification: string | undefined
  warningNotification?: boolean
  infoNotification?: boolean
}

function PageNotification(props: Readonly<Props>) {
  const { notification, warningNotification, infoNotification } = props
  return <div className={clsx(styles.message, infoNotification && styles.green, warningNotification && styles.red)}>{notification}</div>
}

export default PageNotification
