import axios from 'axios'
import queryString from 'query-string'
import { RcFile } from 'antd/lib/upload'
import { onboardingToolApiConfig } from '../app.config'
import { PaginatedResult } from './service.models'

export type Template = {
  id: string
  name: string
}

const templatesUri = `${onboardingToolApiConfig.uri}/descriptiontemplates`

export async function getTemplates(page: number, pageSize: number) {
  if (page < 1) {
    throw Error('page cannot be lower than 1')
  }
  const queryParams = { skip: (page - 1) * pageSize, top: pageSize }
  const getUri = queryString.stringifyUrl({ url: templatesUri, query: queryParams })
  const getResponse = await axios.get<PaginatedResult<Template>>(getUri)
  return getResponse.data
}

export async function getAllTemplates() {
  const getResult = await axios.get<PaginatedResult<Template>>(templatesUri)
  return getResult.data.rows
}

export async function addTemplate(template: Template) {
  const response = await axios.post<Template>(templatesUri, template)
  return response.data
}

export async function deleteTemplate(id: string) {
  const uri = `${onboardingToolApiConfig.uri}/descriptiontemplates?id=${id}`
  await axios.delete(uri)
}

export async function updateTemplate(template: Template) {
  const response = await axios.put<Template>(templatesUri, template)
  return response.data
}

export async function uploadTemplatesFile(file: RcFile) {
  const formData = new FormData()
  formData.append('file', file)
  const uploadUri = `${onboardingToolApiConfig.uri}/descriptiontemplates/upload`
  const response = await axios.post(uploadUri, formData)

  if (response.status === 200) {
    return response.data
  }

  throw new Error(`Response error: ${response.status}: ${response}`)
}

export async function exportTemplates() {
  const exportUri = `${onboardingToolApiConfig.uri}/descriptiontemplates/export`
  const response = await axios.get(exportUri, { responseType: 'blob' })

  return response
}
