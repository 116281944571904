import { useIntl } from 'react-intl'
import { AnswerQuestionnaireStatus } from 'services/QuestionsAnswers/QuestionsAnswers.model'

export default function getStatusHint(publishedQuestionnaireStatus: string, appName: string): string {
  const intl = useIntl()
  switch (publishedQuestionnaireStatus) {
    case AnswerQuestionnaireStatus.NotAvailable:
      return intl.$t({ id: 'status_not_available_message' })

    case AnswerQuestionnaireStatus.InProgress:
      return intl.$t({ id: 'status_in_progress_message' })

    case AnswerQuestionnaireStatus.Ready:
      return intl.$t({ id: `status_ready_${appName}_message` })

    case AnswerQuestionnaireStatus.Completed:
      return intl.$t({ id: `status_completed_${appName}_message` })

    case AnswerQuestionnaireStatus.Processing:
      return intl.$t({ id: 'status_processing_message' })

    default:
      return ''
  }
}
