import { useEffect, useState } from 'react'
import { Table } from '@bdo/kitchensink'
import translate from 'i18n/translate'
import TableEmptyState from 'ui-components/TableEmptyState/TableEmptyState'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import { useIntl } from 'react-intl'
import { GlobalPortalProject, LastUpdate } from 'services/Client/client.models'
import moment from 'moment'
import { getProjects } from 'services/portalsAndProjects.service'
import { useClientId } from 'features/clients/clientZustandStore'
import getErrorMessage from 'utils/getErrorMessage'
import { Apps } from 'services/Questionnaire/questionnaire.models'
import RedirectButton from './RedirectButton'

export default function ProjectsList() {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const clientId = useClientId()
  const [projects, setProjects] = useState<GlobalPortalProject[]>([])

  useEffect(() => {
    fetchProjects()
  }, [])

  const fetchProjects = () => {
    if (!clientId) {
      return
    }
    setIsLoading(true)

    getProjects(clientId)
      .then((gpProjects: GlobalPortalProject[]) => {
        setProjects(gpProjects)
      })
      .catch((error) => {
        LoggerHelper(error)
        getErrorMessage(intl, error, 400, 'api_projects_do_not_exist_')
      })
      .finally(() => setIsLoading(false))
  }

  const columns = [
    {
      title: translate('project_name'),
      dataIndex: 'projectName',
      key: 'projectName',
      width: '24%'
    },
    {
      title: translate('questionnaire_name'),
      dataIndex: 'questionnaireName',
      key: 'questionnaireName',
      width: '24%',
      render: (questionnaireName: string) => questionnaireName ?? '-'
    },
    {
      title: translate('table_column_creation_date'),
      dataIndex: 'projectCreated',
      key: 'projectCreated',
      width: '24%',
      render: (projectCreated: Date | null) => (projectCreated !== null ? moment.utc(projectCreated).local().format('DD.MM.YYYY HH:mm') : '-')
    },
    {
      title: translate('table_column_created_by'),
      dataIndex: 'createdBy',
      width: '24%',
      key: 'createdBy',
      render: (createdBy: LastUpdate) => (createdBy !== null ? `${createdBy.user.firstName} ${createdBy.user.lastName}` : '-')
    },
    {
      title: translate('actions'),
      dataIndex: 'action',
      key: 'action',
      width: '5%',
      render: (action: boolean, project: GlobalPortalProject) => (
        <> {action && <RedirectButton instanceId={project.instanceId} questionnaireId={project.questionnaireId} appName={Apps.createProject} />}</>
      )
    }
  ]

  return (
    <Table
      dataSource={projects}
      columns={columns}
      rowKey='projectName'
      loading={isLoading}
      locale={{
        emptyText: <TableEmptyState />
      }}
    />
  )
}
