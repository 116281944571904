import { Badge } from '@bdo/kitchensink'
import translate from 'i18n/translate'
import clsx from 'clsx'
import { AnswerQuestionnaireStatus, StatusColors } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import useClientStore, { useLastAnsweredQuestionnaireData } from 'features/clients/clientZustandStore'
import styles from './LastAnsweredQuestionnaire.module.scss'
import ModificationInfo from './ModificationInfo'

type Props = {
  appId: number
}

function LastAnsweredQuestionnaire(props: Readonly<Props>) {
  const { appId } = props
  const lastAnsweredQuestionnaireData = useLastAnsweredQuestionnaireData(appId)
  const lastAnsweredQuestionnaireName = lastAnsweredQuestionnaireData.questionnaireName
  const lastAnsweredQuestionnaireStatus = lastAnsweredQuestionnaireData.status
  const appIdsWhereAnswersForImportAreAvailable = useClientStore((state) => state.appIdsWhereAnswersForImportAreAvailable)

  const indexOfS = Object.values(AnswerQuestionnaireStatus).indexOf(lastAnsweredQuestionnaireStatus)
  const statusKey = Object.keys(AnswerQuestionnaireStatus)[indexOfS]
  const colorStatus = Object.values(StatusColors)[indexOfS]

  return (
    <div className={styles.lastAnsweredQuestionnaire}>
      <div className={clsx(styles.lastAnsweredQuestionnaireInfo, 'flex', 'alignItems')}>
        <div
          className={clsx(
            styles.lastAnsweredQuestionnaireTitle,
            !appIdsWhereAnswersForImportAreAvailable.includes(appId) ? styles.grayBackground : styles.greenBackground,
            'flex',
            'alignItems'
          )}
        >
          <div className={styles.wrapper}>
            <div className={styles.prevInfo}>{translate('user_message_prev_answered_questionnaire')}</div>
            <h4>{lastAnsweredQuestionnaireName}</h4>
            <ModificationInfo modificationInfo={lastAnsweredQuestionnaireData} />
          </div>
          <Badge color={`${colorStatus}`} text={translate(`status_${statusKey}`)} className={styles.status} />
        </div>
        <div className={styles.hiddenButton}> </div>
      </div>
    </div>
  )
}

export default LastAnsweredQuestionnaire
