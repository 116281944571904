import clsx from 'clsx'
import styles from './LayoutTwoColumns.module.scss'
import QuestionsList from '../QuestionsList/QuestionsList'
import GroupsNavigation from '../GroupsNavigation/GroupsNavigation'
import { useSelectedGroup } from '../questionAnswerZustandStore'

function LayoutTwoColumns() {
  const group = useSelectedGroup()

  return (
    <div className={clsx(styles.twoColumnsWrapper, 'flex')}>
      {group?.columns.map((column) => (
        <div key={column.id} className={clsx(styles.columnCard, 'card')}>
          <h4 className='subtitle'>{column.name}</h4> <QuestionsList columnId={column.id ?? ''} />
        </div>
      ))}
      <GroupsNavigation />
    </div>
  )
}

export default LayoutTwoColumns
