import { GroupContentViewModel } from 'features/questionnaire/components/_models/GroupContentViewModel'
import GroupTabAnswers from '../GroupTabAnswers/GroupTabAnswers'
import { useQuestionnaireGroups } from '../questionAnswerZustandStore'
import styles from './GroupTabsAnswers.module.scss'

function GroupTabsAnswers() {
  const groups = useQuestionnaireGroups()

  return (
    <div className={styles.wrapper}>
      <div className={styles.groupsWrapper}>
        {groups?.map((g: GroupContentViewModel) => (
          <GroupTabAnswers groupKey={g.key} key={g.key} />
        ))}
      </div>
    </div>
  )
}

export default GroupTabsAnswers
