import clsx from 'clsx'
import LayoutTwoColumns from '../LayoutTwoColumns/LayoutTwoColumns'
import QuestionsList from '../QuestionsList/QuestionsList'
import GroupsNavigation from '../GroupsNavigation/GroupsNavigation'
import styles from './GroupLayout.module.scss'
import { useSelectedGroup } from '../questionAnswerZustandStore'

function GroupLayout() {
  const selectedGroup = useSelectedGroup()
  const isSingleColumn = selectedGroup && selectedGroup.numberOfColumns <= 1

  return (
    <>
      <div className={clsx(isSingleColumn && styles.oneColumnWrapper, 'card')}>
        <h3 className={clsx(isSingleColumn ? 'modalTitle' : 'noMargin')}>{selectedGroup?.name}</h3>
        <div className={styles.oneColumnQuestionsWrapper}>
          {isSingleColumn &&
            selectedGroup?.columns.map((column) => (
              <div key={column.id}>
                <QuestionsList columnId={column.id ?? ''} />
              </div>
            ))}
        </div>
        {isSingleColumn && <GroupsNavigation />}
      </div>
      <div>{!isSingleColumn && <LayoutTwoColumns />}</div>
    </>
  )
}

export default GroupLayout
