import translate from 'i18n/translate'
import { Input } from 'antd'
import { Form, Select, Checkbox } from '@bdo/kitchensink'
import { useIntl } from 'react-intl'
import { AccountType } from 'services/generalLedger.service'
import styles from './AccountForm.module.scss'

function AccountForm() {
  const intl = useIntl()
  const { Option } = Select

  return (
    <>
      <div className='flex alignItems'>
        <Form.Item
          name='accountNumber'
          label={translate('account_number')}
          rules={[
            { min: 7, max: 7, message: translate('user_message_7_digits') },
            { pattern: /^\d*$/, message: translate('user_message_integer') },
            { required: true, message: translate('forms_required_field') }
          ]}
          className={styles.accountInput}
        >
          <Input step='1' min='0' placeholder={intl.$t({ id: 'forms_placeholder_account_number' }, { value: '7' })} maxLength={7} />
        </Form.Item>
        <Form.Item
          name='type'
          label={translate('account_type')}
          initialValue={AccountType.BAS}
          className={styles.typeSelect}
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select>
            <Option value={AccountType.BAS}>{AccountType.BAS}</Option>
            <Option value={AccountType.PNL}>{AccountType.PNL}</Option>
          </Select>
        </Form.Item>
      </div>
      <Form.Item name='alwaysOn' valuePropName='checked'>
        <Checkbox>{translate('account_isActive')}</Checkbox>
      </Form.Item>
      <Form.Item name='dutchDescription' label={translate('account_description_nl')} rules={[{ required: true, message: translate('forms_required_field') }]}>
        <Input.TextArea autoSize={{ minRows: 1, maxRows: 4 }} showCount maxLength={164} placeholder={intl.$t({ id: 'forms_placeholder_no_characters' }, { value: '164' })} />
      </Form.Item>
      <Form.Item name='englishDescription' label={translate('account_description_en')} rules={[{ required: true, message: translate('forms_required_field') }]}>
        <Input.TextArea autoSize={{ minRows: 1, maxRows: 4 }} showCount maxLength={164} placeholder={intl.$t({ id: 'forms_placeholder_no_characters' }, { value: '164' })} />
      </Form.Item>
    </>
  )
}

export default AccountForm
