import translate from 'i18n/translate'
import ClientsList from 'features/clients/components/ClientsList/ClientsList'

function Clients() {
  return (
    <div className='pagePadding'>
      <h2 className='pageTitle'>{translate('clients')}</h2>
      <div className='card cardTable'>
        <ClientsList />
      </div>
    </div>
  )
}

export default Clients
