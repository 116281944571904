import AddGroupModal from 'features/questionnaire/components/AddGroupModal/AddGroupModal'
import { useQuestionnaireStore } from 'features/questionnaire/questionaryZustandStore'
import EmptyPage from 'ui-components/EmptyPage/EmptyPage'

type Props = {
  isLoading: boolean
}

function EmptyQuestionnaire(props: Readonly<Props>) {
  const { isLoading } = props
  const isReadOnly = useQuestionnaireStore((state) => state.isReadOnly)

  return <EmptyPage>{!isReadOnly && !isLoading ? <AddGroupModal buttonType='primary' /> : undefined}</EmptyPage>
}

export default EmptyQuestionnaire
