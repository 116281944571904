import { Icon, Tooltip } from '@bdo/kitchensink'
import translate from 'i18n/translate'
import React from 'react'
import clsx from 'clsx'
import { useApplicationIdByName } from 'features/clients/clientZustandStore'
import { useNavigate } from 'react-router-dom'
import styles from './TabPortalProjects.module.scss'

type Props = {
  instanceId: string
  questionnaireId: string
  appName: string
}

export default function RedirectButton(props: Readonly<Props>) {
  const { instanceId, questionnaireId, appName } = props

  const appId = useApplicationIdByName(appName)
  const navigate = useNavigate()

  const handleOnClick = () => {
    navigate(`../questionnaires/${questionnaireId}/application/${appId}/instance/${instanceId}/fill`)
  }

  return (
    <Tooltip title={translate('preview_questionnaire')} mouseEnterDelay={0.5}>
      <button type='button' aria-label='button' className={clsx(styles.chevronButton, 'iconButton')} onClick={handleOnClick}>
        <Icon type='ChevronRight' />
      </button>
    </Tooltip>
  )
}
