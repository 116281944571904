import { QuestionViewModel } from 'features/questionnaire/components/_models/QuestionViewModel'
import { useColumn } from '../questionAnswerZustandStore'
import Questions from '../Questions/Questions'

type Props = {
  columnId: string
}

function QuestionsList(props: Readonly<Props>) {
  const { columnId } = props
  const column = useColumn(columnId)

  return (
    <div>
      {column && (
        <>
          {column.questions.map((question: QuestionViewModel) => (
            <Questions key={question.id} questionKey={question.key} />
          ))}
        </>
      )}
    </div>
  )
}

export default QuestionsList
