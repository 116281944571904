import { Badge } from '@bdo/kitchensink'
import translate from 'i18n/translate'
import { PresetStatusColorType } from 'antd/lib/_util/colors'
import { QuestionnaireStatuses } from 'services/Questionnaire/questionnaire.models'

type Props = {
  questionnaireStatus: string
}

function StatusBadge(props: Readonly<Props>) {
  const { questionnaireStatus } = props

  let badgeStatus: PresetStatusColorType = 'default'

  if (questionnaireStatus === QuestionnaireStatuses.Published) {
    badgeStatus = 'success'
  } else if (questionnaireStatus === QuestionnaireStatuses.Draft) {
    badgeStatus = 'processing'
  }

  return (
    <>
      <Badge status={badgeStatus} /> {translate(`questionnaire_${questionnaireStatus.toLowerCase()}`)}
    </>
  )
}

export default StatusBadge
