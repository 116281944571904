import { onboardingToolApiConfig } from 'app.config'
import axios from 'axios'
import { AnswersInfo } from 'services/Client/client.models'
import { QuestionAnswer } from './QuestionsAnswers.model'

const answerUri = `${onboardingToolApiConfig.uri}/Answers`
const instanceUri = `${onboardingToolApiConfig.uri}/instances`

export async function getAnswersForClient(clientId: number, instanceId: string): Promise<QuestionAnswer[]> {
  const uri = `${answerUri}?clientId=${clientId}&instanceId=${instanceId}`
  const response = await axios.get<QuestionAnswer[]>(uri)
  return response.data
}

export async function getInstanceInfoById(instanceId: string): Promise<AnswersInfo> {
  const uri = `${instanceUri}/${instanceId}`
  const response = await axios.get<AnswersInfo>(uri)
  return response.data
}

export async function saveAnswersForInstance(clientId: number, instanceId: string, answers: QuestionAnswer[], status: string): Promise<QuestionAnswer[]> {
  const uri = `${answerUri}/${clientId}/${instanceId}/${status}`
  const response = await axios.post<QuestionAnswer[]>(
    uri,
    answers.map((a) => ({ id: a.id ?? null, text: a.text.toString(), questionId: a.questionId }))
  )
  return response.data
}

export async function deleteAnswers(answer: QuestionAnswer): Promise<QuestionAnswer[]> {
  const uri = `${answerUri}/${answer.id}`
  const response = await axios.delete<QuestionAnswer[]>(uri)
  return response.data
}

export async function sendInstanceAnswers(clientId: number, instanceId: string, connectionId: string) {
  const uri = `${instanceUri}/${instanceId}/complete`
  const payload = { clientId, connectionId }
  const response = await axios.post(uri, payload)

  return response
}
