import { Badge, Icon, Tooltip } from '@bdo/kitchensink'
import GlobalPortalLogo from 'assets/images/global-portal-logo.svg'
import translate from 'i18n/translate'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { AnswerQuestionnaireStatus, StatusColors } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import useClientStore, { useApplicationName, usePublishedQuestionnaireData } from 'features/clients/clientZustandStore'
import IconButton from 'ui-components/IconButton/IconButton'
import getStatusHint from 'utils/getStatusHint'
import { useEffect } from 'react'
import { getAnswersForClient } from 'services/QuestionsAnswers/QuestionAnswer.service'
import { useErrorBoundary } from 'react-error-boundary'
import { isProcessing } from 'services/Client/clientProcessedQuestionnaireStatus.service'
import { useIntl } from 'react-intl'
import { GlobalPortal } from 'services/Client/client.models'
import { answersImport } from 'services/answerQuestionnaireConfig.service'
import styles from './PublishedCreatePortal.module.scss'
import PortalInfo from './PortalInfo'

type Props = {
  appId: number
  portal: GlobalPortal | undefined
  isPortalInfoLoading: boolean
}

function PublishedCreatePortal(props: Readonly<Props>) {
  const { appId, portal, isPortalInfoLoading } = props
  const navigate = useNavigate()
  const publishedQuestionnaireData = usePublishedQuestionnaireData(appId)
  const clientId = useClientStore((state) => state.client.id)
  const publishedQuestionnaireStatus = publishedQuestionnaireData ? publishedQuestionnaireData.status : AnswerQuestionnaireStatus.NotAvailable
  const publishedQuestionnaireName = publishedQuestionnaireData ? publishedQuestionnaireData.questionnaireName : ''
  const publishedQuestionnaireId = publishedQuestionnaireData ? publishedQuestionnaireData.questionnaireId : ''
  const instanceId = publishedQuestionnaireData?.instanceId
  const applicationName = useApplicationName(appId)
  const removeAppIdsThatHaveAnswersAvailable: (appId: number) => void = useClientStore((state) => state.removeAppIdsThatHaveAnswersAvailable)
  const addAppIdsThatHaveAnswersAvailable: (appId: number) => void = useClientStore((state) => state.addAppIdsThatHaveAnswersAvailable)
  const { showBoundary } = useErrorBoundary()
  const intl = useIntl()
  const { isAnswersImportEnabled } = answersImport[applicationName]

  useEffect(() => {
    if (!instanceId || !clientId || !isAnswersImportEnabled) {
      return
    }
    getAnswersForClient(clientId, instanceId)
      .then((answers) => {
        if (answers.length > 0 && !answers[0].id) {
          addAppIdsThatHaveAnswersAvailable(appId)
        } else if ((answers.length > 0 && answers[0].id) || answers.length === 0) {
          removeAppIdsThatHaveAnswersAvailable(appId)
        }
      })
      .catch((e) => {
        showBoundary(e)
      })
  }, [publishedQuestionnaireData])

  const handleClick = () => {
    navigate(`../questionnaires/${publishedQuestionnaireId}/application/${appId}/instance/${instanceId}/fill`)
  }

  const indexOfS = Object.values(AnswerQuestionnaireStatus).indexOf(publishedQuestionnaireStatus)
  const statusKey = Object.keys(AnswerQuestionnaireStatus)[indexOfS]
  const colorStatus = Object.values(StatusColors)[indexOfS]

  return (
    <div className={clsx(styles.publishedQuestionnaire, 'flex')}>
      <div className={clsx(styles.publishedQuestionnaireWrapper, 'flex', 'alignItems')}>
        <img src={GlobalPortalLogo} alt='' />
        <div className={styles.publishedQuestionnaireTitle}>
          <h4 className='flex alignItems'>
            {publishedQuestionnaireStatus !== AnswerQuestionnaireStatus.NotAvailable ? publishedQuestionnaireName : translate('application_Portal')}
          </h4>
          <PortalInfo modificationInfo={publishedQuestionnaireData} portalName={portal?.portalName} isPortalLoading={isPortalInfoLoading} />
        </div>

        <div className={clsx(styles.statusBadge, 'flex', 'alignItems')}>
          <Badge color={`${colorStatus}`} text={translate(`status_${statusKey}`)} className={styles.status} />
          {publishedQuestionnaireData && publishedQuestionnaireData.status !== AnswerQuestionnaireStatus.NotStarted && (
            <Tooltip title={getStatusHint(publishedQuestionnaireStatus, applicationName)} mouseEnterDelay={0.1} placement='right'>
              <Icon type='Unknown' className={styles.hintIcon} />
            </Tooltip>
          )}
        </div>
      </div>
      <div className={styles.editButton}>
        <IconButton
          type={publishedQuestionnaireStatus === AnswerQuestionnaireStatus.Completed ? 'ChevronRight' : 'Edit'}
          toolTipTitle={publishedQuestionnaireStatus === AnswerQuestionnaireStatus.Completed ? intl.$t({ id: 'button_view_answers' }) : intl.$t({ id: 'button_edit' })}
          testId='editIcon'
          onClick={handleClick}
          disabled={
            (isProcessing(clientId, appId) && publishedQuestionnaireStatus === AnswerQuestionnaireStatus.Processing) ||
            publishedQuestionnaireStatus === AnswerQuestionnaireStatus.NotAvailable
          }
        />
      </div>
    </div>
  )
}

export default PublishedCreatePortal
