import useQuestionnaireAnswerStore from 'features/clientDetails/components/questionAnswerZustandStore'
import useClientStore from 'features/clients/clientZustandStore'
import { useState, useRef, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { processingFinished } from 'services/Client/clientProcessedQuestionnaireStatus.service'
import { AnswerQuestionnaireStatus } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import AsyncNotification, { AsyncMessageType, NotificationType } from 'ui-components/AsyncNotification/AsyncNotification'
import SignalRContext from 'utils/SignalRContextHelper/SignalRContextHelper'

export enum SignalRSubscriptions {
  FilesExport = 'filesExport',
  PortalCreation = 'PortalCreation',
  PortalProjectCreation = 'PortalProjectCreation',
  QuestionnairePublished = 'QuestionnairePublished'
}

export type Props = {
  subscription: SignalRSubscriptions
}

function SignalRReceiver(props: Readonly<Props>) {
  const { subscription } = props
  const [messages, setMessages] = useState<AsyncMessageType[]>([])
  const updateStatusOfSelectedPublishedQuestionnaire = useClientStore((state) => state.updateStatusOfSelectedPublishedQuestionnaire)
  const removeIdOfAppWhenRequestSent = useClientStore((state) => state.removeIdOfAppWhenRequestSent)
  const clientId = useClientStore((state) => state.client.id)
  const previousMessage = useRef<AsyncMessageType[]>([])
  const intl = useIntl()
  const { appId } = useParams<string>()
  const applicationId = parseInt(appId ?? '0', 10)
  const updateIsArchived = useQuestionnaireAnswerStore((state) => state.updateIsArchived)
  const defaultErrorMessge = intl.$t({ id: `signalR_default_error_message` })

  useEffect(() => {
    previousMessage.current = messages
  }, [messages])

  SignalRContext.useSignalREffect(
    subscription,
    (message) => {
      function getDescription(signalRMessageStatus: NotificationType) {
        switch (signalRMessageStatus) {
          case NotificationType.Success:
          case NotificationType.Warning:
          case NotificationType.Info:
            return intl.$t({ id: `signalR_${message.messageCode}_message` }, { clientId: `${message.data.id}` })

          case NotificationType.Error:
            return intl.$t({ defaultMessage: defaultErrorMessge, id: `signalR_${message.data.errors[0].code}` })

          default:
            return ''
        }
      }

      const signalRMessage: AsyncMessageType = {
        id: message.id,
        title: intl.$t({ id: `signalR_${message.messageCode}_title` }),
        description: getDescription(message.status),
        status: message.status,
        data: { appId: message.data.applicationId, clientId: message.data.id }
      }

      if (clientId.toString() === message.data.id) {
        updateStatusOfSelectedPublishedQuestionnaire(
          signalRMessage.status === 'success' ? AnswerQuestionnaireStatus.Completed : AnswerQuestionnaireStatus.Ready,
          signalRMessage.data.appId
        )
      }

      if (signalRMessage.data.appId === applicationId) {
        updateIsArchived(true)
        setMessages([...messages, signalRMessage])
      }

      if (subscription !== SignalRSubscriptions.QuestionnairePublished) setMessages([...messages, signalRMessage])

      removeIdOfAppWhenRequestSent(Number(signalRMessage.data.clientId), signalRMessage.data.appId)
      processingFinished(Number(signalRMessage.data.clientId), signalRMessage.data.appId)
    },
    []
  )

  const cleanUpMessageOnClose = (id: string) => {
    setMessages(previousMessage.current.filter((message) => message.id !== id))
  }

  return (
    <>
      {messages.map((message) => (
        <AsyncNotification key={message.id} message={message} cleanUpMessageOnClose={cleanUpMessageOnClose} />
      ))}
    </>
  )
}
export default SignalRReceiver
