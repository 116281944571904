import SignInButton from 'features/authentication/components/SignInButton/SignInButton'
import bdoLogoImage from 'assets/images/bdo_logo_color.jpg'
import clsx from 'clsx'
import translate from 'i18n/translate'
import styles from './LoginPage.module.scss'

function LoginPage() {
  return (
    <div className={styles.noAuthPage}>
      <div className={styles.noAuthPageContent}>
        <div className={clsx(styles.loginWrapper, 'card')}>
          <img src={bdoLogoImage} alt='' />
          <h3>{translate('app_welcome_message')}</h3>
          <SignInButton />
        </div>
      </div>
    </div>
  )
}
export default LoginPage
