/* eslint-disable import/prefer-default-export */
import { GroupContentViewModel } from 'features/questionnaire/components/_models/GroupContentViewModel'
import { QuestionViewModel } from 'features/questionnaire/components/_models/QuestionViewModel'
import { AccountDescription } from 'services/accountDescription.service'
import { Questionnaire, QuestionTypes } from 'services/Questionnaire/questionnaire.models'
import { Template } from 'services/templates.service'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

type AccountState = {
  description: AccountDescription
  snapshot: string
  isDirty: boolean
  questionnaires: Questionnaire[]
  groups: GroupContentViewModel[]
  questions: QuestionViewModel[]
  childQuestions: QuestionViewModel[]
  selectedQuestionnaireId: string | undefined
  selectedGroupId: string | undefined
  selectedQuestionId: string | undefined
  selectedChildQuestionId: string | undefined
  templates: Template[]
  selectedTemplateId: string | undefined
  allAccountDescriptions: AccountDescription[]
  currentAccountDescription: AccountDescription
}

type AccountActions = {
  setQuestionnaires: (questionnaires: Questionnaire[]) => void
  setGroups: (groups: GroupContentViewModel[]) => void
  setQuestions: (questions: QuestionViewModel[]) => void
  setChildQuestions: (questions: QuestionViewModel[]) => void
  selectQuestionnaire: (questionnaireId: string) => void
  selectGroup: (groupId: string | undefined) => void
  selectQuestion: (questionId: string | undefined) => void
  selectChildQuestion: (questionId: string | undefined) => void
  setTemplates: (templates: Template[]) => void
  selectTemplate: (templateId: string | undefined) => void
  setAllAccountDescriptions: (descriptions: AccountDescription[]) => void
  addDescription: (description: AccountDescription) => void
  updateDescription: (description: AccountDescription) => void
  deleteDescription: (accountId: number, questionnaireId: string) => void
  setCurrentDescription: (description: AccountDescription) => void
  cleanup: () => void
}

const initialState: AccountState = {
  description: { accountId: 0, questionnaireId: '', groupId: '', questionId: '', descriptionTemplateId: '', parentQuestionId: '' },
  snapshot: '',
  isDirty: false,
  questionnaires: [],
  groups: [],
  questions: [],
  childQuestions: [],
  selectedQuestionnaireId: undefined,
  selectedGroupId: undefined,
  selectedQuestionId: undefined,
  selectedChildQuestionId: undefined,
  templates: [],
  selectedTemplateId: undefined,
  allAccountDescriptions: [],
  currentAccountDescription: { accountId: 0, groupId: '', questionId: '', questionnaireId: '', descriptionTemplateId: '', parentQuestionId: '' }
}

export const useAccountDescriptionStore = create<AccountState & AccountActions, [['zustand/devtools', AccountState & AccountActions]]>(
  devtools(
    (set) => ({
      ...initialState,
      setQuestionnaires: (questionnaires) => set(setQuestionnaires(questionnaires), false, 'Set questionnaires list'),
      setGroups: (groups) => set(setGroups(groups), false, 'Set group list'),
      setQuestions: (questions) => set(setQuestions(questions), false, 'Set question list'),
      setChildQuestions: (questions) => set(setChildQuestions(questions), false, 'Set child questions list'),
      selectQuestionnaire: (questionnaireId) => set(selectQuestionnaire(questionnaireId), false, 'Select questionnaire'),
      selectGroup: (groupId) => set(selectGroup(groupId), false, 'Select group'),
      selectQuestion: (questionId) => set(selectQuestion(questionId), false, 'Select question'),
      selectChildQuestion: (questionId) => set(selectChildQuestion(questionId), false, 'Select child question'),
      setTemplates: (templates) => set(setTemplates(templates), false, 'Set description templates'),
      selectTemplate: (templateId) => set(selectTemplate(templateId), false, 'Select template'),
      setAllAccountDescriptions: (descriptions) => set(setAllAccountDescriptions(descriptions), false, 'Set all account descriptions'),
      addDescription: (description) => set(addAccountDescription(description), false, 'Add account description'),
      updateDescription: (description) => set(updateAccountDescription(description), false, 'Update account description'),
      deleteDescription: (accountId, questionnaireId) => set(deleteAccountDescription(accountId, questionnaireId), false, 'Delete account description'),
      setCurrentDescription: (description) => set(setCurrentDescription(description), false, 'Set current description'),
      cleanup: () => set(cleanupDescription(), false, 'Cleanup')
    }),
    { name: 'Onboarding tool - Account description store' }
  )
)

export const useQuestions = () => useAccountDescriptionStore((state) => state.questions)
export const useSelectedQuestionId = () => useAccountDescriptionStore((state) => state.selectedQuestionId)
export const useChildQuestions = () => useAccountDescriptionStore((state) => state.childQuestions)
export const useSelectedChildQuestionId = () => useAccountDescriptionStore((state) => state.selectedChildQuestionId)
export const useTemplates = () => useAccountDescriptionStore((state) => state.templates)
export const useGroups = () => useAccountDescriptionStore((state) => state.groups)
export const useIsDirty = () => useAccountDescriptionStore((state) => state.isDirty)
export const useQuestionnaires = () => useAccountDescriptionStore((state) => state.questionnaires)
export const useSelectedGroupId = () => useAccountDescriptionStore((state) => state.selectedGroupId)
export const useSelectedQuestionnaireId = () => useAccountDescriptionStore((state) => state.selectedQuestionnaireId)
export const useSelectedTemplateId = () => useAccountDescriptionStore((state) => state.selectedTemplateId)

function cleanupDescription(): (state: AccountState) => AccountState {
  return (state) => ({
    ...state,
    groups: [],
    questions: [],
    childQuestions: [],
    selectedGroupId: undefined,
    selectedQuestionId: undefined,
    selectedChildQuestionId: undefined,
    selectedQuestionnaireId: undefined,
    snapshot: '',
    isDirty: false
  })
}

function getSnapshot(selectedTemplate: string | undefined, selectedQuestionId: string | undefined) {
  const data = {
    selectedQuestionId,
    selectedTemplate
  }
  const snapshot = JSON.stringify(data)
  return snapshot
}

function setQuestionnaires(questionnaires: Questionnaire[]): (state: AccountState) => AccountState {
  return (state) => ({ ...state, questionnaires, snapshot: getSnapshot(state.selectedTemplateId, state.selectedQuestionId) })
}

function setGroups(groups: GroupContentViewModel[]): (state: AccountState) => AccountState {
  return (state) => ({ ...state, groups })
}

function setQuestions(questions: QuestionViewModel[]): (state: AccountState) => AccountState {
  return (state) => {
    const filteredQuestions: QuestionViewModel[] = []

    questions.forEach((q) => {
      if (q.type === QuestionTypes.Multiplied) {
        const filteredGenQuestions = q.generatedQuestions.filter((gq) => gq.type === QuestionTypes.Text || gq.type === QuestionTypes.Multiplied)

        filteredGenQuestions.forEach((fq) => filteredQuestions.push(fq))
      }
      if (q.type === QuestionTypes.Text) {
        filteredQuestions.push(q)
      }
    })
    return { ...state, questions: filteredQuestions }
  }
}

function setChildQuestions(questions: QuestionViewModel[]): (state: AccountState) => AccountState {
  return (state) => ({ ...state, childQuestions: questions })
}

function selectQuestionnaire(questionnaireId: string): (state: AccountState) => AccountState {
  return (state) => ({
    ...state,
    selectedQuestionnaireId: questionnaireId,
    selectedGroupId: undefined,
    selectedQuestionId: undefined,
    selectedTemplateId: undefined,
    selectedChildQuestionId: undefined
  })
}

function selectGroup(groupId: string | undefined): (state: AccountState) => AccountState {
  return (state) => ({ ...state, selectedGroupId: groupId, selectedQuestionId: undefined, selectedChildQuestionId: undefined })
}

function selectQuestion(questionId: string | undefined): (state: AccountState) => AccountState {
  return (state) => {
    const isDirty = state.snapshot !== getSnapshot(state.selectedTemplateId, questionId)
    return { ...state, selectedQuestionId: questionId, selectedChildQuestionId: undefined, isDirty }
  }
}

function selectChildQuestion(questionId: string | undefined): (state: AccountState) => AccountState {
  return (state) => {
    const isDirty = state.snapshot !== getSnapshot(state.selectedTemplateId, questionId)

    return { ...state, selectedChildQuestionId: questionId, isDirty }
  }
}

function setTemplates(templates: Template[]): (state: AccountState) => AccountState {
  return (state) => ({ ...state, templates })
}

function selectTemplate(templateId: string | undefined): (state: AccountState) => AccountState {
  return (state) => {
    const isDirty = state.snapshot !== getSnapshot(templateId, state.selectedQuestionId)

    return { ...state, selectedTemplateId: templateId, isDirty }
  }
}

function setAllAccountDescriptions(descriptions: AccountDescription[]): (state: AccountState) => AccountState {
  return (state) => ({ ...state, allAccountDescriptions: descriptions })
}

function addAccountDescription(description: AccountDescription): (state: AccountState) => AccountState {
  return (state) => ({
    ...state,
    allAccountDescriptions: [...state.allAccountDescriptions, description]
  })
}

function updateAccountDescription(description: AccountDescription): (state: AccountState) => AccountState {
  return (state) => ({
    ...state,
    allAccountDescriptions: state.allAccountDescriptions.map((d) => {
      if (d.accountId === description.accountId && d.questionnaireId === description.questionnaireId) {
        return description
      }
      return d
    })
  })
}

function deleteAccountDescription(accountId: number, questionnaireId: string): (state: AccountState) => AccountState {
  return (state) => ({ ...state, allAccountDescriptions: [...state.allAccountDescriptions.filter((x) => x.accountId !== accountId || x.questionnaireId !== questionnaireId)] })
}

function setCurrentDescription(description: AccountDescription): (state: AccountState) => AccountState {
  return (state) => ({
    ...state,
    currentAccountDescription: description,
    snapshot: getSnapshot(description.descriptionTemplateId, description.questionId),
    isDirty: false
  })
}
