import create from 'zustand'
import { devtools } from 'zustand/middleware'
import { Template } from 'services/templates.service'

type TemplatesState = {
  templates: Template[]
  page: number
  pageSize: number
  total: number
}

type TemplatesActions = {
  setTemplates: (templates: Template[]) => void
  setPagination: (page: number, pageSize: number, total: number) => void
  updateTemplate: (updatedTemplate: Template) => void
}

const initialState: TemplatesState = {
  templates: [],
  page: 1,
  pageSize: 10,
  total: 0
}

// eslint-disable-next-line import/prefer-default-export
export const useTemplatesStore = create<TemplatesState & TemplatesActions, [['zustand/devtools', TemplatesState & TemplatesActions]]>(
  devtools(
    (set) => ({
      ...initialState,
      setTemplates: (templates) => set(setTemplates(templates), false, 'Set templates'),
      setPagination: (page, pageSize, total) => set(setPagination(page, pageSize, total), false, 'Set pagination'),
      updateTemplate: (updatedTemplate) => set(updateTemplate(updatedTemplate), false, 'Update template')
    }),
    { name: 'Onboarding tool - Templates store' }
  )
)

function setTemplates(templates: Template[]): (state: TemplatesState) => TemplatesState {
  return (state) => ({ ...state, templates })
}

function setPagination(page: number, pageSize: number, total: number): (state: TemplatesState) => TemplatesState {
  return (state) => ({ ...state, page, pageSize, total })
}

function updateTemplate(updatedTemplate: Template): (state: TemplatesState) => TemplatesState {
  return (state) => ({
    ...state,
    templates: state.templates.map((template) => {
      if (updatedTemplate.id === template.id) return updatedTemplate
      return template
    })
  })
}
