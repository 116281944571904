import clsx from 'clsx'
import translate from 'i18n/translate'
import { useIntl } from 'react-intl'
import SearchField from 'ui-components/SearchField/SearchField'
import { useClientId } from 'features/clients/clientZustandStore'
import { getDocumentsList } from 'services/documents.service'
import { useEffect, useState } from 'react'
import { Message } from '@bdo/kitchensink'
import axios, { AxiosError } from 'axios'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import PreviousExportsSwitch from '../PreviousExportsSwitch/PreviousExportsSwitch'
import styles from './TabDocuments.module.scss'
import DocumentsList from './DocumentsList'
import { useDocumentStore } from '../DocumentsStore'

function TabDocuments() {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const clientId = useClientId()
  const page = useDocumentStore((state) => state.page)
  const pageSize = useDocumentStore((state) => state.pageSize)
  const total = useDocumentStore((state) => state.total)
  const latestOnly = useDocumentStore((state) => state.latestOnly)
  const setDocuments = useDocumentStore((state) => state.setDocuments)
  const cleanUpStore = useDocumentStore((state) => state.cleanUpStore)
  const setPagination = useDocumentStore((state) => state.setPagination)
  const setSearchValue = useDocumentStore((state) => state.setSearchValue)
  const searchValue = useDocumentStore((state) => state.searchValue)
  const sortField = useDocumentStore((state) => state.sortField)
  const sortDirection = useDocumentStore((state) => state.sortDirection)
  const resetSortOrder = useDocumentStore((state) => state.resetSortOrder)
  const sortQueryString = sortDirection === 'ascend' ? `${sortField}` : `${sortField} desc`

  const onSearchChanged = (value: string) => {
    if (value === '') {
      resetSortOrder()
      Message.success(intl.$t({ id: 'user_message_documents_list_updated' }), 5)
    }
    setPagination(1, pageSize, total)
    setSearchValue(value)
  }

  useEffect(() => {
    if (!clientId) {
      return
    }
    setIsLoading(true)

    getDocumentsList(clientId, page, pageSize, latestOnly, searchValue, sortQueryString)
      .then((response) => {
        const receivedFiles = response.rows
        setDocuments(receivedFiles)

        const pagesCount = Math.ceil(response.total / pageSize)

        if (page <= pagesCount || pagesCount === 0) {
          setPagination(page, pageSize, response.total)
          showFeedbackAfterSearch(response.rows.length)
        } else {
          setPagination(pagesCount, pageSize, response.total)
        }
      })
      .catch((e: AxiosError | Error) => {
        LoggerHelper(e)
        if (axios.isAxiosError(e) && e.response && e.response.status === 403) {
          const responseErrorObject = e.response.data
          const errorMsg = intl.$t({ id: `api_DMS_${responseErrorObject.DMS[0].errorCode}` })
          Message.error(errorMsg, 10)
        } else {
          Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }), 5)
        }
      })
      .finally(() => setIsLoading(false))
  }, [clientId, page, pageSize, searchValue, latestOnly, sortQueryString])

  useEffect(
    () => () => {
      cleanUpStore()
    },
    []
  )

  function showFeedbackAfterSearch(documentsNumber: number) {
    if (searchValue !== '' && documentsNumber > 0) {
      Message.success(intl.$t({ id: 'user_message_documents_list_updated' }), 5)
    } else if (documentsNumber === 0) {
      Message.error(intl.$t({ id: 'user_message_documents_list_no_results' }), 5)
    }
  }

  return (
    <div className={styles.wrapper}>
      <h3>{translate('documents')}</h3>
      <div>
        <div className='card cardTable'>
          <div className={clsx(styles.controls, 'flex', 'alignItems')}>
            <SearchField onSearch={onSearchChanged} placeholder={intl.$t({ id: 'search_placeholder_documents' })} defaultValue={searchValue} />
            <div className={styles.toggleButton}>
              <PreviousExportsSwitch />
            </div>
          </div>
          <DocumentsList isLoading={isLoading} />
        </div>
      </div>
    </div>
  )
}

export default TabDocuments
